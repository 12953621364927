import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient";
import { IoIosLogOut } from "react-icons/io";
import defaultImage from "../../../../assets/images/default_company.jpg";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../Common/Loader";
import { MdOutlineDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { Helmet } from "react-helmet";

const CompanyProfile = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [productImages, setProductImages] = useState([]);
  const [serviceImages, setServiceImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const companyId = user ? user.company_id : null;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (!user) {
      alert("Please log in to view your profile.");
      navigate("/");
    }
  }, [user, navigate]);
  // console.log(user)

  useEffect(() => {
    if (companyId) {
      fetchProfile(companyId);
    }
  }, [companyId]);

  const fetchProfile = async (companyId) => {
    setLoading(true);
    try {
      const response = await apiClient.post("/getCompanyDetails", {
        command: "getCompanyDetails",
        company_id: companyId,
      });
      if (response.data.status === "success") {
        const profileData = response.data.status_message;
        setCompanyData(profileData);

        const productImageUrls = await Promise.all(
          (profileData.products || []).map(async (product) => {
            if (product.product_image) {
              try {
                const imgResponse = await apiClient.post(
                  "/getObjectSignedUrl",
                  {
                    command: "getObjectSignedUrl",
                    key: product.product_image,
                  }
                );
                return imgResponse.data || defaultImage;
              } catch {
                return defaultImage;
              }
            }
            return defaultImage;
          })
        );
        setProductImages(productImageUrls);

        const serviceImageUrls = await Promise.all(
          (profileData.products || []).map(async (service) => {
            if (service.service_image) {
              try {
                const imgResponse = await apiClient.post(
                  "/getObjectSignedUrl",
                  {
                    command: "getObjectSignedUrl",
                    key: service.service_image,
                  }
                );
                return imgResponse.data || defaultImage;
              } catch {
                return defaultImage;
              }
            }
            return defaultImage;
          })
        );
        setServiceImages(serviceImageUrls);

        if (profileData.fileKey) {
          const imgResponse = await apiClient.post("/getObjectSignedUrl", {
            command: "getObjectSignedUrl",
            key: profileData.fileKey,
          });
          setImageUrl(imgResponse.data || defaultImage);
        }
      } else {
        toast.error(
          "Failed to fetch company details: " + response.data.status_message
        );
      }
    } catch (error) {
      // console.log(error);
      console.error(
        "An error occurred while fetching your profile. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBrochure = async (brochureKey) => {
    setLoading(true)

    try {
      const response = await apiClient.post("/getObjectSignedUrl", {
        command: "getObjectSignedUrl",
        key: brochureKey,
      });
      // console.log("handlebrochure ",response)
      if (response.data) {
        window.open(response.data, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.error("Error fetching brochure URL:", error);
    } finally {
      setLoading(false)
    }
  };

  const confirmLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/");
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');

    if (confirmLogout) {

      localStorage.removeItem('user');
      setUser(null);
      navigate('/login');
    }
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  const handleDelete = async () => {
    navigate('/company-dashboard/profile/deleteaccount')
    window.scrollTo(0, 0)
  };

  // preview zoom in out starts here ✅

  const openModal = (imageUrl) => {
    console.log("hi")
    console.log(imageUrl)
    setModalImage(imageUrl || defaultImage);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // preview zoom in out ends here ✅


  if (loading) {
    <Loader />
  }

  return (
    <div className="p-6 flex items-center justify-center ">
      <ToastContainer />
      <Helmet>
        <title>BME Bharat - Profile</title>
        <meta property="og:title" content="BME Bharat - Profile"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile" />
      </Helmet>
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg p-8">
        <div className="flex flex-col items-center mb-6">

          <img
            src={imageUrl || defaultImage}
            alt="Company Logo"
            onContextMenu={(e) => e.preventDefault()}
            onClick={() => {
              openModal(imageUrl)
            }}
            className="w-32 h-32 object-contain rounded-full mb-4 shadow-md border-4 border-gray-200"
          />
          {companyData && (
            <h2 className="text-2xl font-semibold text-center text-gray-800">
              {companyData.company_name}
            </h2>
          )}
        </div>
        {companyData && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Email</span>
                <span>: {companyData.company_email_id || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Category</span>
                <span>: {companyData.category || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Description</span>
                <span>: {companyData.company_description || "Not Specified"}</span>
              </div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Reg. Number</span>
                <span>: {companyData.business_registration_number || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Contact</span>
                <span>: {companyData.company_contact_number || "Not Specified"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-32">Location</span>
                <span>: {companyData.company_located_city || "Not Specified"}, {companyData.company_located_state || "Not Specified"}</span>
              </div>
            </div>
          </div>

        )}

        {companyData && companyData.brochureKey && (
          <div className="flex justify-center mb-4">
            <button
              className="custom-btn"
              onClick={() => {
                handleBrochure(companyData?.brochureKey);
              }}
            >
              Brochure
            </button>
          </div>
        )}

        <hr className="my-4" />

        <h2 className="font-bold mb-4 text-[16px]">Select Product</h2>
        <select
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="w-full border rounded-lg p-2 mb-4"
        >
          <option value="">Select a Product</option>
          {companyData &&
            companyData.products &&
            companyData.products.length > 0 ? (
            companyData.products &&
            companyData.products.map((product, index) => (
              <option key={index} value={product.product_name}>
                {product.product_name}
              </option>
            ))
          ) : (
            <option disabled>No products available</option>
          )}
        </select>



        {selectedProduct && (
          <div className="border rounded-lg p-4 shadow-sm mb-4 bg-gray-50">
            {/* {
            console.log(companyData)} */}
            <img
              src={
                productImages[
                companyData.products.findIndex(
                  (p) => p.product_name === selectedProduct
                )
                ]
              }
              alt={
                companyData.products.find(
                  (p) => p.product_name === selectedProduct
                )?.product_name || "Product"
              }
              onContextMenu={(e) => e.preventDefault()}
              onClick={() => {
                openModal(productImages[
                  companyData.products.findIndex(
                    (p) => p.product_name === selectedProduct
                  )
                ])
              }}
              className="w-full h-64 object-contain rounded mb-2 shadow-md"
            />
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Product Name</span>
              <span>: {companyData.products.find((p) => p.product_name === selectedProduct)?.product_name || "Product Name Not Available"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Price</span>
              <span>: ₹{companyData.products.find((p) => p.product_name === selectedProduct)?.product_price || "N/A"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="text-justify">{companyData.products.find((p) => p.product_name === selectedProduct)?.product_description || "No Description Available"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Specifications</span>
              <span>: {companyData.products.find((p) => p.product_name === selectedProduct)?.product_specifications || "No Specifications Available"}</span>
            </div>

            {companyData.products.find(
              (p) => p.product_name === selectedProduct
            )?.product_brochure_key && (
                <button
                  className="custom-btn mt-2"
                  onClick={() => {
                    const brochureKey = companyData.products.find(
                      (p) => p.product_name === selectedProduct
                    )?.product_brochure_key;
                    handleBrochure(brochureKey);
                  }}
                >
                  Brochure
                </button>
              )}
          </div>
        )}

        <hr className="my-4" />

        <h2 className="font-bold mb-4 text-[16px]">Select Service</h2>
        <select
          onChange={(e) => setSelectedService(e.target.value)}
          className="w-full border rounded-lg p-2 mb-4"
        >
          <option value="">Select a Service</option>
          {companyData &&
            companyData.services &&
            companyData.services.length > 0 ? (
            companyData.services &&
            companyData.services.map((service, index) => (
              <option key={index} value={service.service_name}>
                {service.service_name}
              </option>
            ))
          ) : (
            <option disabled>No services available</option>
          )}
        </select>

        {selectedService && (


          <div className="border rounded-lg p-4 shadow-sm mb-4 bg-gray-50">
            {console.log(serviceImages[
              companyData.services.findIndex(
                (s) => s.service_name === selectedService
              )
            ])}
            <img
              src={
                serviceImages[
                companyData.services.findIndex(
                  (s) => s.service_name === selectedService
                )
                ]
              }
              alt={
                companyData.services.find(
                  (s) => s.service_name === selectedService
                )?.service_name || "Service"
              }
              onContextMenu={(e) => e.preventDefault()}
              onClick={() => {
                openModal(serviceImages[
                  companyData.services.findIndex(
                    (s) => s.service_name === selectedService
                  )
                ])
              }}
              className="w-full h-64 object-contain rounded mb-2 shadow-md"
            />
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Service Name</span>
              <span>: {companyData.services.find((s) => s.service_name === selectedService)?.service_name || "Service Name Not Available"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Price</span>
              <span>: ₹{companyData.services.find((s) => s.service_name === selectedService)?.service_price || "N/A"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="text-justify">{companyData.services.find((s) => s.service_name === selectedService)?.service_description || "No Description Available"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="font-bold w-28">Specifications</span>
              <span>: {companyData.services.find((s) => s.service_name === selectedService)?.service_specifications || "No Specifications Available"}</span>
            </div>

            {companyData.services.find(
              (s) => s.service_name === selectedService
            )?.service_brochure_key && (
                <button
                  className="custom-btn mt-2"
                  onClick={() => {
                    const brochureKey = companyData.services.find(
                      (s) => s.service_name === selectedService
                    )?.service_brochure_key;
                    handleBrochure(brochureKey);
                  }}
                >
                  Brochure
                </button>
              )}
          </div>
        )}

        <hr className="my-4" />

        <div className="flex justify-start gap-3 mt-6">
          <button
            onClick={() =>
              navigate("/company-dashboard/profile/update", {
                state: { companyData, productImages, serviceImages, imageUrl },
              })
            }
            className="custom-btn"
          >
            Edit Profile
          </button>
          <button
            onClick={() =>
              navigate(-1)
            }
            className="custom-btn"
          >
            Back
          </button>


        </div>
        <div className=" flex gap-3 mt-3">
          <button
            onClick={handleDelete}
            className="flex items-center custom-btn text-red-600"
          >
            <MdOutlineDelete className="mr-2" /> Delete account
          </button>

          <button
            onClick={handleLogout}
            className="flex items-center custom-btn text-red-600"
          >
            <IoIosLogOut className="mr-2" /> Logout
          </button>
        </div>

        {/* Logout Confirmation Modal */}
        {showLogoutModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">
                Are you sure you want to logout?
              </h2>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={cancelLogout}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400 transition-all"
                >
                  No
                </button>
                <button
                  onClick={confirmLogout}
                  className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-all"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"

              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyProfile;
