import React from "react";
import { Helmet } from "react-helmet";

const Refunds = () => {
  return (
    <div className="bg-white rounded-lg p-3 shadow-md mt-5 mx-auto w-[90%] md:w-3/4 lg:w-[80%]">
      <Helmet>
        <title>BME Bharat Guidelines | Terms and Conditions</title>
        <meta property="og:title" content=">BME Bharat Guidelines | Terms and Conditions"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/guidelines/termsandconditions" />
      </Helmet>
      <h1 className="text-center custom-h1  mb-2">Terms and Conditions</h1>
      <hr className="my-2" />
      <p className="text-gray-600 text-[12px] leading-6 text-justify">
        Welcome to BME Bharat App. By accessing or using the App, you agree to be bound by these Terms and Conditions. If you do not agree to these Terms, please do not use the App.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Use of the App:</strong>
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Eligibility:</strong>
        You must be at least 18 years old to use the App. By using the App, you represent and warrant that you meet this age requirement.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">License:</strong>
        We grant you a limited, non-exclusive, non-transferable, revocable license to use the App for your personal, non-commercial use, subject to these Terms.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Prohibited Conduct:</strong>
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">You agree not to:</strong>
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">Use the App for any illegal purpose or in violation of any local, state, national, or international law.</li>
          <li className="text-[12px]">Attempt to gain unauthorized access to the App, other user accounts, or any computer systems or networks connected to the App.</li>
          <li className="text-[12px]">Transmit any viruses, worms, defects, Trojan horses, or any items of a destructive nature.</li>
          <li className="text-[12px]">Use the App to transmit unsolicited commercial emails ("spam").</li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">User Accounts:</strong>
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Account Creation:</strong>
        To use certain features of the App, you may need to create an account. You agree to provide accurate and complete information when creating your account.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Account Security:</strong>
        You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Privacy:</strong>
        Our Privacy Policy describes how we collect, use, and disclose information about you. By using the App, you agree to our Privacy Policy.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Intellectual Property:</strong>
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Ownership:</strong>
        All content and materials available on the App, including but not limited to text, graphics, logos, and software, are the property of BME Bharat or its licensors and are protected by intellectual property laws.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Trademarks:</strong>
        BME Bharat and all related names, logos, product and service names, designs, and slogans are trademarks of BME Bharat or its affiliates or licensors. You may not use such marks without the prior written permission of BME Bharat.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Disclaimers and Limitation of Liability:</strong>
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">No Medical Advice:</strong>
        The App is provided for informational purposes only and is not intended as a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Disclaimer of Warranties:</strong>
        The App is provided on an "as is" and "as available" basis, without any warranties of any kind, either express or implied. We do not warrant that the App will be uninterrupted or error-free, that defects will be corrected, or that the App is free of viruses or other harmful components.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Limitation of Liability:</strong>
        To the fullest extent permitted by law, we disclaim all liability, whether based in contract, tort (including negligence), strict liability, or otherwise, and will not be liable for any indirect, incidental, consequential, or punitive damages arising out of or related to your use of the App.
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Changes to the Terms:</strong>
        We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on the App. Your continued use of the App after the posting of the changes constitutes your acceptance of the changes.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">Contact Us:</strong>
        <br />
        If you have questions or concerns about our legal compliance specific to Bharat or require assistance related to legal matters, please contact us at Email{" "}
        <span className="text-blue-600">admin@bmebharat.com</span> or{" "}
        <span className="text-blue-600">bmebharat@gmail.com</span> or Phone Number <span className="text-blue-600">+91 8310491223</span>.
      </p>
    </div>
  );
};

export default Refunds;
