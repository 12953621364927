import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaComment, FaPlus, FaTrash } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { AuthContext } from "../../../AuthContext";
import apiClient from '../../../ApiClient';
import { toast } from "react-toastify";
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { Helmet } from "react-helmet";

const MyCompanyForumList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [forum, setForum] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [noPostsFound, setNoPostsFound] = useState(false);
  const [filekey, setFileKey] = useState("")

  // const defaultImage = "https://drive.google.com/uc?export=download&id=1cQ7hJ5mkHamvdxP__11j_FHWs-5vfhjx";

  const fetchPosts = async () => {
    try {
      const response = await apiClient.post(
        "/getUsersAllForumPosts",
        { command: "getUsersAllForumPosts", user_id: user.user_id }
      );
      // console.log(response)
      // console.log(user.user_id)
      let posts = response.data.response;
      posts.sort((a, b) => b.posted_on - a.posted_on);
      setForum(posts);
      setFilteredPosts(posts);
      setNoPostsFound(posts.length === 0);

      const urlsObject = {};
      await Promise.all(
        posts.map(async (post) => {
          if (post.fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post.fileKey,
              });
              setFileKey(post.fileKey)

              const img_url = res.data;
              if (img_url) {
                urlsObject[post.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
    } catch (error) {
      console.log("Error fetching posts", error);
    }
  };

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results.length === 0);
  };


  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results.length === 0);
  }, [searchQuery, forum])

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).replace(/ /g, "/");
  };

  const handleDelete = async (forum_id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete) {
      try {
        const response = await apiClient.post('/deleteForumPost', {
          command: "deleteForumPost",
          user_id: user.user_id,
          forum_id: forum_id,
        });
        // console.log(filekey)
        if (filekey) {
          const res = await apiClient.post(
            "/deleteFileFromS3",
            {
              command: "deleteFileFromS3",
              key: filekey,
            }
          );

          // console.log("deleteFileFromS3",res)
        }

        if (response.data.status === 'success') {
          setFilteredPosts(filteredPosts.filter(post => post.forum_id !== forum_id));
          toast.success("The forum post has been successfully deleted.");
        } else {
          toast.error(response.data.status_message);
        }
      } catch (error) {
        toast.error("Error deleting post");
      }
    }
  };

  const handleEditForum = (item, imageUrls) => {
    navigate("/user-dashboard/profile/my-forum-edit", {
      state: { post: item, imageUrls },

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // console.log("Image URL: ", imageUrl);
  }

  const navigateToForumDetails = (forum_id) => {
    
    navigate(`/user-dashboard/forums/details/${forum_id}`)
  }

  const RenderPostItem = ({ item }) => {
    const imageUrl = imageUrls[item.forum_id] || defaultImage;
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item.fileKey && videoExtensions.some(ext => item.fileKey.endsWith(ext));


    return (
      <div className="w-full">
        <div className="bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <div
            onClick={() => 
              navigateToForumDetails(item.forum_id)
           }
          >
            {isVideo ? (
              <video
                src={imageUrl}
                className="w-full h-64 object-contain rounded-t-lg"
                controls
                muted
                onError={(e) => { e.target.src = defaultImage; }}
              />
            ) : (
              <img
                src={imageUrl}
                alt={item.title}

                onContextMenu={(e) => e.preventDefault()}
                className="w-full h-64 object-contain rounded-t-lg"
                onError={(e) => { e.target.src = defaultImage; }}
              />
            )}
            <div className="p-4">
            <div className="flex flex-col justify-start mb-2">
                <span className="font-bold text-lg">{item.author || "N/A"}</span>
                <span className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
              </div>

              <div className="flex justify-start mb-2">
                <span className="font-bold w-24">Title</span>
                <span>: {item.title || "N/A"}</span>
              </div>

              <div className="flex justify-start mb-2">
                <span className="font-bold w-24">Posted on</span>
                <span>: {formatDate(item.posted_on) || "N/A"}</span>
              </div>
              <p className="mb-1 ">{item.forum_body.slice(0, 70)} ..</p>

              <div className="flex justify-between items-center text-sm text-gray-500 mt-4">
                <Link to={`/user-dashboard/forums/comments/${item.forum_id}`} onClick={(e) => {
                  e.stopPropagation()
                }} className="flex items-center text-blue-600 hover:text-blue-800 no-underline">
                  <FaComment className="mr-1" /> {item.comments_count || 0} Comments
                </Link>
                <span>{item.viewsCount || "0"} Views</span>
              </div>
              <div className="flex justify-start gap-2 mt-3 items-center text-sm text-gray-500">
                {item.user_id === user.user_id && (
                  <><button
                    className="hover:underline custom-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditForum(item, imageUrl)
                    }}
                  >
                    Edit Post
                  </button><button onClick={(e) => {
                    e.stopPropagation()
                    handleDelete(item.forum_id)
                  }} className="flex items-center custom-btn">
                      <FaTrash className="mr-2" /> Delete
                    </button></>
                )}
              </div>


            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 py-2 min-h-screen mt-6">
      <Helmet>
        <title>BME Bharath | My posts</title>
        <meta property="og:title" content="BME Bharath | My posts"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/my-forum-list" />
      </Helmet>
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>
      <div className=" mx-auto">


        {noPostsFound ? (
          <div className="text-left text-gray-600">No posts found</div>
        ) : (
          <>
            <p className="mb-4 text-gray-600">{filteredPosts.length} posts found</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {filteredPosts.map((item) => (
                <RenderPostItem key={item.forum_id} item={item} />
              ))}
            </div>
          </>
        )}

        <Link
          to="/user-dashboard/forums/post"
          className="fixed z-50 bottom-6 right-11 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        >
          <FaPlus size={24} />
        </Link>
      </div>
    </div>
  );
};

export default MyCompanyForumList;
