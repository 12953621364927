import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaComment, FaPlus } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { AuthContext } from "../../../AuthContext"; // Assuming you have an AuthContext to get user info
import apiClient from '../../../ApiClient'; // Import your configured API client
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";


const CompanyForumList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext); // Get user context
  const [forum, setForum] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [noPostsFound, setNoPostsFound] = useState(false);
  const [filekey, setFileKey] = useState("")
  const [loading, setLoading] = useState(false)



  const fetchPosts = async () => {
    setLoading(true)
    try {
      const response = await apiClient.post("/getAllForumPosts", {
        command: "getAllForumPosts",
      });
      let posts = response.data.response;
      console.log(response)
      posts.sort((a, b) => b.posted_on - a.posted_on);
      setForum(posts);
      setFilteredPosts(posts);
      setNoPostsFound(posts.length === 0);

      const urlsObject = {};
      await Promise.all(
        posts.map(async (post) => {
          if (post.fileKey) {
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: post.fileKey,
              });
              setFileKey(post.fileKey)
              const img_url = res.data;
              if (img_url) {
                urlsObject[post.forum_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for post:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
    } catch (error) {
      console.log("Error fetching posts", error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results.length === 0);
  }, [searchQuery, forum])

  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredPosts(forum);
      setNoPostsFound(forum.length === 0);
      return;
    }

    const results = forum.filter((post) => {
      const title = post.title ? post.title.toLowerCase() : "";
      const body = post.forum_body ? post.forum_body.toLowerCase() : "";
      const query = searchQuery.toLowerCase();

      return title.includes(query) || body.includes(query);
    });

    setFilteredPosts(results);
    setNoPostsFound(results.length === 0);
  };


  const handleRefresh = () => {
    fetchPosts();
    setSearchQuery("");
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).replace(/ /g, "/");
  };

  // const formatDate = (timestamp) => {
  //   return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
  //     day: "2-digit",
  //     month: "short",
  //   }).replace(/ /g, " ");
  // };

  const incrementViewCount = async (forumId) => {
    try {
      const res = await apiClient.post('/forumViewCounts', {
        command: 'forumViewCounts',
        forum_id: forumId,
      });
      // console.log(res)
    } catch (error) {
      console.error("Error incrementing view count", error);
    }
  };


  const handleEditForum = (item, imageUrls) => {
    navigate("/user-dashboard/profile/my-forum-edit", {
      state: { post: item, imageUrls },

    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const navigateToUserDetails = (user_id, user_type) => {
    const path = user_type === 'company'
      ? `/user-dashboard/company/details/${user_id}`
      : `/user-dashboard/users/user_details/${user_id}`;
    
    navigate(path);
  };

  const RenderPostItem = ({ item }) => {
    const imageUrl = imageUrls[item.forum_id];
    const videoExtensions = [".mp4", ".mov", ".avi", ".mkv", ".wmv", ".flv", ".webm"];
    const isVideo = item.fileKey && videoExtensions.some(ext => item.fileKey.endsWith(ext));

    const handleDelete = async (forum_id) => {
      const confirmDelete = window.confirm("Are you sure you want to delete this post?");
      if (confirmDelete) {
        try {
          const response = await apiClient.post('/deleteForumPost', {
            command: "deleteForumPost",
            user_id: user.user_id,
            forum_id: forum_id,
          });
          console.log(response)
          if (filekey) {
            const res = await apiClient.post(
              "/deleteFileFromS3",
              {
                command: "deleteFileFromS3",
                key: filekey,
              }
            );

            // console.log("deleteFileFromS3",res)
          }

          if (response.data.status === 'success') {
            setFilteredPosts(filteredPosts.filter(post => post.forum_id !== forum_id));
            toast.success("The forum post has been successfully deleted.");
          } else {
            toast.error(response.data.status_message);
          }
        } catch (error) {
          toast.error("Error deleting post");
        }
      }
    };

    const navigateToForumDetails = (forum_id) => {
    
      navigate(`/user-dashboard/forums/details/${forum_id}`)
    }

    return (
      <div className="w-full">
        <div className="bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <div
            onClick={async () => {
              await incrementViewCount(item.forum_id);
              navigateToForumDetails(item.forum_id)
            }
            }
          >
            {isVideo ? (
              <video
                src={imageUrl || defaultImage}
                className="w-full h-64 object-contain rounded-t-lg"
                controls
                muted
              />
            ) : (
              <img
                src={imageUrl || defaultImage}
                alt={item.title}
                onContextMenu={(e) => e.preventDefault()}
                className="w-full h-64 object-contain rounded-t-lg"
              />
            )}

            <div className="p-4 ">
              <div className="flex flex-col justify-start mb-2 cursor-pointer" onClick={(e)=> {
                      e.stopPropagation()
                      navigateToUserDetails(item.user_id, item.user_type)
                    }}>
                <span className="font-bold text-lg">{item.author || "N/A"}</span>
                <span className="text-sm text-gray-500 -mt-1">{item?.author_category}</span>
              </div>

              <div className="flex justify-start mb-2">
                <span className="font-bold w-24">Title</span>
                <span>: {item.title || "N/A"}</span>
              </div>

              <div className="flex justify-start mb-2">
                <span className="font-bold w-24">Posted on</span>
                <span>: {formatDate(item.posted_on) || "N/A"}</span>
              </div>
              <p className="mb-1 ">{item.forum_body.slice(0, 70)} ..</p>


              <div className="flex flex-col gap-2 mt-4">
                <div className="flex justify-between items-center text-sm text-gray-500">
                  <Link
                    to={`/user-dashboard/forums/comments/${item.forum_id}`}
                    className="flex items-center text-blue-600 hover:text-blue-800 no-underline"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span className="flex items-center text-blue-500">
                      <FaComment className="mr-1" /> {item.comments_count || 0}{" "}
                      Comments
                    </span>
                  </Link>
                  {/* {console.log(item)}
              {console.log(item.viewsCount)} */}
                  <span>{item.viewsCount || "0"} Views</span>
                </div>

                <div className="flex">
                  {item.user_id === user.user_id && (

                    <div className="flex space-x-1">
                      <button
                        className="hover:underline custom-btn "
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditForum(item, imageUrl);
                        }}
                      >
                        Edit Post
                      </button><button onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(item.forum_id);
                      }} className="flex items-center custom-btn">
                        Delete
                      </button>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="p-6 min-h-screen  py-2  mt-6">
      <Helmet>
        <title>BME Bharath | Latest posts</title>
        <meta property="og:title" content="BME Bharath | Latest posts"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/forums" />
      </Helmet>
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>
      <div className="mx-auto">


        {noPostsFound ? (
          <div className="text-left text-gray-600">No posts found</div>
        ) : (
          <>
            <p className="mb-4 text-gray-600">
              {filteredPosts.length} posts found
            </p>{" "}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {filteredPosts.map((item) => (
                <RenderPostItem key={item.forum_id} item={item} />
              ))}
            </div>
          </>
        )}

        <Link
          to="/user-dashboard/forums/post"
          className="fixed z-50 bottom-6 right-11 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        >
          <FaPlus size={24} />
        </Link>
      </div>
    </div>
  );
};

export default CompanyForumList;
