import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../AuthContext";
import { stateCityData, CountryCodes } from "../../../Data";
import defaultImage from "../../../../assets/images/default_company.jpg";
import apiClient from "../../../ApiClient"
import LoaderButton from "../../../Common/LoaderButton";
import { Helmet } from "react-helmet";

const CompanyProfileCreate = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const company_id = user.company_id;
  const location = useLocation();
  const [imageUrls, setImageUrls] = useState();
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(true);

  const {
    companyData,
    productImages,
    serviceImages,
    imageUrl,
    fullPhoneNumber,
    selectedProfile,
    selectedCategory,
  } = location.state || {};

  // console.log(companyData)

  const [imageFile, setImageFile] = useState("");
  const [fileKey, setFileKey] = useState(companyData?.fileKey || "");
  const [pdfFile, setPdfFile] = useState(companyData?.brochureKey || "");
  const [imagePreview, setImagePreview] = useState("");
  const [brochureKey, setBrochureKey] = useState(
    companyData?.brochureKey || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    companyData?.company_contact_number.slice(-10)
  );
  const [countryCode, setCountryCode] = useState(
    companyData?.company_contact_number.slice(
      0,
      companyData?.company_contact_number.length - 10
    )
  );
  // console.log("compnyData", companyData);
  const [products, setProducts] = useState(
    companyData && companyData.products && companyData.products.length > 0
      ? companyData.products.map((product) => ({
        product_image: product.product_image,
        product_name: product.product_name || undefined,
        product_price: product.product_price || undefined,
        product_description: product.product_description || undefined,
        product_specifications: product.product_specifications || undefined,
        product_brochure_key: product.product_brochure_key,
      }))
      : []
  );

  const [services, setServices] = useState(
    companyData && companyData.services && companyData.services.length > 0
      ? companyData.services.map((service) => ({
        service_image: service.service_image,
        service_name: service.service_name || undefined,
        service_price: service.service_price || undefined,
        service_description: service.service_description || undefined,
        service_specifications: service.service_specifications || undefined,
        service_brochure_key: service.service_brochure_key,
      }))
      : []
  );

  const [postData, setPostData] = useState({
    company_name: companyData?.company_name || "",
    business_registration_number:
      companyData?.business_registration_number || "",
    company_email_id: companyData?.company_email_id || "",
    company_located_city: companyData?.company_located_city || "",
    company_located_state: companyData?.company_located_state || "",
    Website: companyData?.Website || "",
    company_address: companyData?.company_address || "",
    company_description: companyData?.company_description || "",
    company_contact_number: companyData?.company_contact_number || "",
  });

  useEffect(() => {
    if (imageUrl && typeof imageUrl === "string") {
      setImageUrls(imageUrl);
    } else {
      setImageUrls(defaultImage);
    }
  }, [imageUrl]);

  const handleInputChange = (key, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  //upload file for all images and brochure
  const uploadFile = async (base64, type) => {
    setLoading(true)
    try {
      const response = await apiClient.post("/uploadFile", {
        command: "uploadFile",
        headers: { "Content-Type": type },
        fileBuffer: base64,
      });
      if (response.data.statusCode === 200) {
        const body = JSON.parse(response.data.body);
        return body.brochureKey || body.fileKey;
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      toast.error("File upload failed.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        toast.error("Image file size must be less than 200 KB.");
        return;
      }

      const base64 = await convertToBase64(file);

      if (fileKey) {
        // Deleting the old file
        await deleteFileFromS3(fileKey);
        // console.log(fileKey);
      }

      const uploadedKey = await uploadFile(base64, file.type);

      if (uploadedKey) {
        setImageFile(file);
        setFileKey(uploadedKey); // Updating new file key
      }
    } else {
      toast.info("Please upload a valid file.");
    }
  };

  const handleBrochureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);

      if (brochureKey) {
        // Deleting the old file
        await deleteFileFromS3(brochureKey);
        // console.log(brochureKey);
      }

      const uploadedKey = await uploadFile(base64, file.type);
      setPdfFile(file);
      setBrochureKey(uploadedKey);
    } else {
      toast.info("Please upload a valid File for the brochure.");
    }
  };

  const handleProductImageUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("Image file size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);

      const currentFileKey = products[index].product_image;
      // console.log(products)

      if (currentFileKey) {
        await deleteFileFromS3(currentFileKey);
      }
      const uploadedFileKey = await uploadFile(base64, file.type);
      const updatedProducts = [...products];
      updatedProducts[index].product_image = uploadedFileKey;
      setProducts(updatedProducts);
    } else {
      toast.info("Please upload a valid file.");
    }
  };

  const handleProductBrochureUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);

      const currentFileKey = products[index].product_brochure_key;
      if (currentFileKey) {
        await deleteFileFromS3(currentFileKey);
      }

      const uploadedKey = await uploadFile(base64, file.type);
      const updatedProducts = [...products];
      updatedProducts[index].product_brochure_key = uploadedKey;
      setProducts(updatedProducts);
    } else {
      alert("Please upload a valid File for the brochure.");
    }
  };

  const handleServiceImageUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("Image file size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);

      const currentFileKey = services[index].service_image;
      if (currentFileKey) {
        await deleteFileFromS3(currentFileKey);
      }
      const uploadedFileKey = await uploadFile(base64, file.type);
      const updatedServices = [...services];
      updatedServices[index].service_image = uploadedFileKey;
      setServices(updatedServices);
    }
  };

  const handleServiceBrochureUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const currentFileKey = services[index].service_brochure_key;

      if (currentFileKey) {
        await deleteFileFromS3(currentFileKey);
      }
      const uploadedKey = await uploadFile(base64, file.type);

      const updatedServices = [...services];
      updatedServices[index].service_brochure_key = uploadedKey;
      setServices(updatedServices);
    } else {
      alert("Please upload a valid File for the brochure.");
    }
  };

  const handleProductChange = (index, key, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][key] = value;
    setProducts(updatedProducts);
  };

  const handleServiceChange = (index, key, value) => {
    const updatedServices = [...services];
    updatedServices[index][key] = value;
    setServices(updatedServices);
  };

  const canAddProduct = () => {
    if (products.length === 0) return true;

    const lastProduct = products[products.length - 1];
    return lastProduct && lastProduct.product_name


  };

  const canAddService = () => {
    if (services.length === 0) return true;

    const lastService = services[services.length - 1];
    return lastService && lastService.service_name


  };

  const addProduct = () => {
    if (!canAddProduct()) {
      toast.error("Product name is required to add a new product.");
      return;
    }
    setProducts([
      ...products,
      {
        product_image: "",
        product_brochure_key: "",
        product_name: "",
        product_price: 0,
        product_description: "",
        product_specifications: "",
      },
    ]);
  };

  const addService = () => {
    if (!canAddService()) {
      toast.error("Service name is required to add a new service.");
      return;
    }
    setServices([
      ...services,
      {
        service_image: "",
        service_brochure_key: "",
        service_name: "",
        service_price: 0,
        service_description: "",
        service_specifications: "",
      },
    ]);
  };

  const states = Object.keys(stateCityData);
  const cities = postData.company_located_state
    ? stateCityData[postData.company_located_state]
    : [];

  const deleteFileFromS3 = async (key) => {
    setLoading(true)
    try {
      const response = await apiClient.post("/deleteFileFromS3", {
        command: "deleteFileFromS3",
        key: key,
      });
      // console.log("deleteFileFromS3", response.data.message);
    } catch (error) {
      console.error("Error deleting file:", error);
      return false;
    } finally {
      setLoading(false)
    }
  };

  // otp verify for phone ✅\

  const handleOTPChange = (index, value) => {
    if (/^[0-9a-zA-Z]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      // console.log(newOtp)
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to the previous input if backspace is pressed and current is empty
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVerifyOtp();
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhoneNumber(newPhone);
    setOtpSent(false);
    setIsOtpVerified(false);
  };

  const handleSendOtp = async () => {
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    if (!/^\d{10}$/.test(phoneNumber)) {
      return toast.error("Phone number must be exactly 10 digits.");
    }

    try {
      setLoading(true);
      const otpRes = await apiClient.post(
        "/sendOtpVerificationMsg91",
        {
          command: "sendOtpVerificationMsg91",
          user_phone_number: fullPhoneNumber,
        },
        {
          headers: {
            "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
          },
        }
      );
      if (otpRes.data?.type === "success") {
        setOtpSent(true);
        toast.success("OTP sent successfully.");
      } else {
        toast.error(otpRes.data.errorMessage);

      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    const enteredOTP = otp.join('');
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    try {
      setLoading(true);
      const response = await apiClient.post('/verifyOtpMsg91', {
        command: 'verifyOtpMsg91',
        otp: enteredOTP,
        user_phone_number: fullPhoneNumber
      });
      // console.log(response)
      if (response.data.type === 'success') {
        setIsOtpVerified(true);
        toast.success("Phone number verified successfully.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
    // console.log(countryCode,phoneNumber)

    if (!postData.company_name) {
      toast.error("Company name is required");
      return;
    }
    if (!postData.business_registration_number) {
      toast.error("CIN / Business registration number is required");
      return;
    }
    if (!postData.company_located_city) {
      toast.error("City is required");
      return;
    }
    if (!postData.company_located_state) {
      toast.error("State is required");
      return;
    }
    if (!/^\d{10}$/.test(phoneNumber)) {
      return toast.error("Phone number must be exactly 10 digits.");
    }

    if (!postData.company_email_id) {
      toast.error("Email ID is required");
      return;
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(postData.company_email_id)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (products.length > 0) {
      const invalidProduct = products.find((product) => !product.product_name);

      if (invalidProduct) {
        toast.error("Name of the product is required");
        return;
      }
    }

    if (services.length > 0) {
      const invalidProduct = services.find((service) => !service.service_name);

      if (invalidProduct) {
        toast.error("Name of the service is required");
        return;
      }
    }

    const payload = {
      command: "updateCompanyProfile",
      company_name: postData.company_name,
      company_id: company_id,
      fileKey: fileKey,
      brochureKey: brochureKey,
      business_registration_number: postData.business_registration_number,
      select_your_profile: selectedProfile || "Manufacturer",
      category: selectedCategory || "Electronics",
      company_located_city: postData.company_located_city,
      company_located_state: postData.company_located_state,
      company_address: postData.company_address,
      company_contact_number: fullPhoneNumber,
      company_email_id: postData.company_email_id,
      Website: postData.Website,
      company_description: postData.company_description,
      products: products,
      services: services,
    };

    // console.log(payload);

    try {
      const response = await apiClient.post("/updateCompanyProfile", payload);
      // console.log(response);
      if (response.data.status === "success") {
        toast.success("Company Profile Updated Successfully");
        const res = await apiClient.post('/getCompanyDetails', {
          command: "getCompanyDetails",
          company_id: user.company_id,
        })
        // console.log(res.data.status_message)
        localStorage.setItem('user', JSON.stringify(res.data.status_message));

        setTimeout(() => {
          navigate("/company-dashboard/profile");
          window.scrollTo(0, 0)
        }, 1000);
      } else {
        toast.error("Failed to update company profile.");
        toast.error(response.data.ErrorMessage);
      }
    } catch (error) {
      toast.error("An error occurred while updating the company profile.");
    } finally {
      setLoading(false)
    }
  };

  const deleteService = async (index) => {
    const service = services[index];
    if (service.service_image) {
      try {
        await deleteFileFromS3(service.service_image);
      } catch (error) {
        console.error("Error deleting service image from S3:", error);
        toast.error("Failed to delete service image from S3.");
      }
    }

    // Check and delete service brochure from S3 if it exists
    if (service.service_brochure_key) {
      try {
        await deleteFileFromS3(service.service_brochure_key);
      } catch (error) {
        console.error("Error deleting service brochure from S3:", error);
        toast.error("Failed to delete service brochure from S3.");
      }
    }
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const deleteProduct = async (index) => {
    const product = products[index];
    if (product.product_image) {
      try {
        await deleteFileFromS3(product.product_image);
      } catch (error) {
        console.error("Error deleting product image from S3:", error);
        toast.error("Failed to delete product image from S3.");
      }
    }

    if (product.product_brochure_key) {
      try {
        await deleteFileFromS3(product.product_brochure_key);
      } catch (error) {
        console.error("Error deleting product brochure from S3:", error);
        toast.error("Failed to delete product brochure from S3.");
      }
    }
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const removeProductImage = async (index) => {
    const product = products[index];
    if (product.product_image) {
      await deleteFileFromS3(product.product_image);
    }

    const updatedProducts = [...products];
    updatedProducts[index].product_image = "";
    setProducts(updatedProducts);
  };

  const removeProductBrochure = async (index) => {
    const product = products[index];
    if (product.product_brochure_key) {
      await deleteFileFromS3(product.product_brochure_key);
    }

    const updatedProducts = [...products];
    updatedProducts[index].product_brochure_key = "";
    setProducts(updatedProducts);
  };

  const removeServiceImage = async (index) => {
    const service = services[index];
    if (service.service_image) {
      await deleteFileFromS3(service.service_image);
    }
    const updatedServices = [...services];
    updatedServices[index].service_image = "";
    setServices(updatedServices);
  };

  const removeServiceBrochure = async (index) => {
    const service = services[index];
    if (service.service_brochure_key) {
      await deleteFileFromS3(service.service_brochure_key);
    }

    const updatedServices = [...services];
    updatedServices[index].service_brochure_key = "";
    setServices(updatedServices);
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="max-w-4xl mx-auto p-6 mt-10 bg-white shadow-md rounded-md">
      <ToastContainer />
      <Helmet>
        <title>BME Bharat | Update Profile</title>
        <meta property="og:title" content="BME Bharath | Update Profile"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile" />
      </Helmet>
      <h1 className="custom-h1 mb-6 text-center">Update Profile</h1>
      <div className="grid gap-4 md:grid-cols-2">
        <label>
          <span>
            Company Name <span className="text-red-500"> *</span>
          </span>
          <input
            type="text"
            value={postData.company_name}
            onChange={(e) => handleInputChange("company_name", e.target.value)}
            className="border p-2 rounded w-full"
            required
          />
        </label>
        <label>
          <span>
            CIN / Business Registration Number{" "}
            <span className="text-red-500"> *</span>
          </span>
          <input
            type="text"
            value={postData.business_registration_number}
            onChange={(e) =>
              handleInputChange("business_registration_number", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          />
        </label>
        <label>
          <span>
            Company Email ID <span className="text-red-500"> *</span>
          </span>
          <input
            type="email"
            value={postData.company_email_id}
            onChange={(e) =>
              handleInputChange("company_email_id", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          />
        </label>

        {/* value={postData.company_located_city} */}

        <label>
          <span>
            State <span className="text-red-500"> *</span>
          </span>
          <select
            name="selectedState"
            value={postData.company_located_state}
            onChange={(e) =>
              handleInputChange("company_located_state", e.target.value)
            }
            className="w-full p-2 border border-gray-300 rounded-lg"
            required
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </label>
        <label>
          <span>
            City <span className="text-red-500"> *</span>
          </span>
          <select
            value={postData.company_located_city}
            onChange={(e) =>
              handleInputChange("company_located_city", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </label>
        <label>
          <label htmlFor="user_phone_number">Phone Number<span className="text-red-500"> *</span></label>

          <div className="flex items-center justify-center space-x-2">
            <select
              id="countryCode"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="block text-center w-[40%] px-1 py-2 mr-1 border border-gray-300 bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              {CountryCodes.map((code, index) => (
                <option key={index} value={code.value}>
                  {code.label} ({code.value})
                </option>
              ))}
            </select>
            <input
              type="tel"
              id="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Enter 10-digit phone number"
              required
              className="block w-full rounded-lg px-3 py-2 border bg-none border-gray-300 bg-transparent shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {companyData?.company_contact_number != countryCode + phoneNumber && !otpSent && (
              <button
                onClick={handleSendOtp}
                className="w-[40%] px-3 py-2 bg-indigo-500 text-white rounded-md"
                disabled={loading}
              >
                {loading ? "Sending OTP..." : "Verify OTP"}
              </button>
            )}
          </div>
          {otpSent && !isOtpVerified && (
            <div className="flex flex-col space-y-2">
              <label htmlFor="otp">Enter OTP<span className="text-red-500"> *</span></label>
              <div className="flex justify-center gap-3">

                {otp.map((digit, index) => (
                  <input
                    key={index}
                    className="w-12 h-12 border rounded text-center text-xl focus:outline-none focus:border-blue-500"
                    placeholder="•"
                    type="tel"
                    maxLength={1}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    value={digit}
                    ref={(ref) => (otpInputs.current[index] = ref)}
                  />
                ))}
              </div>
              <button type="submit" className="w-fit mx-auto custom-btn" onClick={() => {
                handleVerifyOtp()
              }} disabled={loading}>
                {loading ? (
                  <div className='flex justify-center items-center'>
                    <LoaderButton />
                    Verifying OTP
                  </div>
                ) : (
                  <>Verify OTP</>
                )}
              </button>
            </div>
          )}

        </label>
        <label>
          <span>Website</span>
          <input
            type="url"
            value={postData.Website}
            onChange={(e) => handleInputChange("Website", e.target.value)}
            className="border p-2 rounded w-full"
          />
        </label>
        <label>
          <span>Company Address</span>
          <input
            type="text"
            value={postData.company_address}
            onChange={(e) =>
              handleInputChange("company_address", e.target.value)
            }
            className="border p-2 rounded w-full"
          />
        </label>
        <label>
          <span>Company Description</span>
          <textarea
            value={postData.company_description}
            onChange={(e) =>
              handleInputChange("company_description", e.target.value)
            }
            className="border p-2 rounded w-full"
          />
        </label>

        <label>
          <span>Upload Company Catalogue</span>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"

            onChange={handleBrochureChange}
            className="border p-2 rounded w-full"
          />
          {brochureKey && (
            <div className="mt-2">
              <button onClick={async () => {
                // console.log(brochureKey)
                if (brochureKey) {
                  await deleteFileFromS3(brochureKey)
                }
                setPdfFile("")
                setBrochureKey("")
              }} className="text-red-500">
                Remove Brochure
                <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            </div>
          )}
        </label>

        <label>
          <div>Upload Company Logo</div>
          <input
            type="file"
            accept="image/*"
            id="logo_company"
            onChange={handleFileChange}
            className="border p-2 rounded hidden w-full"
          />
          {imageUrls && !imageFile && (
            <div className="relative mt-4 inline-block">

              <img
                src={imageUrls || defaultImage}
                alt="company logo"
                className="image-standard mb-2 cursor-pointer"
                onContextMenu={(e) => e.preventDefault()}
                onClick={() => document.getElementById("logo_company").click()}
              />
              <button
                type="button"
                className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
                onClick={() => document.getElementById("logo_company").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
              <button onClick={async () => {
                if (fileKey) {
                  await deleteFileFromS3(fileKey)
                }
                setImageFile("")
                setFileKey("")
                setImageUrls(defaultImage)
              }} className="text-red-500">
                Remove Image{" "}
                <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>


            </div>
          )}
          {imageFile && (
            <img
              src={URL.createObjectURL(imageFile) || defaultImage}

              onContextMenu={(e) => e.preventDefault()}
              alt="Company Preview"
              className="mt-2 image-standard object-contain"
            />
          )}
          <button
            type="button"
            className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
            onClick={() => document.getElementById("logo_company").click()}
          >
            <i className="fa fa-pencil text-black" aria-hidden="true"></i>
          </button>
          {imageFile && (
            <button onClick={async () => {
              if (fileKey) {
                await deleteFileFromS3(fileKey)
              }
              setImageFile("")
              setFileKey("")
              setImageUrls(defaultImage)
            }} className="text-red-500">
              Remove Image{" "}
              <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
            </button>
          )}
        </label>
      </div>

      {/* Products Section */}
      <h2 className="text-[16px] font-bold mt-6">Products</h2>
      {products &&
        products.map((product, index) => (
          <div key={index} className="border p-4 mb-4 rounded">
            <div className="w-full flex justify-end ">
              <button
                className="text-red-500"
                onClick={() => deleteProduct(index)}
              >
                Delete{" "}
                <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            </div>
            <h3 className="text-lg font-semibold">Product {index + 1}</h3>
            <label>
              <span>
                Product Name<span className="text-red-500"> *</span>
              </span>
              <input
                type="text"
                value={product.product_name}
                onChange={(e) =>
                  handleProductChange(index, "product_name", e.target.value)
                }
                className="border p-2 rounded w-full"
                required
              />
            </label>
            <label>
              <span>Product Price</span>
              <input
                type="number"
                value={product.product_price}
                onChange={(e) =>
                  handleProductChange(
                    index,
                    "product_price",
                    parseFloat(e.target.value)
                  )
                }
                className="border p-2 rounded w-full"
                required
              />
            </label>
            <label>
              <span>Product Description </span>
              <textarea
                value={product.product_description}
                onChange={(e) =>
                  handleProductChange(
                    index,
                    "product_description",
                    e.target.value
                  )
                }
                className="border p-2 rounded w-full"
              />
            </label>
            <label>
              <span>Product Specifications</span>
              <textarea
                value={product.product_specifications}
                onChange={(e) =>
                  handleProductChange(
                    index,
                    "product_specifications",
                    e.target.value
                  )
                }
                className="border p-2 rounded w-full"
              />
            </label>
            <label>
              <span>Upload Product Image</span>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleProductImageUpload(e, index)}
                className="border p-2 rounded w-full"
              />

              {product.product_image && (
                <button
                  onClick={() => removeProductImage(index)}
                  className="text-red-500"
                >
                  Remove Image{" "}
                  <i
                    className="fa fa-trash text-red-500"
                    aria-hidden="true"
                  ></i>
                </button>
              )}
            </label>
            <label>
              <span>Upload Product Brochure</span>
              <input
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"

                onChange={(e) => handleProductBrochureUpload(e, index)}
                className="border p-2 rounded w-full"
              />
              {product.product_brochure_key && (
                <div className="mt-2">
                  <button
                    onClick={() => removeProductBrochure(index)}
                    className="text-red-500"
                  >
                    Remove Brochure
                    <i
                      className="fa fa-trash text-red-500"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              )}
            </label>
          </div>
        ))}
      <button type="button" onClick={addProduct}
        className={`custom-btn ${!canAddProduct() ? 'bg-gray-200 cursor-not-allowed' : ''}`}
      >
        + Add Product
      </button>

      {/* Services Section */}
      <h2 className="text-[16px] font-bold mt-6">Services</h2>
      {services &&
        services.map((service, index) => (
          <div key={index} className="border p-4 mb-4 rounded">
            <div className="w-full flex justify-end ">
              <button
                className="text-red-500"
                onClick={() => deleteService(index)}
              >
                Delete{" "}
                <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            </div>
            <h3 className="text-lg font-semibold">Service {index + 1}</h3>
            <label>
              <span>
                Service Name <span className="text-red-500"> *</span>
              </span>
              <input
                type="text"
                value={service.service_name}
                onChange={(e) =>
                  handleServiceChange(index, "service_name", e.target.value)
                }
                className="border p-2 rounded w-full"
                required
              />
            </label>
            <label>
              <span>Service Price</span>
              <input
                type="number"
                value={service.service_price}
                onChange={(e) =>
                  handleServiceChange(
                    index,
                    "service_price",
                    parseFloat(e.target.value)
                  )
                }
                className="border p-2 rounded w-full"
                required
              />
            </label>
            <label>
              <span>Service Description </span>
              <textarea
                value={service.service_description}
                onChange={(e) =>
                  handleServiceChange(
                    index,
                    "service_description",
                    e.target.value
                  )
                }
                className="border p-2 rounded w-full"
              />
            </label>
            <label>
              <span>Service Specifications</span>
              <textarea
                value={service.service_specifications}
                onChange={(e) =>
                  handleServiceChange(
                    index,
                    "service_specifications",
                    e.target.value
                  )
                }
                className="border p-2 rounded w-full"
              />
            </label>
            <label>
              <span>Upload Service Image</span>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleServiceImageUpload(e, index)}
                className="border p-2 rounded w-full"
              />
              {service.service_image && (
                <button
                  onClick={() => removeServiceImage(index)}
                  className="text-red-500"
                >
                  Remove Image{" "}
                  <i
                    className="fa fa-trash text-red-500"
                    aria-hidden="true"
                  ></i>
                </button>
              )}
            </label>
            <label>
              <span>Upload Service Brochure</span>
              <input
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"

                onChange={(e) => handleServiceBrochureUpload(e, index)}
                className="border p-2 rounded w-full"
              />
              {service.service_brochure_key && (
                <div className="mt-2">
                  <button
                    onClick={() => removeServiceBrochure(index)}
                    className="text-red-500"
                  >
                    Remove Brochure{" "}
                    <i
                      className="fa fa-trash text-red-500"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              )}
            </label>
          </div>
        ))}
      <button type="button" onClick={addService}
        className={`custom-btn ${!canAddService() ? 'bg-gray-200 cursor-not-allowed' : ''}`}
      >
        + Add Service
      </button>

      <div className="flex mt-6">
        <button type="submit" className=" custom-btn" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoaderButton />
              Submitting
            </div>
          ) : (
            <>Submit</>
          )}
        </button>
       <button className="custom-btn ml-2" onClick={()=>{
            handleCancel()
          }}>
            Cancel
          </button>
      </div>
    </div>
  );
};

export default CompanyProfileCreate;
