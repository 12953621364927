import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { stateCityData } from '../Data';
import defaultImage from "../../assets/images/default_company.jpg"
import apiClient from "../ApiClient";
import LoaderButton from "../Common/LoaderButton";

const CompanyProfileCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fullPhoneNumber, selectedProfile, selectedCategory } =
  location.state || { fullPhoneNumber: '', selectedProfile: '', selectedCategory: '' };

  const [imageFile, setImageFile] = useState(null);
  const [fileKey, setFileKey] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [brochureKey, setBrochureKey] = useState(null);
  const [loading, setLoading] = useState(false)

  // {
  //   product_image: "",
  //   product_brochure_key: "",
  //   product_name: "",
  //   product_price: 0,
  //   product_description: "",
  //   product_specifications: "",
  // },
  const [products, setProducts] = useState([]);

  // {
  //   service_image: "",
  //   service_brochure_key: "",
  //   service_name: "",
  //   service_price: 0,
  //   service_description: "",
  //   service_specifications: "",
  // },
  const [services, setServices] = useState([]);

  const [imageUrls, setImageUrls] = useState();

  const [postData, setPostData] = useState({
    company_name: "",
    business_registration_number: "",
    company_email_id: "",
    company_located_city: "",
    company_located_state: "",
    Website: "",
    company_address: "",
    company_description: "",
  });

  const handleInputChange = (key, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const uploadFile = async (base64, type) => {
    setLoading(true)
    try {
      const response = await apiClient.post("/uploadFile", {
        command: "uploadFile",
        headers: { "Content-Type": type },
        fileBuffer: base64,
      });
      if (response.data.statusCode === 200) {
        const body = JSON.parse(response.data.body);
        return body.brochureKey || body.fileKey;
      } else {
        throw new Error("Upload failed");
      }
    } catch (error) {
      toast.error("File upload failed.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 100 KB limit
        toast.error("Image file size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedKey = await uploadFile(base64, file.type);
      setImageFile(file);
      setFileKey(uploadedKey);
    } else {
      toast.info("Please upload a valid file.");
    }
  };

  const handleBrochureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedKey = await uploadFile(base64, file.type);
      setPdfFile(file);
      setBrochureKey(uploadedKey);
    } else {
      toast.info("Please upload a valid File for the brochure.");
    }
  };

  const handleProductImageUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 100 KB limit
        toast.error("Image file size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedFileKey = await uploadFile(base64, file.type);
      const updatedProducts = [...products];
      updatedProducts[index].product_image = uploadedFileKey;
      setProducts(updatedProducts);
    }
  };

  const handleProductBrochureUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedKey = await uploadFile(base64, file.type);
      const updatedProducts = [...products];
      updatedProducts[index].product_brochure_key = uploadedKey;
      setProducts(updatedProducts);
    } else {
      alert("Please upload a valid File for the brochure.");
    }
  };

  const handleServiceImageUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 100 KB limit
        toast.error("Image file size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedFileKey = await uploadFile(base64, file.type);
      const updatedServices = [...services];
      updatedServices[index].service_image = uploadedFileKey;
      setServices(updatedServices);
    }
  };

  const handleServiceBrochureUpload = async (event, index) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        // 200 KB limit
        toast.error("File size must be less than 200 KB.");
        return;
      }
      const base64 = await convertToBase64(file);
      const uploadedKey = await uploadFile(base64, file.type);
      const updatedServices = [...services];
      updatedServices[index].service_brochure_key = uploadedKey;
      setServices(updatedServices);
    } else {
      alert("Please upload a valid File for the brochure.");
    }
  };

  const handleProductChange = (index, key, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][key] = value;
    setProducts(updatedProducts);
  };

  const handleServiceChange = (index, key, value) => {
    const updatedServices = [...services];
    updatedServices[index][key] = value;
    setServices(updatedServices);
  };

  const canAddProduct = () => {
    if (products.length === 0) return true;

      const lastProduct = products[products.length - 1];
      return lastProduct && lastProduct.product_name 

    
  };

  const canAddService = () => {
    if (services.length === 0) return true;

      const lastService = services[services.length - 1];
      return lastService && lastService.service_name

    
  };

  const addProduct = () => {
    if (!canAddProduct()) {
      toast.error("Product name is required to add a new product.");
      return;
    }
    setProducts([
      ...products,
      {
        product_image: "",
        product_brochure_key: "",
        product_name: "",
        product_price: 0,
        product_description: "",
        product_specifications: "",
      },
    ]);
  };

  const addService = () => {
    if (!canAddService()) {
      toast.error("Service name is required to add a new service.");
      return;
    }
    setServices([
      ...services,
      {
        service_image: "",
        service_brochure_key: "",
        service_name: "",
        service_price: 0,
        service_description: "",
        service_specifications: "",
      },
    ]);
  };

  const states = Object.keys(stateCityData);
  const cities = postData.company_located_state ? stateCityData[postData.company_located_state] : [];

  const handleSubmit = async () => {
    setLoading(true)
    if (!postData.company_name) {
      toast.error("Company name is required");
      return setLoading(false);
    }
    if (!postData.business_registration_number) {
      toast.error("Business registration number is required");
      return setLoading(false);

    }
    if (!postData.company_located_city) {
      toast.error("City is required");
      return setLoading(false);
      
    }
    if (!postData.company_located_state) {
      toast.error("State is required");
      return setLoading(false);
    }
    if (!postData.company_email_id) {
      toast.error("Email ID is required");
      return setLoading(false);
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(postData.company_email_id)) {
      toast.error("Please enter a valid email address.");
      return setLoading(false);
    }
    if (products.length > 0) {
      const invalidProduct = products.find(
        (product) => !product.product_name 
      );

      if (invalidProduct) {
        toast.error("Name of the product is required");
        return setLoading(false);
      }
    }

    if (services.length > 0) {
      const invalidProduct = services.find(
        (service) => !service.service_name 
      );

      if (invalidProduct) {
        toast.error("Name of the service is required");
        return setLoading(false);
      }
    }

    const payload = {
      command: "companySignUp",
      company_name: postData.company_name,
      fileKey: fileKey,
      brochureKey: brochureKey,
      business_registration_number: postData.business_registration_number,
      select_your_profile: selectedProfile || "Manufacturer",
      category: selectedCategory.value || "Electronics",
      company_located_city: postData.company_located_city,
      company_located_state: postData.company_located_state,
      company_address: postData.company_address,
      company_contact_number: fullPhoneNumber,
      company_email_id: postData.company_email_id,
      Website: postData.Website,
      company_description: postData.company_description,
      products: products,
      services: services,
    };
    // console.log(payload)
    try {
      const response = await apiClient.post("/companySignUp", payload);
      if (response.data.status === "success") {
        toast.success(
          "Company Profile Created Successfully"
        );
        toast.success(
          "please wait for admin approval"
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        toast.error("Failed to create company profile.");
        toast.error(response.data.ErrorMessage);
      }
    } catch (error) {
      toast.error("An error occurred while creating the company profile.");
    } finally {
      setLoading(false)
    }
  };

  // delete from s3

   const deleteFileFromS3 = async (key) => {
    setLoading(true)
    try {
      const response = await apiClient.post("/deleteFileFromS3", {
        command: "deleteFileFromS3",
        key: key,
      });
      // console.log("deleteFileFromS3", response.data.message);
    } catch (error) {
      console.error("Error deleting file:", error);
      return false;
    } finally {
      setLoading (false)
    }
  };

  const deleteService = async (index) => {
    const service = services[index];
    if (service.service_image) {
      try {
        await deleteFileFromS3(service.service_image);
      } catch (error) {
        console.error("Error deleting service image from S3:", error);
        toast.error("Failed to delete service image from S3.");
      }
    }

    // Check and delete service brochure from S3 if it exists
    if (service.service_brochure_key) {
      try {
        await deleteFileFromS3(service.service_brochure_key);
      } catch (error) {
        console.error("Error deleting service brochure from S3:", error);
        toast.error("Failed to delete service brochure from S3.");
      }
    }
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const deleteProduct = async (index) => {
    const product = products[index];
    if (product.product_image) {
      try {
        await deleteFileFromS3(product.product_image);
      } catch (error) {
        console.error("Error deleting product image from S3:", error);
        toast.error("Failed to delete product image from S3.");
      }
    }

    if (product.product_brochure_key) {
      try {
        await deleteFileFromS3(product.product_brochure_key);
      } catch (error) {
        console.error("Error deleting product brochure from S3:", error);
        toast.error("Failed to delete product brochure from S3.");
      }
    }
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const removeProductImage = async (index) => {
    const product = products[index];
    if (product.product_image) {
      await deleteFileFromS3(product.product_image);
    }

    const updatedProducts = [...products];
    updatedProducts[index].product_image = "";
    setProducts(updatedProducts);
  };

  const removeProductBrochure = async (index) => {
    const product = products[index];
    if (product.product_brochure_key) {
      await deleteFileFromS3(product.product_brochure_key);
    }

    const updatedProducts = [...products];
    updatedProducts[index].product_brochure_key = "";
    setProducts(updatedProducts);
  };

  const removeServiceImage = async (index) => {
    const service = services[index];
    if (service.service_image) {
      await deleteFileFromS3(service.service_image);
    }
    const updatedServices = [...services];
    updatedServices[index].service_image = "";
    setServices(updatedServices);
  };

  const removeServiceBrochure = async (index) => {
    const service = services[index];
    if (service.service_brochure_key) {
      await deleteFileFromS3(service.service_brochure_key);
    }

    const updatedServices = [...services];
    updatedServices[index].service_brochure_key = "";
    setServices(updatedServices);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 mt-10 bg-white shadow-md rounded-md">
      <ToastContainer/>
      <h1 className="custom-h1 mb-6 text-center">
        Sign Up
      </h1>
      <div className="grid gap-4 md:grid-cols-2">
        <label>
        <label>
  <span>Company Name</span>
  <span className="text-red-500"> *</span>
</label>

          <input
            type="text"
            value={postData.company_name}
            onChange={(e) => handleInputChange("company_name", e.target.value)}
            className="border p-2 rounded w-full"
            required
          />
        </label>
        <label>
          <span>CIN / Business Registration Number</span>
  <span className="text-red-500"> *</span>

          <input
            type="text"
            value={postData.business_registration_number}
            onChange={(e) =>
              handleInputChange("business_registration_number", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          />
        </label>
        <label>
          <span>Company Email ID</span>
  <span className="text-red-500"> *</span>

          <input
            type="email"
            value={postData.company_email_id}
            onChange={(e) =>
              handleInputChange("company_email_id", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          />
        </label>
       


        <label>
          <span>State <span className="text-red-500"> *</span></span>
          <select
            name="selectedState"
            value={postData.company_located_state}
            onChange={(e) =>
              handleInputChange("company_located_state", e.target.value)
            }
           
            className="w-full p-2 border border-gray-300 rounded-lg"
            required
          >
          <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>

        </label>
        <label>
          <span>City <span className="text-red-500"> *</span></span>
          <select
            value={postData.company_located_city}
            onChange={(e) =>
              handleInputChange("company_located_city", e.target.value)
            }
            className="border p-2 rounded w-full"
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </label>




        <label>
          <span>Website</span>
          <input
            type="url"
            value={postData.Website}
            onChange={(e) => handleInputChange("Website", e.target.value)}
            className="border p-2 rounded w-full"
          />
        </label>
        <label>
          <span>Company Address</span>
          <input
            type="text"
            value={postData.company_address}
            onChange={(e) =>
              handleInputChange("company_address", e.target.value)
            }
            className="border p-2 rounded w-full"
          />
        </label>
        <label>
          <span>Company Description</span>
          <textarea
            value={postData.company_description}
            onChange={(e) =>
              handleInputChange("company_description", e.target.value)
            }
            className="border p-2 rounded w-full"
          />
        </label>
        <label>
          <div>Upload Company Logo</div>
          <input
            type="file"
            accept="image/*"
            id="logo_company"
            onChange={handleFileChange}
            className="border p-2 rounded hidden w-full"
          />
          {!imageFile && (
            <div className="relative mt-4 inline-block">
             
              <img
                src={defaultImage}
                alt="company logo"
                onContextMenu={(e) => e.preventDefault()}
                className="image-standard mb-2 cursor-pointer"
                onClick={() => document.getElementById("logo_company").click()} // Clicking on the image opens file picker
              />
              <button
                type="button"
                className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
                onClick={() => document.getElementById("logo_company").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
            </div>
          )}
          {imageFile && (
            <img
              src={URL.createObjectURL(imageFile)}
              onContextMenu={(e) => e.preventDefault()}
              alt="Company Preview"
              className="mt-2 image-standard object-contain"
            />
          )}
          <button
                type="button"
                className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
                onClick={() => document.getElementById("logo_company").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
          {imageFile && (
            <button onClick={async() =>{
              if(fileKey){
                await deleteFileFromS3(fileKey)
              }
              setImageFile(null)
              setFileKey(null)
              setImageUrls(defaultImage)
            }} className="text-red-500">
              Remove Image{" "}
              <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
            </button>
          )}
        </label>
        <label>
          <span>Upload Company Catalogue</span>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
            onChange={handleBrochureChange}
            className="border p-2 rounded w-full"
          />
           {brochureKey && (
            <div className="mt-2">
              <button onClick={async() =>{
                console.log(brochureKey)
              if(brochureKey){
                await deleteFileFromS3(brochureKey)
              }
              setPdfFile(null)
              setBrochureKey(null)
            }} className="text-red-500">
                Remove Brochure
                <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            </div>
          )}
        </label>
      </div>

      {/* Products Section */}
      <h2 className="text-xl font-bold mt-6">Products</h2>
      {products.map((product, index) => (
        <div key={index} className="border p-4 mb-4 rounded">
          <div className="w-full flex justify-end ">
            <button
              className="text-red-500"
              onClick={() => deleteProduct(index)}
            >
              Delete <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
            </button>
          </div>
          <h3 className="text-lg font-semibold">Product {index + 1}</h3>
          <label>
            <span>Product Name</span>
  <span className="text-red-500"> *</span>

            <input
              type="text"
              value={product.product_name}
              onChange={(e) =>
                handleProductChange(index, "product_name", e.target.value)
              }
              className="border p-2 rounded w-full"
              required
            />
          </label>
          <label>
            <span>Product Price</span>
            <input
              type="number"
              value={product.product_price}
              onChange={(e) =>
                handleProductChange(
                  index,
                  "product_price",
                  parseFloat(e.target.value)
                )
              }
              className="border p-2 rounded w-full"
              required
            />
          </label>
          <label>
            <span>Product Description</span>

            <textarea
              value={product.product_description}
              onChange={(e) =>
                handleProductChange(
                  index,
                  "product_description",
                  e.target.value
                )
              }
              className="border p-2 rounded w-full"
            />
          </label>
          <label>
            <span>Product Specifications</span>
            <textarea
              value={product.product_specifications}
              onChange={(e) =>
                handleProductChange(
                  index,
                  "product_specifications",
                  e.target.value
                )
              }
              className="border p-2 rounded w-full"
            />
          </label>
          <label>
            <span>Upload Product Image</span>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleProductImageUpload(e, index)}
              className="border p-2 rounded w-full"
            />
            {product.product_image && (
              <button
                onClick={() => removeProductImage(index)}
                className="text-red-500"
              >
                Remove Image <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            )}
          </label>
          <label>
            <span>Upload Product Brochure</span>
            <input
              type="file"
               accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
              onChange={(e) => handleProductBrochureUpload(e, index)}
              className="border p-2 rounded w-full"
            />
            {product.product_brochure_key && (
              <div className="mt-2">
                <button
                  onClick={() => removeProductBrochure(index)}
                  className="text-red-500"
                >
                  Remove Brochure
                  <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
                </button>
              </div>
            )}
          </label>
        </div>
      ))}
      <button type="button" onClick={addProduct} 
            className={`custom-btn ${!canAddProduct() ? 'bg-gray-200 cursor-not-allowed' : ''}`}

      >
        + Add Product
      </button>

      {/* Services Section */}
      <h2 className="text-xl font-bold mt-6">Services</h2>
      {services.map((service, index) => (
        <div key={index} className="border p-4 mb-4 rounded">
          <div className="w-full flex justify-end ">
            <button
              className="text-red-500"
              onClick={() => deleteService(index)}
            >
              Delete{" "}
              <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
            </button>
          </div>
          <h3 className="text-lg font-semibold">Service {index + 1}</h3>
          <label>
            <span>Service Name <span className="text-red-500"> *</span></span>
            <input
              type="text"
              value={service.service_name}
              onChange={(e) =>
                handleServiceChange(index, "service_name", e.target.value)
              }
              className="border p-2 rounded w-full"
              required
            />
          </label>
          <label>
            <span>Service Price</span>
            <input
              type="number"
              value={service.service_price}
              onChange={(e) =>
                handleServiceChange(
                  index,
                  "service_price",
                  parseFloat(e.target.value)
                )
              }
              className="border p-2 rounded w-full"
              required
            />
          </label>
          <label>
            <span>Service Description</span>
            <textarea
              value={service.service_description}
              onChange={(e) =>
                handleServiceChange(
                  index,
                  "service_description",
                  e.target.value
                )
              }
              className="border p-2 rounded w-full"
            />
          </label>
          <label>
            <span>Service Specifications</span>
            <textarea
              value={service.service_specifications}
              onChange={(e) =>
                handleServiceChange(
                  index,
                  "service_specifications",
                  e.target.value
                )
              }
              className="border p-2 rounded w-full"
            />
          </label>
          <label>
            <span>Upload Service Image</span>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleServiceImageUpload(e, index)}
              className="border p-2 rounded w-full"
            />
            {service.service_image && (
              <button
                onClick={() => removeServiceImage(index)}
                className="text-red-500"
              >
                Remove Image <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
              </button>
            )}
          </label>
          <label>
            <span>Upload Service Brochure</span>
            <input
              type="file"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
              onChange={(e) => handleServiceBrochureUpload(e, index)}
              className="border p-2 rounded w-full"
            />
            {service.service_brochure_key && (
              <div className="mt-2">
                <button
                  onClick={() => removeServiceBrochure(index)}
                  className="text-red-500"
                >
                  Remove Brochure{" "}
                  <i
                    className="fa fa-trash text-red-500"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            )}
          </label>
        </div>
      ))}
      <button type="button" onClick={addService} 
            className={`custom-btn ${!canAddService() ? 'bg-gray-200 cursor-not-allowed' : ''}`}

      >
        + Add Service
      </button>

    <div className="flex ">
    <button type="submit" className="mt-6 custom-btn" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoaderButton />
              Submitting
            </div>
          ) : (
            <>Submit</>
          )}
        </button>
    </div>
    </div>
  );
};

export default CompanyProfileCreate;
