import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiClient from './ApiClient'; // Adjust import as necessary
import { AuthContext } from './AuthContext';

const AccountChecker = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (user) {
      if (user.user_type === "company") {
        setUserId(user.company_id);
      } else {
        setUserId(user.user_id);
      }
    }
  }, [user]);

  useEffect(() => {
    const checkAccountStatus = async () => {
      if (!userId) return; // Prevent calling API if userId is not set

      try {
        const response = await apiClient.post("/getUserDetails", {
          command: "getUserDetails",
          user_id: userId,
        });
        if (response.data.errorType === "Error") {
          localStorage.removeItem("user");
          setUser(null);
          navigate('/login');
        }
      } catch (error) {
        console.error("Error checking account status:", error);
      }
    };

    const intervalId = setInterval(checkAccountStatus, 5000);

    return () => clearInterval(intervalId);
  }, [navigate, userId]);

  return null;
};

export default AccountChecker;
