import React from 'react';
import { useLocation } from 'react-router-dom';

const DocumentViewer = () => {
  const location = useLocation();
  const { url } = location.state || {};

  if (!url) {
    return <p>No document available</p>;
  }

  return (
    <div className="min-h-screen p-6 flex items-center justify-center">
      <iframe
        src={url}
        width="100%"
        height="100%"
        title="Document Viewer"
        className="border-0"
      />
    </div>
  );
};

export default DocumentViewer;
