import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaPen, FaPlus, FaTrash } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient"; // Importing your apiClient
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";

const MyCompanyArticleList = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const { user } = useContext(AuthContext);
  const [userId, setUserId] = useState("");
  const [imageUrls, setImageUrls] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filekey, setFileKey] = useState("")
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (user) {
      setUserId(user.company_id);
      fetchArticles();
    } else {
      toast.error("Please log in to view your articles.");
    }
  }, [user]);

  const fetchArticles = async () => {
    setLoading(true)

    try {
      const res = await apiClient.post(
        "/getUsersAllArticles",
        {
          command: "getUsersAllArticles",
          user_id: user.company_id,
        }
      );
      const allArticles = res.data.response || [];
      setArticles(allArticles);
      setFilteredArticles(allArticles);
      const urlsObject = {};
      await Promise.all(
        allArticles.map(async (article) => {
          if (article.fileKey) {
            try {
              const res = await apiClient.post(
                "/getObjectSignedUrl",
                {
                  command: "getObjectSignedUrl",
                  key: article.fileKey,
                }

              );
              setFileKey(article.fileKey)
              const img_url = res.data;
              if (img_url) {
                urlsObject[article.article_id] = img_url;
              }
            } catch (error) {
              console.error("Error getting signed URL for article:", error);
            }
          }
        })
      );
      setImageUrls(urlsObject);
    } catch (error) {
      toast.error("Error fetching articles");
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    const filtered = articles.filter(
      (article) =>
        article.article_title
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        article.article_body.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredArticles(filtered);
  }, [searchQuery, articles])

  const handleSearch = () => {
    const filtered = articles.filter(
      (article) =>
        article.article_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        article.article_body.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredArticles(filtered);
  };

  const handlePostArticle = () => {
    navigate("/company-dashboard/articles/post");
  };

  const handleEditArticle = (articles, imageUrls) => {
    navigate(`/company-dashboard/profile/my-article-edit`, {
      state: { articles, imageUrls },
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  const handleDeleteArticle = async (articleId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this article?");
    if (confirmDelete) {
      setLoading(true)

      try {
        // Delete  article
        await apiClient.post(
          "/deleteArticle",
          {
            command: "deleteArticle",
            user_id: userId,
            article_id: articleId,
          }
        );

        // Delete the  image s3
        if (filekey) {
          const res = await apiClient.post(
            "/deleteFileFromS3",
            {
              command: "deleteFileFromS3",
              key: filekey,
            }
          );

          // console.log("deleteFileFromS3",res)
        }

        toast.success("Article deleted successfully!");
        setFilteredArticles(filteredArticles.filter((article) => article.article_id !== articleId));
      } catch (error) {
        toast.error("Error deleting article or image");
      } finally {
        setLoading(false)
      }
    }
  };


  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    }).replace(/ /g, "/");
  };

  const navigateToArticleDetails = (article_id) => {
    navigate(`/company-dashboard/articles/details/${article_id}`);
  };


  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-[100%] mx-auto py-2 px-4 mt-6">
      <ToastContainer />
      <Helmet>
        <title>BME Bharath | My articles</title>
        <meta property="og:title" content="BME Bharath | My Articles"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/articles/post" />
      </Helmet>
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>

      {filteredArticles.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 gap-6">
          {filteredArticles.map((article) => (
            <div
              key={article.article_id}
              onClick={() => {
                navigateToArticleDetails(article.article_id)
              }}
              className="p-4 bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <img
                src={imageUrls[article.article_id] || defaultImage}
                alt={article.article_title}
                onContextMenu={(e) => e.preventDefault()}
                className="w-full h-48 object-contain mb-4"
              />

              <div className="flex flex-col">
                <div className="flex flex-col justify-start mb-2">
                  <span className="font-bold text-lg">{article.article_author || "N/A"}</span>
                  <span className="text-sm text-gray-500 -mt-1">{article?.author_category}</span>
                </div>
                <div className="flex justify-start mb-2">
                  <span className="font-bold w-24">Article Title</span>
                  <span>: {article.article_title.slice(0, 26) || "N/A"}{article.article_title.length > 30 && ".."}</span>
                </div>
                <div className="flex justify-start mb-2">
                  <span className="font-bold w-24">Posted on</span>
                  <span>: {formatDate(article.article_posted_on)}</span>
                </div>
                <p className="text-gray-700 mb-3 text-justify">
                  {article.article_body.slice(0, 70)} ..

                </p>
                <div className="flex">
                  <button
                    onClick={() => handleEditArticle(article, imageUrls[article.article_id])
                    }
                    className="custom-btn mt-2 mr-2 flex"
                  >
                    <FaPen className="mr-2 mt-1" />
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteArticle(article.article_id)}
                    className="custom-btn mt-2 flex"
                  >
                    <FaTrash className="mr-2 mt-1" />
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No articles found</p>
      )}

      <button
        onClick={handlePostArticle}
        className="fixed z-50 bottom-6 right-11 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
      >
        <FaPlus size={24} />
      </button>
    </div>
  );
};

export default MyCompanyArticleList;
