import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { ToastContainer } from 'react-toastify';
import { GrPowerReset } from "react-icons/gr";
import { Helmet } from 'react-helmet';
import apiClient from "../../../ApiClient"
import Loader from '../../../Common/Loader';

const ArticleDetails = () => {
  const { article_id } = useParams(); // Get article_id from the URL
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(``)
  const [fileKey, setFileKey] = useState();
  const [loading, setLoading] = useState(false)

  // State variables
  const [article, setArticle] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });

  // Fetch the article data on mount
  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true)
      try {
        // Fetch all articles from the API
        const response = await apiClient.post('/getAllArticles', {
          command: 'getAllArticles',
        });
        const articleData = response.data.response.find(article => article.article_id === article_id);
          setArticle(articleData); 
          setFileKey(articleData?.fileKey); 
  
      } catch (error) {
        console.error('Error fetching article:', error);
      } finally {
        setLoading (false)
      }
    };
    

    fetchArticle();
  }, [article_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [article]);

  useEffect(() => {
    const handleProfileImage = async (profileKey) => {
      if (!profileKey) {
        return setImageUrl(defaultImage)
        
      }
      setLoading (true)
      try {
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        if (response.status === 200) {
          console.log(response.data)
          setImageUrl(response.data || defaultImage);
        }
      } catch (error) {
        console.error("Error fetching profile URL:", error);
      }  finally {
        setLoading (false)
      }
    };
    handleProfileImage(fileKey);
  }, [fileKey]);


  // Modal functions for zooming and dragging
  const openModal = () => {
    setModalImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const handleZoom = (increment) => {
    setZoomLevel((prevZoom) => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition((prevPosition) => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const navigateToUserDetails = (user_id, user_type) => {
    const path = user_type === 'company'
      ? `/user-dashboard/company/details/${user_id}`
      : `/user-dashboard/users/user_details/${user_id}`;

    navigate(path);
  };
  
  if (loading) {
    return <Loader/>
  }

  if (!article) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 gap-3">
        <ToastContainer />
        <p className='m-0 text-[16px]'>No article found.</p>
        <button onClick={() => navigate('/company-dashboard/article')} className='custom-btn'>All articles</button>
      </div>
    );
  }

  return (
    <div className="p-6 flex items-center justify-center">
      <Helmet>
        <title>{`${article.article_title} - ${article.article_author}`}</title>
        <meta property="og:title" content={`${article.article_title} ${article.article_author}`} />
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content={`https://bmebharat.com/user-dashboard/articles/${article.article_id}`} />
      </Helmet>
      <ToastContainer />
      <div className="max-w-3xl w-full bg-white rounded-lg shadow-md p-8">
        <h1 className="text-[16px] font-bold mb-6">
          {article.article_title || "No Title"}
        </h1>
        <div className="mb-6 mx-auto flex justify-center items-center">
          {imageUrl && (
            <img
              src={imageUrl || defaultImage}
              alt={article.article_title}
              onContextMenu={(e) => e.preventDefault()}
              className="w-[300px] h-auto object-contain rounded-lg mb-6 cursor-pointer"
              onClick={openModal}
            />
          )}
        </div>

        <div className="space-y-4">
          <div className="flex flex-col justify-start mb-2 cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            navigateToUserDetails(article.user_id, article.user_type);
          }}>
            <span className="font-bold text-lg">{article.article_author || "N/A"}</span>
            <span className="text-sm text-gray-500">{article?.author_category}</span>
          </div>
          <p className="">
            {new Date(article.article_posted_on * 1000).toLocaleDateString("en-GB", {
              day: '2-digit', month: 'short', year: 'numeric'
            }) || "Not Specified"}
          </p>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-24">Title</span>
            <span>: {article.article_title || "Not Specified"}</span>
          </div>
          <p className=" text-justify">
            {article.article_body || "Not Specified"}
          </p>
          <div className="flex justify-start mb-2">
            <span className="font-bold w-24">Conclusion</span>
            <span>: {article.article_conclusion || "Not Specified"}</span>
          </div>
        </div>

        <div className="mt-8 flex items-center justify-center">
          <button onClick={() => navigate(-1)} className="custom-btn">
            Back
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleDetails;
