import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import CompanyArticleList from './Articles/AdminArticleList';
import ComapanyArticlePost from './Articles/AdminArticlePost';
import CompanyArticleDetails from './Articles/AdminArticleDetails';

import CompanyForumList from './Forum/AdminForumList';
import CompanyForumDetails from './Forum/AdminForumDetails';
import CompanyForumPost from './Forum/AdminForumPost';
import UserComments from './Forum/UserComments';

import CompanyProfile from './Profile/AdminProfile';
import NavbarAdmin from '../../Common/NavbarAdmin';

import Footer from '../../Common/FooterCompany';
import UserHome from '../User/UserHome';


import AdminProfileUpdate from './Profile/AdminProfileUpdate';
import AdminHomeBanner from './Profile/AdminHomeBanner';

import CompanyList from './Company/CompanyList';
import CompanyDetails from './Company/CompanyDetails';

import MyCompanyArticleList from './Profile/MyAdminArticleList';
import MycompanyArticleEdit from './Profile/MyAdminArticleEdit';
import MyCompanyForumList from './Profile/MyAdminForumList';
import MyCompanyForumEdit from './Profile/MyAdminForumEdit';

import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../../AuthContext';
import { Helmet } from 'react-helmet';
import AllTransactionListScreen from './Profile/AllTransactionList';
import AdminAdsBanner from './Profile/AdminAdsBanner';

function AdminDashboard() {
  const [ trialVisible, setTrialVisible ] = useState(false);
  const {user, setUser } = useContext(AuthContext)
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const navigate = useNavigate()

  return (
    <div>
      <Helmet>
        <title>BME Bharath | Admin dashboard</title>
        <meta property="og:title" content="BME Bharat | Admin dashboard"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://web.bmebharat.com/admin-dashboard" />
      </Helmet>
      <ToastContainer/>

      <div className="main-content" > 
      <NavbarAdmin /> 

        <Routes>
        <Route path="/" element={<UserHome/>} />

          {/* admin article  */}
          <Route path="articles" element={<CompanyArticleList/>} />
          <Route path="articles/details/:article_id" element={<CompanyArticleDetails/>} />
          <Route path="articles/post" element={<ComapanyArticlePost/>} />
          <Route path="forums" element={<CompanyForumList />} />
          <Route path="forums/details/:forum_id" element={<CompanyForumDetails />} />
          <Route path="forums/post" element={<CompanyForumPost />} />
          <Route path="profile" element={<CompanyProfile />} />
          <Route path="profile/transaction_list" element={<AllTransactionListScreen/>} />
          <Route path="profile/home_banner" element={<AdminHomeBanner/>} />
          <Route path="profile/ads_banner" element={<AdminAdsBanner/>} />

          <Route path="profile/update" element={<AdminProfileUpdate/>} />

          <Route path="profile/my-article-list" element={<MyCompanyArticleList/>} />
          <Route path="profile/my-article-edit" element={<MycompanyArticleEdit/>} />

          <Route path="profile/my-forum-list" element={<MyCompanyForumList />} />
          <Route path="profile/my-forum-edit" element={<MyCompanyForumEdit />} />

          <Route path="company" element={<CompanyList />} />
          <Route path="forums/comments/:forum_id" element={<UserComments />} />
          <Route path="company/details/:company_id" element={<CompanyDetails />} />
          <Route path="company" element={<CompanyList />} />

        </Routes>
        <Footer/>
      </div>
    </div>
  );
}

export default AdminDashboard;

