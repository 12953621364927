import React, { useState, useRef, useContext, useEffect } from 'react'; 
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../Common/Loader"; 
import apiClient from "../ApiClient"; 
import { AuthContext } from '../AuthContext';
import { TrialContext } from '../TrialContext';
import LoaderButton from '../Common/LoaderButton';

const LoginVerifyOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { fullPhoneNumber, userId } = location.state;
  const { login } = useContext(AuthContext);
  const { setTrialVisible } = useContext(TrialContext);
  const [statusMessage, setStatusMessage] = useState();
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [approve, setApprove] = useState(false);
  
  // State for timer
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  
  useEffect(() => {
    otpInputs.current[0].focus();

    // Start timer countdown
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleOTPChange = (index, value) => {
    if (/^[0-9a-zA-Z]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOTP(newOtp);

      // Move focus to the next input if the value is valid
      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to the previous input if backspace is pressed and current is empty
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    } 
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVerifyOTP();
    }
  };

  const handleAlertConfirm = () => {
    setAlertVisible(false);
    if(approve) {
      navigate("/")
    }
    if (deleteAccount) {
      apiClient.post('/deleteAccount', {
        command: "deleteAccount",
        user_phone_number: fullPhoneNumber,
      }).then((res) => {
        // console.log(res)
        if (res.data.status === 'success') {
          setAlertMessage(res.data.status_message);
        }
        setAlertVisible(true);
        navigate('/login'); 
      }).catch((error) => {
        console.error("Error deleting account:", error);
      });
    }
  };

  const handleVerifyOTP = () => {
    const enteredOTP = otp.join('');
    if (enteredOTP.length !== 6 || !/^\d+$/.test(enteredOTP)) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }

    setLoading(true);

    apiClient.post('/verifyOtpMsg91', {
      command: 'verifyOtpMsg91',
      otp: enteredOTP,
      user_phone_number: fullPhoneNumber,
    }, {
      headers: {
        'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
      },
    })
    .then((res) => {
      setLoading(false);
      if (res.data.type === "success") {
        handleUserDetailsFetch();
      } else {
        toast.error('The entered OTP is incorrect. Please verify and try again.')
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error("You don't have an internet connection");
    });
  };

  const handleUserDetailsFetch = () => {
    apiClient.post('/getUserDetails', {
      command: "getUserDetails",
      user_id: userId,
    }).then(async (res) => {
      if (res.data.status_message.user_type === 'users') {
        // setTrialVisible(true);
        toast.success('Successfully Logged in');
        setTimeout(() => {
          login(res.data.status_message);
          navigate('/user-dashboard'); 
        }, 1000);
        window.scrollTo(0, 0);

      } else if (res.data.status_message.user_type === 'company') {
        handleCompanyDetailsFetch();
      } else {
        handleAdminDetailsFetch(res);
      }
    });
  };

  const handleCompanyDetailsFetch = () => {
    apiClient.post('/getCompanyDetails', {
      command: "getCompanyDetails",
      company_id: userId,
    }, {
      headers: {
        'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
      },
    }).then(async (res) => {
      if (res.data.status === "success") {
        if (res.data.status_message.admin_approval === "Pending") {
         setAlertMessage("Please wait for admin approval");
         setApprove(true)
          setAlertVisible(true);
        } else if (res.data.status_message.admin_approval === "Approved") {
          setTrialVisible(true);
          toast.success('Successfully Logged in');
          setTimeout(() => {
            login(res.data.status_message);
            console.log("res.data.status_message",res.data.status_message)
            navigate('/company-dashboard');
        window.scrollTo(0, 0);

          }, 1000);
        } else if (res.data.status_message.admin_approval === "Rejected") {
          setLoading(false);
          setAlertMessage("Your company has been rejected. Press OK to Delete Account");
          setDeleteAccount(true);
          setAlertVisible(true);
        }
      }
    });
  };

  const handleAdminDetailsFetch = async (res) => {
    toast.success('Successfully Logged in');
    setTimeout(() => {
      login(res.data.status_message);
      navigate('/admin-dashboard'); 
    }, 1000);
    window.scrollTo(0, 0);
  };

  const resendHandle = async () => {
    try {
      const res = await apiClient.post("/resendOtpMsg91", {
        command: "resendOtpMsg91",
        user_phone_number: fullPhoneNumber,
      });
      // console.log(res);
      toast.success("OTP resent successfully!");
      setTimer(30);
      setCanResend(false);
    } catch (error) {
      console.error("Error resending OTP:", error);
      toast.error("Failed to resend OTP. Please try again.");
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen">
      <ToastContainer/>
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">Verify Your OTP</h2>
        <div className="text-center text-gray-600 mb-4">
          Enter the OTP sent to: <strong>{fullPhoneNumber}</strong>
        </div>
        <div className="flex justify-between mb-4">
          {otp.map((digit, index) => (
            <input
              key={index}
              className="w-12 h-12 border rounded text-center text-xl focus:outline-none focus:border-blue-500"
              placeholder="•"
              type="tel"
              maxLength={1}
              onChange={(e) => handleOTPChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              value={digit}
              ref={(ref) => (otpInputs.current[index] = ref)}
            />
          ))}
        </div>
        <div className="flex justify-between mb-3">
          <div className=" text-gray-700">
            {canResend ? "You can resend OTP" : `Time Remaining ${timer}s`}
          </div>
          <button 
            onClick={canResend ? resendHandle : undefined} 
            className={` mb-3  text-blue-500 underline ${canResend ? '' : 'opacity-50 cursor-not-allowed'}`} 
            disabled={!canResend}
          >
            Resend OTP
          </button>
        </div>
        <button type="submit" className="w-full custom-btn" onClick={()=> [
          handleVerifyOTP()
        ]} disabled={loading}>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoaderButton />
              Verifying OTP
            </div>
          ) : (
            <>Verify OTP</>
          )}
        </button>

        {/* Alert dialog for alerts */}
        {alertVisible && (
          <div className="mt-4 p-4 shadow-md border border-gray-100 rounded flex flex-col justify-center items-center">
            <div className='text-center'>{alertMessage}</div>
            <button 
              onClick={handleAlertConfirm} 
              className="mt-2 custom-btn transition duration-200">
              OK
            </button>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default LoginVerifyOtp;
