import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CiSearch } from "react-icons/ci";
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient";
import { Helmet } from "react-helmet";
import Loader from "../../../Common/Loader";
import Fuse from "fuse.js";

const CompanyList = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [jobImageUrls, setJobImageUrls] = useState({});
  const [companies, setCompanies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [loading, setLoading] = useState(false)
  const [userCompanyId, setUserCompanyId] = useState(""); // State for storing user's company ID

  const fetchCompanies = async () => {
    setLoading(true)
    try {
      const res = await apiClient.post("/listAllCompanies", {
        command: "listAllCompanies",
      });
      console.log(res.data.response);
      const companyData = res.data.response || [];
      const sortedCompanies = companyData.sort((a, b) => {
        return new Date(b.company_created_on) - new Date(a.company_created_on); 
      });
      setCompanies(sortedCompanies);
      setFilteredCompanies(sortedCompanies); 

      const urlsObject = {};

      await Promise.all(
        companyData.map(async (company) => {
          if (company.fileKey) {
            try {
              setLoading(true)
              const res = await apiClient.post(
                "/getObjectSignedUrl",
                {
                  command: "getObjectSignedUrl",
                  key: company.fileKey,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
                  },
                }
              );
              const img_url = await res.data;
              if (img_url) {
                urlsObject[company.company_id] = img_url;
              }
            } catch (error) {
              console.error("Error fetching image URL:", error);
            } finally {
              setLoading(false)
            }
          }
        })
      );

      setJobImageUrls(urlsObject); // Update state after fetching all URLs
    } catch (error) {
      console.error("Error fetching companies:", error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (user) {
      setUserCompanyId(user.user_id);
    }
    fetchCompanies();
  }, [user]);



useEffect(() => {
  if (companies && companies.length > 0 && searchQuery.trim()) {
    const normalizedQuery = searchQuery.toLowerCase();

    const fuse = new Fuse(companies, {
      keys: [
        'company_name',
        'category',
        'company_located_city',
        'company_located_state',
        'products.product_name',
        'services.service_name',
      ],
      threshold: 0.3, // Adjust the threshold for fuzzy matching (0.0 exact match, 1.0 no match)
    });

    const results = fuse.search(normalizedQuery).map(result => result.item);
    
    setFilteredCompanies(results);
  }
}, [companies, searchQuery]);

const handleSearch = () => {
  if (companies && companies.length > 0 && searchQuery.trim()) {
    const normalizedQuery = searchQuery.toLowerCase();

    const fuse = new Fuse(companies, {
      keys: [
        'company_name',
        'category',
        'company_located_city',
        'company_located_state',
        'products.product_name',
        'services.service_name',
      ],
      threshold: 0.3,
    });

    const results = fuse.search(normalizedQuery).map(result => result.item);
    
    setFilteredCompanies(results);
  }
};


  const navigateToDetails = (company) => {
    const company_id = company.company_id
    navigate(`/user-dashboard/company/details/${company_id}`)
  }

  const handleEditProfile = (companyId) => {
    if (companyId === userCompanyId) {
      navigate(`/user-dashboard/company/edit/${companyId}`);
    } else {
      toast.error("You can only edit your own company profile.");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  if(loading) {
    return(
      <>
        <Loader/>
      </>
    )
  }
  
  return (
    <div className="w-[100%] mx-auto py-1 px-4 mt-6">
    <Helmet>
        <title>BME Bharath | Companies</title>
        <meta property="og:title" content="BME Bharath | Companies"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/company/list" />
      </Helmet>
      <ToastContainer/>
     
      <div className="flex justify-center mb-6">
      <div className="relative w-full max-w-sm">
      {!searchQuery && (
        <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
      )}
      <input
        type="text"
        className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="      Search"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
      />
    </div>
      </div>

      {filteredCompanies.length > 0 ? (
        <>
          <p className=" mb-4 text-gray-600">
            {filteredCompanies.length} companies found
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredCompanies.map((company) => (
              <div
                key={company.company_id}
                className="p-4 bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <img
                  src={jobImageUrls[company.company_id] || defaultImage}
                  alt={company.company_name}
                  onContextMenu={(e) => e.preventDefault()}
                  className="w-full h-48 object-contain mb-4"
                />

                <div
                  className="flex flex-col"
                  onClick={() => navigateToDetails(company)}
                >
                     <div className="flex flex-col justify-start mb-2">
                      <span className="font-bold text-lg">{company.company_name || "N/A"}</span>
                      <span className="text-sm text-gray-500 -mt-1">{company?.category}</span>
                    </div>
                  <div className="space-y-2">
                  <div className="flex justify-start ">
    <span className="font-bold w-20">Website</span>
    <span>: {company.Website.slice(0, 25) || "N/A"} {company.Website.length > 25 && ".."} </span>
  </div>
  
  <div className="flex justify-start ">
    <span className="font-bold w-20">City</span>
    <span>: {company.company_located_city || "N/A"}</span>
  </div>
  
  <div className="flex justify-start mb-2">
    <span className="font-bold w-20">State</span>
    <span>: {company.company_located_state?.value || company.company_located_state || "N/A"}</span>
  </div>
</div>

                  <p className="text-gray-600 mb-2">
                    {company?.company_description?.split(" ").slice(0, 15).join(" ") || "No Description available"} ..
                    
                  </p>

                  <div className="">
                    <button
                      className="custom-btn mt-4 mr-2"
                      onClick={() => navigateToDetails(company)}
                    >
                      View more
                    </button>
                    <button
                      className=" custom-btn mt-4 mr-2"
                      onClick={() =>
                        window.open(
                          "https://wa.me/+918310491223",
                          "_blank",
                          "noopener noreferrer"
                        )
                      }
                    >
                      Contact
                    </button>

                    {company.company_id === userCompanyId && (
                      <button
                        className="custom-btn mt-2"
                        onClick={() => handleEditProfile(company.company_id)}
                      >
                        Edit Profile
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="text-gray-500">No companies found</p>
      )}
    </div>
  );
};

export default CompanyList;
