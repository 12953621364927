import React from "react";
import { ColorRing, ThreeDots } from "react-loader-spinner";

function LoaderButton() {
  return (
    <div className="flex items-center justify-center">
      <ColorRing
        visible={true}
        height="30"
        width="30"
        ariaLabel="color-ring-loading"
        wrapperStyle={{}}
        wrapperClass="color-ring-wrapper"
        colors={["#075cab"]}
      />
    </div>
  );
}

export default LoaderButton;
