import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CountryCodes } from '../Data';
import { toast, ToastContainer } from 'react-toastify';
import LoaderButton from '../Common/LoaderButton';

const apiClient = axios.create({
  baseURL: 'https://badq1x30ch.execute-api.ap-south-1.amazonaws.com/dev',
  headers: {
    'x-api-key': 'YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg',
    'Content-Type': 'application/json',
  },
});

const EnterPhone = () => {
  const location = useLocation();
  const { selectedCategory, selectedProfile, userType } = location.state || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneVerify, setPhoneVerify] = useState(false);
  const [countryCode, setCountryCode] = useState('+91');
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [countryVerify, setCountryVerify] = useState(true);

  const companyProfiles = [
    "Biomedical Engineering Company Manufacturer",
    "Dealer/Distributor",
    "Biomedical Engineering Company - Service Provider",
    "Healthcare Provider - Biomedical",
    "Academic Institution - Biomedical",
    "Regulatory Body",
    "Investor/Venture Capitalist",
    "Patient Advocate",
    "Healthcare IT Developer"
  ];

  const handlePhone = (phoneVar) => {
    setPhone(phoneVar);
    setPhoneVerify(/^[1-9]\d{9}$/.test(phoneVar));
  };

  const sendOTPHandle = () => {
    setLoading(true);
    const fullPhoneNumber = `${countryCode}${phone}`;

    if (!phoneVerify || !countryVerify) {
      toast.error("Please enter a valid phone number and select a country");
      setLoading(false);
      return;
    }

   apiClient.post('/loginUser', {
      command: "loginUser",
      user_phone_number: fullPhoneNumber,
    })
      .then((res) => {
        if (res.data.status === 'success') {
        toast.error("This user already exists. Use a new number."); 
            navigate("/")
          
        } else {
          apiClient.post('/singInOTP', {
            command: 'sendOtpMsg91',
            user_phone_number: fullPhoneNumber,
          })
            .then((res) => {
              if (res.status === 200) {
                navigate("/verify-otp", { state: { fullPhoneNumber, selectedProfile, selectedCategory, userType } });
              } else {
                toast.error("Failed to send OTP. Please try again.");
              }
            })
            .catch(() => {
              toast.error("Failed to send OTP. Please try again.");
            });
        }
      })
      .catch(() => {
        toast.error("Network error. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderInstructionText = () => {
    if (selectedProfile && selectedCategory) {
      return companyProfiles.includes(selectedProfile) ? 'Enter your business phone number' : 'Enter your phone number';
    }
    return 'Enter phone number';
  };

  return (
    <div className="bg-white min-h-screen flex items-center justify-center p-6">
      <ToastContainer />
      <div className="bg-white shadow-md border border-gray-400 rounded-lg w-full max-w-md p-3 md:p-12">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-black mb-4">
            Welcome to <span className="text-2xl text-bme_blue">BME Bharat</span>..!
          </h1>
          <p className="text-lg font-semibold text-black">
            {renderInstructionText()}
          </p>
        </div>
        
        <div className="space-y-4">
          <div className="flex items-center justify-between mb-4">
            <select
              id="countryCode"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="block text-center w-[45%] mr-3 py-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              {CountryCodes.map((code, index) => (
                <option key={`${code.value}-${index}`} value={code.value}>
                  {code.label}({code.value})
                </option>
              ))}
            </select>
  
            <input
              type="tel"
              placeholder="Enter number"
              maxLength={10}
              className="border py-3 px-4 rounded-lg w-full"
              onChange={(e) => handlePhone(e.target.value)}
            />
          </div>
          
          {!countryVerify && selectedCountry !== 'Country' && (
            <p className="text-red-500 text-sm">
              Please select a valid country
            </p>
          )}
          {phone.length > 0 && !phoneVerify && (
            <p className="text-red-500 text-sm">
              Phone number should start with 1-9 and be exactly 10 digits long
            </p>
          )}
  
          <div className="w-full flex justify-center items-center">
          <button type="submit" className=" custom-btn" onClick={sendOTPHandle} disabled={loading}>
          {loading ? (
            <div className='flex justify-center items-center'>
              <LoaderButton />
              Sending OTP
            </div>
          ) : (
            <>Send OTP</>
          )}
        </button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default EnterPhone;