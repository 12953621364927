import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../../../ApiClient'; // Import your apiClient
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';
import defaultImage from "../../../../assets/images/default_user.jpg"

const CompanyGetAppliedJobList = () => {
  const location = useLocation();
  const { user_id } = location.state || {};
  const userId = user_id;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageUrls, setImageUrls] = useState({});
  const [pdfUrls, setPdfUrls] = useState({});
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await apiClient.post(
          '/getJobProfiles',
          {
            command: 'getJobProfiles',
            user_id: userId,
          }
        );
        console.log(response)
        if (response.data.status === 'success') {
          let posts = response.data.response;
          const imageUrlsObject = {};
          const pdfUrlsObject = {};

          await Promise.all(
            posts.map(async (post) => {
              if (post.fileKey) {
                try {
                  const res = await apiClient.post(
                    '/getObjectSignedUrl',
                    { command: 'getObjectSignedUrl', key: post.fileKey }
                  );
                  imageUrlsObject[post.seeker_id] = res.data;
                } catch (error) {
                  console.error('Error getting image URL:', error);
                }
              }

              if (post.resume_key) {
                try {
                  const res = await apiClient.post(
                    '/getObjectSignedUrl',
                    { command: 'getObjectSignedUrl', key: post.resume_key }
                  );
                  pdfUrlsObject[post.seeker_id] = res.data;
                } catch (error) {
                  console.error('Error getting PDF URL:', error);
                }
              }
            })
          );
          setImageUrls(imageUrlsObject);
          setPdfUrls(pdfUrlsObject);
          setData(posts);
        } else {
          console.log('API Error:', response.data.status_message);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [userId]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error.message}</div>;
  }


  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <Helmet>
        <title>BME Bharat | All applied list</title>
        <meta property="og:title" content="BME Bharath |  All applied list"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile/my-post-jobs-apply" />
      </Helmet>
      <h1 className="custom-h1 mb-6 text-center">Applied Jobs</h1>
      <div className="grid max-w-3xl w-full grid-cols-1 gap-6 mx-auto">
        {data.map((item) => (
          <div key={item.user_id} className="border p-4 rounded-lg shadow-md bg-white">
            <div className="flex flex-col items-center mb-4">
              <img
                src={imageUrls[item.fileKey] || defaultImage}
                onContextMenu={(e) => e.preventDefault()}
                alt="Profile"
                className="w-24 h-24 rounded-full mb-4"
              />
              <h2 className="text-xl font-bold">{item.first_name} {item.last_name}</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-2 justify-center">
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Email</span>
                <span>: {item.user_email_id || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Phone Number</span>
                <span>: {item.user_phone_number || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">City</span>
                <span>: {item.city || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">State</span>
                <span>: {item.state || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">College</span>
                <span>: {item.college || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Work Experience</span>
                <span>: {item.work_experience || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Preferred Salary</span>
                <span>: {item.preferred_salary || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Expert In</span>
                <span>: {item.expert_in || "N/A"}</span>
              </div>
              <div className="flex justify-start mb-2">
                <span className="font-bold w-36">Notice Period</span>
                <span>: {item.notice_period || "N/A"}</span>
              </div>

              {/* Full-width button */}
              <div className="col-span-1 sm:col-span-2 flex justify-center">
                {pdfUrls[item.seeker_id] && (
                  <><button
                    onClick={() => {
                      window.open(pdfUrls[item.seeker_id], '_blank', 'noopener,noreferrer');
                    }}
                    className="custom-btn no-underline w-fit"
                  >
                    View resume
                  </button><button
                    onClick={() => {
                      navigate(-1)
                    }}
                    className="custom-btn no-underline w-fit ml-2"
                  >
                      Back
                    </button></>
                )}
              </div>
            </div>


          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyGetAppliedJobList;
