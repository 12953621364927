import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import defaultImage from "../../../../assets/images/default_user.jpg";
import { AuthContext } from "../../../AuthContext";
import { IoIosLogOut } from "react-icons/io";
import Loader from "../../../Common/Loader";
import apiClient from "../../../ApiClient";
import { toast, ToastContainer } from "react-toastify";
import { MdOutlineDelete } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { Helmet } from "react-helmet";

const UserProfileScreen = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const { user, setUser } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (!user) {
      alert("Please log in to view your profile.");
      return;
    }

    fetchProfile(user.user_id);
  }, [user]);

  const fetchProfile = async (userId) => {
    try {
      const response = await apiClient.post("/getUserDetails", {
        command: "getUserDetails",
        user_id: userId,
      });
      console.log(response)
      if (response.data.status === "success") {
        const profileData = response.data.status_message;
        setProfile(profileData);

        if (profileData.fileKey) {
          const imgResponse = await apiClient.post("/getObjectSignedUrl", {
            command: "getObjectSignedUrl",
            key: profileData.fileKey,
          });
          const imgUrlData = await imgResponse.data;
          setImageUrl(imgUrlData);
        }
      } else {
        console.error("API Error:", response.data.status_message);
      }
    } catch (error) {
      console.error("Error fetching profile", error);
    }
  };

  const handleUpdate = () => {
    navigate("/admin-dashboard/profile/update", {
      state: { profile, imageUrl },
    });
    window.scrollTo(0, 0)
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      localStorage.removeItem("user");
      setUser(null);
      navigate("/login");
    }
  };

  if (!profile) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );
  }


  // preview zoom in out starts here ✅

  const openModal = () => {
    setModalImage(imageUrl || defaultImage);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // preview zoom in out ends here ✅

  return (
    <div className="p-6 flex items-center justify-center">
      <Helmet>
        <title>{profile.first_name} {profile.last_name} - Profile</title>
        <meta property="og:title" content={`${profile.first_name} ${profile.last_name} - Profile`}></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/profile" />
      </Helmet>
      <ToastContainer />
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">
        <div className="flex flex-col justify-center mx-auto items-center mb-6">
          <img
            src={imageUrl || defaultImage}
            alt="Profile"
            onContextMenu={(e) => e.preventDefault()}
            className="w-32 h-32 object-contain rounded-full mb-4"
            onClick={() => {
              openModal()
            }}
          />
          <h2 className="text-2xl font-semibold text-center">
            {profile.first_name} {profile.last_name}
          </h2>
          <span className="text-sm">{profile.user_type || "Not Specified"}</span>

        </div>
        <div className="flex flex-col justify-center items-center mx-auto gap-2 mb-6">
            <div className="flex justify-start mb-2">
              <span className="font-bold md:w-20 md:ml-10">Email</span>
              <span>: {profile.user_email_id || "Not Specified"}</span>
            </div>
            <div className="flex justify-start mb-2">
              <span className="font-bold md:w-20">Phone</span>
              <span>: {profile.user_phone_number || "Not Specified"}</span>
            </div>
          <div>
          </div>
        </div>


        <button
          onClick={handleUpdate}
          className="custom-btn mb-4 md:w-1/3 block mx-auto"
        >
          Edit Profile
        </button>

        <button
          onClick={handleLogout}
          className="flex items-center custom-btn mx-auto text-red-600 md:w-1/3 justify-center"
        >
          <IoIosLogOut className="mr-2" /> Logout
        </button>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileScreen;
