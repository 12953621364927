import React, { useContext, useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { CiSearch } from 'react-icons/ci';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import apiClient from '../../../ApiClient'; // Import the apiClient
import { toast, ToastContainer } from 'react-toastify';
import CustomToastContainer from "../../../CustomToastContainer"
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';

const CompanyJobListScreen = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [jobImageUrls, setJobImageUrls] = useState({});
  const [filteredJobs, setFilteredJobs] = useState([]);
  const { user } = useContext(AuthContext);
  const [companyId, setCompanyId] = useState(''); const [loading, setLoading] = useState(false)

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  useEffect(() => {
    if (user) {
      setCompanyId(user.company_id);
    }
  }, [user]);

  const fetchJobs = async () => {
    setLoading(true)

    try {
      const res = await apiClient.post('/getAllJobPosts', {
        command: "getAllJobPosts",
      });
      const jobsData = res.data.response || [];
      console.log(jobsData)
      setJobs(jobsData);
      setFilteredJobs(jobsData); // Initialize filteredJobs

      const urlsObject = {};

      await Promise.all(jobsData.map(async (job) => {
        if (job.fileKey) {
          try {
            const res = await apiClient.post('/getObjectSignedUrl', {
              command: 'getObjectSignedUrl',
              key: job.fileKey,
            });
            const img_url = res.data; // Assuming the response directly contains the URL
            if (img_url) {
              urlsObject[job.post_id] = img_url;
            }
          } catch (error) {
            console.error('Error fetching image URL:', error);
          }
        }
      }));

      setJobImageUrls(urlsObject);
    } catch (error) {
      console.log('Error fetching jobs:', error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    const results = jobs.filter((job) => {
      const jobTitle = job.job_title?.toLowerCase() || "";
      const industryType = job.industry_type?.toLowerCase() || "";
      return (
        jobTitle.includes(searchQuery.toLowerCase()) ||
        industryType.includes(searchQuery.toLowerCase())
      );
    });
    setFilteredJobs(results);
  }, [searchQuery, jobs]);

  const handleSearch = () => {
    const results = jobs.filter(
      (job) =>
        job.job_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        job.preferred_languages.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredJobs(results);
  };

  const handleRefresh = () => {
    setSearchQuery('');
    setFilteredJobs(jobs);
  };

  const navigateToDetails = (post_id) => {
    navigate(`/company-dashboard/jobs/user-job-detail/${post_id}`);
    window.scrollTo(0,0)
  };

  const handleEditbtn = (job) => {
    navigate('/company-dashboard/profile/my-post-jobs-edit', { state: { post: job } });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleDelete = (post) => {
    setPostToDelete(post.post_id);
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    setShowDeleteConfirmation(false);
    setLoading(true)

    try {
      const response = await apiClient.post(
        "/deleteJobPost",
        {
          command: "deleteJobPost",
          company_id: user.company_id,
          post_id: postToDelete,
        }
      );
      // console.log(response)
      // console.log( postToDelete.company_id,postToDelete)
      if (response.data.status === "success") {
        setJobs((prevPosts) => prevPosts.filter((p) => p.post_id !== postToDelete));
        toast.success("Job Post Deleted Successfully");
        handleRefresh()
      } else {
        toast.error(response.data.errorMessage);
      }
    } catch (error) {
      toast.error("Error deleting job post");
    } finally {
      setLoading(false)
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPostToDelete(null);
  };

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-[100%] mx-auto py-2 px-4 mt-6">
      <Helmet>
        <title>BME Bharath | Jobs</title>
        <meta property="og:title" content="BME Bharath | Jobs"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/jobs" />
      </Helmet>
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-sm">
          {!searchQuery && (
            <CiSearch style={{ fill: '#D1D5DB' }} className="absolute left-[11%] top-1/2 transform -translate-y-1/2 w-6 h-6 text-gray-300" />
          )}
          <input
            type="text"
            className="w-full pl-10 py-3 text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="      Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </div>
      </div>


      {filteredJobs.length > 0 ? (
        <>
          <p className=" mb-4 text-gray-600">
            {filteredJobs.length} jobs found
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {filteredJobs.map((job) => (

              <div key={job.post_id} onClick={() => navigateToDetails(job.post_id)} className="p-3 bg-white border rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <img
                  src={jobImageUrls[job.post_id] || defaultImage}

                  onContextMenu={(e) => e.preventDefault()}
                  alt={job.job_title}
                  className="w-full h-48 object-contain mb-4"
                />
                <div className="flex flex-col">
                <h2 className="text-[16px] font-semibold text-gray-800 mb-2">
                    {job.job_title}
                  </h2>
                  <div className="flex flex-col justify-start mb-2 cursor-pointer">
                      <span className="font-bold text-lg">{job.company_name || "N/A"}</span>
                      <span className="text-sm text-gray-500 -mt-1">{job?.category}</span>
                    </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Package</span>
                    <span>: {job.Package || "N/A"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Location</span>
                    <span>: {job.company_located_city || "N/A"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Industry Type</span>
                    <span>: {job.industry_type || "N/A"}</span>
                  </div>
                  <div className='flex gap-1 mt-3'>
                    {job.company_id === companyId && (
                      <><button
                        className=" custom-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditbtn(job);
                        }}
                      >
                        Update
                      </button><button type='button' onClick={(e) => {
                        e.stopPropagation()
                        handleDelete(job)
                      }
                      } className="custom-btn">
                          Delete
                        </button></>
                    )}
                    <button
                      className=" custom-btn"
                      onClick={() => navigateToDetails(job.post_id)}
                    >
                      View More
                    </button>

                    {/* Show Update button only if company ID matches */}

                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="text-gray-500">No jobs found</p>
      )}

      <Link
        to="/company-dashboard/jobs/user-job-post"
        className="fixed z-50 bottom-6 right-11 bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
      >
        <FaPlus size={36} />
      </Link>
      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg">
            <p>Are you sure you want to delete this job post?</p>
            <div className="flex space-x-4 mt-4">
              <button onClick={confirmDelete} className="custom-btn">
                Yes
              </button>
              <button onClick={cancelDelete} className="custom-btn">
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <CustomToastContainer />
    </div>

  );
};

export default CompanyJobListScreen;
