import React from "react";
import { Helmet } from "react-helmet";
import {
  FaHandsHelping,
  FaLock,
  FaComments,
  FaFingerprint,
} from "react-icons/fa";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white rounded-lg p-3 shadow-md mt-5 mx-auto w-[90%] md:w-3/4 lg:w-[80%]">
      <Helmet>
        <title>BME Bharat Guidelines | Privacy policy</title>
        <meta property="og:title" content=">BME Bharat Guidelines | Privacy policy"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/guidelines/privacypolicy" />
      </Helmet>
      <h1 className="text-center custom-h1 mb-2">Privacy Policy</h1>
      <hr className="my-2" />
      <p className="text-gray-600 text-[12px] leading-6 text-justify">
        Effective Date: 01/July/2024
        <br />
        This Privacy Policy outlines how B M E Bharat ("we," "us," or "our")
        collects, uses, discloses, and protects the personal information of
        users of our biomedical engineering app B M E Bharat. We are committed
        to safeguarding your privacy and ensuring the security of your
        personal data. By using the App, you agree to the terms and practices
        described in this Privacy Policy.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Information We Collect:
        </strong>
        <br />
        User-Provided Information: We may collect information that you
        voluntarily provide when using our App, including but not limited to:
        <br />
        Your name
        <br />
        Email address
        <br />
        Contact information
        <br />
        Profile information
        <br />
        Content you submit, post, or share on the App
        <br />
        Automatically Collected Information: We may automatically collect
        information about your usage of the App, such as: Device information
        (e.g., device type, operating system) Log data (e.g., IP address,
        browser type, date and time of access) Location data (if you enable
        location services)
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          How We Use Your Information:
        </strong>
        <br />
        We use the collected information for the following purposes: To
        provide and maintain the App. To personalize and improve your
        experience with the App. To communicate with you, including sending
        notifications, updates, and information about our services. To respond
        to your requests, comments, or questions. To analyze user trends and
        preferences to enhance the App's features and content. To fulfill legal
        and regulatory obligations.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Sharing of Your Information:
        </strong>
        <br />
        We do not sell, trade, or rent your personal information to third
        parties. However, we may share your information with: Service
        providers and third-party vendors who assist us in operating and
        maintaining the App. Legal authorities or other entities when required
        to comply with the law or protect our rights and interests.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Your Choices and Controls:
        </strong>
        <br />
        You have certain rights and choices regarding your personal
        information: You can review and update your account information at any
        time. You may opt out of receiving marketing communications from us.
        You can disable location services through your device settings. You
        can request the deletion of your account and associated data.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Security:
        </strong>
        <br />
        We take reasonable measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction. However,
        no method of transmission over the internet or electronic storage is
        entirely secure. We cannot guarantee the absolute security of your
        data.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Children's Privacy:
        </strong>
        <br />
        Our App is not intended for children under the age of 13. We do not
        knowingly collect personal information from individuals under the age
        of 13. If you believe we have inadvertently collected such
        information, please contact us to have it removed.
        <br />
        <br />
        
        <strong className="text-gray-800 text-[12px] font-bold">
          Changes to this Privacy Policy:
        </strong>
        <br />
        We may update this Privacy Policy to reflect changes in our practices
        or for other operational, legal, or regulatory reasons. We will
        provide notice of any material changes and obtain your consent if
        required by applicable laws.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Contact Us:
        </strong>
        <br />
        If you have any questions, concerns, or requests related to this
        Privacy Policy If you have questions or concerns about our legal
        compliance specific to Bharat or require assistance related to legal
        matters
        <br />
        <br />
         please contact us at Email{" "}
        <span className="text-blue-600">admin@bmebharat.com</span> or{" "}
        <span className="text-blue-600">bmebharat@gmail.com</span> or Phone
        Number <span className="text-blue-600">+91 8310491223.</span>
        <br />
        By using our App, you consent to the practices described in this
        Privacy Policy. Please review this policy regularly to stay informed
        about how we handle your personal information.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
