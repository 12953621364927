import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import defaultImage from "../../../../assets/images/defaultImage.jpg";
import { useNavigate } from "react-router-dom";
import apiClient from "../../../ApiClient";
import { toast, ToastContainer } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import { Helmet } from "react-helmet";
import { all } from "axios";
import Loader from "../../../Common/Loader";

const CompanyDetails = () => {
  const location = useLocation();
  const { company_id } = useParams()
  const [imageUrls, setImageUrls] = useState({});
  const navigate = useNavigate();
  const [company, setCompanyDetails] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      setLoading(true)
      try {
        const response = await apiClient.post("/getCompanyDetails", {
          command: "getCompanyDetails",
          company_id: company_id,
        });
        // console.log("response", response.data.status_message);
        if (response.data.status === "success") {
          setCompanyDetails(response.data.status_message);
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      } finally {
        setLoading (false)
      }
    };

    fetchCompanyDetails();
  }, [company_id]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urlsObject = {}
      const allFileKeys = [
        company.fileKey,
        ...(company?.services?.map((service) => service.service_image) || []),
        ...(company?.products?.map((product) => product.product_image) || []),
      ];

      await Promise.all(
        allFileKeys.map(async (fileKey) => {
          if (fileKey) {
            setLoading(true)
            try {
              const res = await apiClient.post("/getObjectSignedUrl", {
                command: "getObjectSignedUrl",
                key: fileKey,
              });
              const imgUrl = res.data; // Assuming the response contains the URL directly
              if (imgUrl) {
                urlsObject[fileKey] = imgUrl;
              }
            } catch (error) {
              console.error("Error fetching image URL:", error);
            } finally {
              setLoading (false)
            }
          }
        })
      );
      setImageUrls(urlsObject);
    };


    if (company) {
      fetchImageUrls();
    }
  }, [company]);

  if(loading) {
    return <Loader/>
  }

  if (!company) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 gap-3">
        <ToastContainer/>
        <p className='m-0 text-[16px]'>No company profile available.</p>

        <button onClick={()=>{
          navigate('/admin-dashboard/company/list')
        }} className='custom-btn'>All companies</button>
      </div>
    );
  }

 
  
  const handleBrochure = async (brochureKey) => {
    try {
      const response = await apiClient.post("/getObjectSignedUrl", {
        command: "getObjectSignedUrl",
        key: brochureKey,
      });
      // console.log(response)
      if (response.data) {
        window.open(response.data, '_blank', 'noopener,noreferrer');
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching brochure URL:", error);
    }
  };



  // modal for image preview with zoom in and out starts here ✅
  const openModal = (imageUrl) => {
    setModalImage(imageUrl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false);
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleApprove = (company) => {
    // Show confirmation dialog
    const confirmApprove = window.confirm(`Are you sure you want to approve ${company.company_name}?`);

    if (confirmApprove) {
      setLoading(true);
      apiClient.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/approveOrRejectCompany', {
        command: "approveOrRejectCompany",
        bme_Admin_email_id: "bmebharat@gmail.com",
        company_id: company.company_id,
        admin_approval: "Approved",
      })
        .then((res) => {
          if (res.data.status === "success") {
            toast.success("This company Approved", `Approved: ${company.company_name}`);
          } else {
            toast.error(res.data.errorMessage);
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  };

  const handleReject = (company) => {
    // Show confirmation dialog
    const confirmReject = window.confirm(`Are you sure you want to reject ${company.company_name}?`);

    if (confirmReject) {
      setLoading(true);
      apiClient.post('https://5kh43xmxxl.execute-api.ap-south-1.amazonaws.com/dev/approveOrRejectCompany', {
        command: "approveOrRejectCompany",
        bme_Admin_email_id: "bmebharat@gmail.com",
        company_id: company.company_id,
        admin_approval: "Rejected",
      })
        .then((res) => {
          if (res.data.status === "success") {
            toast.success(`The company Rejected: ${company.company_name}`);
            return deleteAccount(company);
          } else {
            toast.error(res.data.errorType, res.data.errorMessage);
          }
        }).finally(() => {
          setLoading(false);
        });
    }
  };


  const deleteAccount = (company) => {
    setLoading(true)
    apiClient.post('/deleteAccount', {
      command: "deleteAccount",
      user_phone_number: company.company_contact_number,
    })
      .then((deleteResponse) => {
        if (deleteResponse.data.status === "success") {
          toast.success("The company account has been deleted successfully.");
          setTimeout(() => {
            navigate(-1)
          }, 2000);
        } else {
          toast.success(deleteResponse.data.errorType, deleteResponse.data.errorMessage);
        }
      })
      .catch((error) => {
        toast.success("Error", "Failed to delete the account.");
      }).finally(() => {
        setLoading(false)
      })
  };

  return (
    <div className="min-h-screen p-6 flex items-center justify-center">
      <Helmet>
        <title>BME Bharath | {company.company_name}</title>
        <meta property="og:title" content={`BME Bharath | ${company.company_name}`}></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content={`https://bmebharat.com/admin-dashboard/company/details/${company.company_id}`} />
      </Helmet>
      <ToastContainer />
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-md p-8">
        <img
          src={imageUrls[company.fileKey] || defaultImage}
          onClick={() => {
            openModal(imageUrls[company.fileKey] || defaultImage)
          }}
          onContextMenu={(e) => e.preventDefault()}
          alt={company.company_name}
          className="w-full h-64 object-contain rounded-lg mb-6"

        />

        <div className="flex flex-col justify-start mb-2">
          <span className="font-bold text-lg">{company.company_name || "N/A"}</span>
          <span className="text-sm text-gray-500 -mt-1">{company?.category}</span>
        </div>

        <div className="space-y-4 mb-6">
          <div className="space-y-4">
            <div className="flex justify-start mb-2">
              <span className="font-bold w-24">Website</span>
              <span>: {company.Website || "Not Specified"}</span>
            </div>

            <div className="flex justify-start mb-2">
              <span className="font-bold w-24">City</span>
              <span>: {company.company_located_city || "Not Specified"}</span>
            </div>

            <div className="flex justify-start mb-2">
              <span className="font-bold w-24">State</span>
              <span>: {company.company_located_state || "Not Specified"}</span>
            </div>
          </div>


          <p className="text-justify">
            {company.company_description || "Not Specified"}
          </p>
          {/* <p>
            <strong>Email:</strong>{" "}
            {(companyDetails && companyDetails.company_email_id) || "Not Specified"}
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            {(companyDetails && companyDetails.company_contact_number) || "Not Specified"}
          </p> */}
          <div className="flex justify-start mb-2">
            <span className="font-bold w-24">Address</span>
            <span>: {company.company_address || "Not Specified"}</span>
          </div>
          {company && company.brochureKey && (
            <button
              className="custom-btn"
              onClick={() => {
                handleBrochure(company.brochureKey);
              }}
            >
              View Brochure
            </button>
          )}

        </div>

        <hr className="my-4" />

        <h2 className="font-bold mb-4 text-[16px]">Select Product</h2>
        <select
          onChange={(e) => setSelectedProduct(e.target.value)}
          className="w-full border rounded-lg p-2 mb-4"
        >
          <option value="">Select a Product</option>
          {company.products && company.products.length > 0 ? (
            company.products.map((product, index) => (
              <option key={index} value={product.product_name}>
                {product.product_name}
              </option>
            ))
          ) : (
            <option disabled>No products available</option>
          )}
        </select>

        {selectedProduct && (
          <div className="border rounded-lg p-4 shadow-sm mb-4">
            {/* {console.log(imageUrls)}
    {console.log(selectedProduct)} */}

            {(() => {
              const selectedProductDetails = company.products.find((p) => p.product_name === selectedProduct);

              return (
                <>
                  <img
                    src={
                      imageUrls[selectedProductDetails.product_image] || defaultImage
                    }
                    onClick={() => {
                      openModal(imageUrls[selectedProductDetails.product_image] || defaultImage)
                    }}

                    onContextMenu={(e) => e.preventDefault()}

                    alt={selectedProduct}
                    className="w-full h-48 object-contain rounded mb-2"
                  />
                  {selectedProductDetails ? (
                    <>
                      <div className="flex justify-start mb-2">
                        <span className="font-bold w-28">Product Name</span>
                        <span>: {selectedProductDetails.product_name || "Not Specified"}</span>
                      </div>
                      <div className="flex justify-start mb-2">
                        <span className="font-bold w-28">Price</span>
                        <span>: ₹{selectedProductDetails.product_price || "Not Specified"}</span>
                      </div>
                      <div className="flex justify-start mb-2">
                        <span className="text-justify">{selectedProductDetails.product_description || "Not Specified"}</span>
                      </div>
                      <div className="flex justify-start mb-2">
                        <span className="font-bold w-28">Specifications</span>
                        <span>: {selectedProductDetails.product_specifications || "Not Specified"}</span>
                      </div>

                      {selectedProductDetails.product_brochure_key && (
                        <button
                          className="custom-btn"
                          onClick={() => handleBrochure(selectedProductDetails.product_brochure_key)}
                        >
                          View Brochure
                        </button>
                      )}
                    </>
                  ) : (
                    <p><strong>Product Name:</strong> Not Specified</p>
                  )}
                </>
              );
            })()}
          </div>
        )}


        <hr className="my-4" />

        <h2 className="font-bold mb-4 text-[16px]">Select Service</h2>
        <select
          onChange={(e) => setSelectedService(e.target.value)}
          className="w-full border rounded-lg p-2 mb-4"
        >
          <option value="">Select a Service</option>
          {company.services && company.services.length > 0 ? (
            company.services.map((service, index) => (
              <option key={index} value={service.service_name}>
                {service.service_name}
              </option>
            ))
          ) : (
            <option disabled>No services available</option>
          )}
        </select>

        {selectedService ? (
          <div className="border rounded-lg p-4 shadow-sm mb-4">


            {(() => {
              const selectedServiceDetails = company.services.find((s) => s.service_name === selectedService);

              return selectedServiceDetails ? (
                <>
                  <img

                    src={imageUrls[selectedServiceDetails.service_image] || defaultImage}
                    alt={selectedService}

                    onContextMenu={(e) => e.preventDefault()}
                    onClick={() => {
                      openModal(imageUrls[selectedServiceDetails.service_image] || defaultImage)
                    }}
                    className="w-full h-48 object-contain rounded mb-2"
                  />
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Service Name</span>
                    <span>: {selectedServiceDetails.service_name || "Not Specified"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Price</span>
                    <span>: ₹{selectedServiceDetails.service_price || "Not Specified"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="text-justify"> {selectedServiceDetails.service_description || "Not Specified"}</span>
                  </div>
                  <div className="flex justify-start mb-2">
                    <span className="font-bold w-28">Specifications</span>
                    <span>: {selectedServiceDetails.service_specifications || "Not Specified"}</span>
                  </div>

                  {selectedServiceDetails.service_brochure_key && (
                    <button
                      className="custom-btn"
                      onClick={() => handleBrochure(selectedServiceDetails.service_brochure_key)}
                    >
                      View Brochure
                    </button>
                  )}
                </>
              ) : (
                <p><strong>Service Name:</strong> Not Specified</p>
              );
            })()}
          </div>
        ) : (
          <></>
        )}


        <div className="w-full flex items-center justify-center mx-auto mt-3">

<button
  className="custom-btn bg-red-500 text-white hover:bg-red-600 mr-2"
  onClick={(e) => {
    e.stopPropagation()
    handleReject(company)
  }}
>
  Reject
</button>
{company.admin_approval === "Approved" ? (
  <></>
) : (
  <button
    className="custom-btn  mr-2"
    onClick={(e) => {
      e.stopPropagation()
      handleApprove(company)
    }
    }
  >
    Approve
  </button>
)}





<button
  className="custom-btn"
  onClick={() => {
    navigate(-1);
  }}
>
  Back
</button>
</div>
      </div>


      {/* modal for image preview with zoom in and out starts here ✅ */}

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
              onDragStart={(e) => e.preventDefault()}

              onContextMenu={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
      {/* modal for image preview with zoom in and out starts here ✅ */}
    </div>
  );
};

export default CompanyDetails;
