import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./components/AuthContext";
import { TrialProvider } from "./components/TrialContext";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUs from "./components/Common/ContactUs.js";
import AdminDashboard from "./components/Dashboard/Admin/AdminDashboard.js";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <TrialProvider>
        <React.StrictMode>
          <ToastContainer />
          <App />
        </React.StrictMode> 
      </TrialProvider>
    </AuthProvider>
  </BrowserRouter>
);

reportWebVitals();
