import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import dummy_pic from '../../assets/images/default_user.jpg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { stateCityData } from '../Data';
import apiClient from '../ApiClient';
import { AuthContext } from '../AuthContext';
import { TrialContext } from '../TrialContext';
import LoaderButton from '../Common/LoaderButton';

const UserSignupScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCategory, selectedProfile, fullPhoneNumber } = location.state;
  const { setTrialVisible } = useContext(TrialContext);
  const { login } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    collage: '',
    selectedState: '',
    selectedCity: '',
    gender: '',
    dateOfBirth: null,
    imageUri: null,
  });

  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState('');
  const [loading, setLoading] = useState(false);

  const states = Object.keys(stateCityData);
  const cities = formData.selectedState ? stateCityData[formData.selectedState] : [];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageSelection = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 200 * 1024) {
        toast.error('File size exceeds 200 KB. Please upload a smaller image.');
        return;
      }

      setFormData({ ...formData, imageUri: URL.createObjectURL(selectedFile) });
      setFile(selectedFile);
      setFileType(selectedFile.type);
    }
  };

  const handleImagePreviewClick = () => {
    document.getElementById('image-upload-input').click();
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, imageUri: null });
    setFile(null);
    setFileType('');
  };

  const handleUploadFile = async () => {
    setLoading(true)
    const fileInput = document.getElementById('fileInput');
    const file = fileInput?.files[0];

    // if (!file) {
    //   toast.error('No image file selected. Please upload a valid image file.');
    //   throw new Error('No Image file selected.');
    // }
    // console.log(file)
    if (file) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
          const base64 = reader.result.split(',')[1];
          const fileType = file.type; // Get the file type dynamically
          try {
            const response = await apiClient.post('/uploadImage', {

              command: 'uploadImage',
              headers: { 'Content-Type': fileType },
              fileBuffer: base64,

            });

            const result = await response.json();
            if (response.ok && result.statusCode === 200) {
              const body = JSON.parse(result.body);
              resolve(body.fileKey);
            } else {
              reject(new Error('File upload failed'));
            }
          } catch (error) {
            reject(error);
          } finally {
            setLoading(false)
          }
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }
  };


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setLoading(true);

    if (!formData.firstName) {
      toast.error("First Name is required.");
      setLoading(false);
      return;
    }

    if (!formData.gender) {
      toast.error("Select your gender");
      setLoading(false);
      return;
    }
    try {
      const imageFileKey = await handleUploadFile();
      console.log(selectedCategory, selectedProfile, fullPhoneNumber)
      const payload = {
        command: 'signUpUsers',
        fileKey: imageFileKey,
        date_of_birth: formData.dateOfBirth ? formData.dateOfBirth.toISOString().split('T')[0] : '',
        user_phone_number: fullPhoneNumber,
        category: selectedCategory,
        select_your_profile: selectedProfile,
        user_email_id: formData.email,
        state: formData.selectedState,
        city: formData.selectedCity,
        first_name: formData.firstName,
        last_name: formData.lastName,
        college: formData.collage,
        gender: formData.gender
      };

      console.log(payload)

      const res = await apiClient.post('/signUpUsers', payload);
      if (res.data.status === "success") {
        toast.success('Successfully signed up!');
        setTimeout(() => {
          setTrialVisible(true);
          login(res.data.user_details);
          navigate('/user-dashboard');
        }, 2000);
        window.scrollTo(0, 0);
      } else {
        toast.error(res.data.errorMessage);
      }
    } catch (error) {
      toast.error('Error submitting the form');
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const disableFutureDates = (date) => {
    return date < new Date(); // Disable today and any future dates
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-8 bg-white rounded-lg shadow-md">

      <h2 className="custom-h1 text-center mb-6">Sign Up</h2>

      <form onSubmit={handleSubmit}>
        <div className="mb-4 text-center">
          <img
            src={formData.imageUri || dummy_pic}
            alt="Profile"
            onContextMenu={(e) => e.preventDefault()}
            className="h-32 w-32 rounded-full object-cover mb-4 mx-auto cursor-pointer"
            onClick={handleImagePreviewClick}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageSelection}
            className="hidden"
            id="image-upload-input"
          />
          <p className="text-gray-500 ">Click to upload image (Max 200KB)</p>

          {formData.imageUri && (
            <button
              type="button"
              onClick={handleRemoveImage}
              className="text-red-600 hover:underline"
            >
              Remove Image X
            </button>
          )}
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">First Name<span className="text-red-500"> *</span>
          </label>

          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Last Name </label>


          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Email <span className="text-red-500"> *</span></label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"


          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Gender <span className="text-red-500"> *</span></label>
          <div className="flex gap-4">
            <label>
              <input
                type="radio"
                name="gender"
                value="Male"
                onChange={handleInputChange}
                checked={formData.gender === 'Male'}
              />{' '}
              Male
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="Female"
                onChange={handleInputChange}
                checked={formData.gender === 'Female'}
              />{' '}
              Female
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Date of Birth</label>
          <DatePicker
            selected={formData.dateOfBirth}
            onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
            placeholderText="Select Date of Birth"
            className="w-full p-2 border border-gray-300 rounded-lg"
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            filterDate={disableFutureDates}

          />
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">State</label>
          <select
            name="selectedState"
            value={formData.selectedState}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">City</label>
          <select
            name="selectedCity"
            value={formData.selectedCity}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block mb-2 font-medium text-gray-700">Organisation / Institution</label>
          <input
            type="text"
            name="collage"
            value={formData.collage}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-lg"

          />
        </div>

        <div className='flex justify-center items-center'>

          <button type="submit" className="mt-6 custom-btn" onClick={handleSubmit} disabled={loading}>
            {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Submitting
              </div>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UserSignupScreen;
