import React from "react";
import student from "../About-Page/images/card_bme1.jpg"
import card5 from "../About-Page/images/professional_bme.jpg";
import card6 from "../About-Page/images/researchers in home page.jpg";
import card7 from "../About-Page/images/buyers_bme.jpg";
import cardExtra from "../About-Page/images/cardExtra.jpg";
import card9 from "../About-Page/images/job_bme.jpg";
import "./Card.css"

export const Card = () => {
  return (
    <>
<h1 className="mt-5 mb-7 text-center text-[#075CAB] font-bold text-lg sm:text-xl md:text-2xl px-2 flex items-center justify-center">
  <span className="inline-block w-1/4 sm:w-1/5 md:w-1/4 border-t border-gray-300 mt-2 mr-5"></span>
  Who can benefit from the App?
  <span className="inline-block w-1/4 sm:w-1/5 md:w-1/4 border-t border-gray-300 mt-2 ml-5"></span>
</h1>

<div className=" w-full flex justify-center">
  <div className="cards w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
    <div className="card">
      <div className="card_img">
        <img
          src={student}
          onContextMenu={(e) => e.preventDefault()}
          alt="pic"
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Students:</strong> Our app is an invaluable resource for
          students pursuing degrees in biomedical engineering or related fields.
          It enhances the learning experience by offering hands-on experiences
          and access to valuable resources. Whether you're a medical student,
          engineering student, or a student in a related discipline, this app can
          supplement your education and help you stay up-to-date with the latest
          developments in the field.
        </p>
      </div>
    </div>
    <div className="card">
      <div className="card_img">
        <img
          src={card5}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Medical Professionals:</strong> For healthcare practitioners,
          this app is a gateway to staying informed about the latest technologies
          and medical advancements. It offers a curated feed of information on
          groundbreaking medical innovations, which can transform the way you
          diagnose and treat patients. As a medical professional, you can benefit
          from the app's continuous updates and expert insights to deliver the
          best care to your patients.
        </p>
      </div>
    </div>
    <div className="card">
      <div className="card_img">
        <img
          src={card6}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Researchers:</strong> Access to a wealth of knowledge and data
          is crucial for researchers in the biomedical engineering and related
          areas. Our app provides a platform for researchers to stay updated with
          the most recent studies, breakthroughs, and datasets. It's an invaluable
          tool to support your research efforts, helping you make advancements in
          your chosen field.
        </p>
      </div>
    </div>
    <div className="card">
      <div className="card_img">
        <img
          src={card9}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Enthusiasts:</strong> If you're someone with a passion for
          learning about groundbreaking medical technologies and innovations, this
          app caters to your curiosity. You don't need a formal background in
          biomedical engineering to benefit from the app. It's designed to be
          user-friendly and informative, making it easy for enthusiasts to explore
          and understand the exciting developments in the field.
        </p>
      </div>
    </div>
    <div className="card">
      <div className="card_img">
        <img
          src={cardExtra}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Job Seekers:</strong> Job seekers looking for opportunities in
          the biomedical engineering and related industries can utilize the app
          to find job listings, stay updated on industry trends, and access
          resources that will help them prepare for interviews. It serves as a
          valuable tool in your job search and career development.
        </p>
      </div>
    </div>
    <div className="card">
      <div className="card_img">
        <img
          src={card7}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
        />
      </div>
      <div className="card_text">
        <p>
          <strong>Buyers:</strong> Individuals and organizations looking to
          purchase medical equipment, devices, or related products can use the
          app to discover and compare options. It provides a marketplace for buyers
          to browse a wide range of products and make informed decisions.
        </p>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default Card;