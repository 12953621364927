import React, { useState,  useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../../ApiClient";
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import LoaderButton from "../../../Common/LoaderButton";
import { Helmet } from "react-helmet";


const ArticlePost = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const userid = user.user_id;
  const [loading,setLoading] = useState(false)

  const [imageFile, setImageFile] = useState(null);

  const [fileKey, setFileKey] = useState(null); // State to store fileKey after upload
  const [postData, setPostData] = useState({
    article_title: "",
    article_body: "",
    article_conclusion: "",
  });

  const handleImageSelection = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 200) {
        toast.error("File size exceeds 200 KB limit");
        return;
      }

      setImageFile(file);
      const base64 = await convertToBase64(file);
      const uploadedFileKey = await handleUploadImage(base64, file.type);
      setFileKey(uploadedFileKey);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Get base64 part only
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUploadImage = async (base64, type) => {
    setLoading(true)
    try {
      const apiEndpoint =
        "https://badq1x30ch.execute-api.ap-south-1.amazonaws.com/dev/uploadImage";

      const uploadResponse = await apiClient.post("/uploadImage", {
        command: "uploadImage",
        headers: {
          "Content-Type": type,
        },
        fileBuffer: base64,
      });

      const uploadResult = await uploadResponse.data;
      // console.log(uploadResponse.data);

      if (uploadResponse) {
        if (uploadResult.statusCode === 200) {
          const body = JSON.parse(uploadResult.body);
          if (body.fileKey) {
            setFileKey(body.fileKey);
            return body.fileKey;
          } else {
            throw new Error("fileKey not found in response");
          }
        } else {
          throw new Error(
            `Upload failed with status code ${uploadResult.statusCode}`
          );
        }
      } else {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }
    } catch (error) {
      console.log(error);
      console.error("Error in handleUploadImage:", error.message);
      toast.error("Image upload failed.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const handlePostSubmission = async (e) => {
    setLoading(true)

    e.preventDefault();

    if (
      !postData.article_title ||
      !postData.article_body 
    ) {
      toast.error("The title and description fields are required.");

      return;
    }

    try {
      const postPayload = {
        command: "postArticles",
        user_id: userid,
        article_title: postData.article_title,
        article_body: postData.article_body,
        article_conclusion: postData.article_conclusion,
        fileKey: fileKey, // Use the uploaded fileKey
      };

      const res = await apiClient.post("/postArticles", postPayload);
      console.log(res);
      if (res.data.status === "success") {
        toast.success("The article post has been successfully uploaded.");
setTimeout(() => {
  navigate("/user-dashboard/articles");
}, 1000);
      } else {
        toast.error(res.data.message || res.data.errorMessage);
      }
    } catch (error) {
      console.error("Error in post submission:", error);
      toast.error("Error submitting the post.");
    } finally {
    setLoading(false)
    }
  };
  const clearImage = () => {
    setImageFile(null);
    setFileKey(null);
    document.getElementById('article_image').value = '';
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="max-w-[1370px] w-full mt-10">
      <Helmet>
        <title>BME Bharath | Articles</title>
        <meta property="og:title" content="BME Bharath | Articles"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/my-article-list" />
      </Helmet>
      <ToastContainer/>
      <h1 className="custom-h1 my-8 text-center z-[1000]">
        Post an article
      </h1>

      <form
        className="max-w-3xl w-[90%] md:w-full mx-auto"
        onSubmit={handlePostSubmission}
      >
        <div className="mb-4 ">
          <label
            htmlFor="article_title"
            className="block mb-2  font-medium text-gray-900"
          >
            Title
          </label>
          <input
            type="text"
            id="article_title"
            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
            value={postData.article_title}
            onChange={(e) =>
              setPostData({ ...postData, article_title: e.target.value })
            }
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_image"
            className="block mb-2  font-medium text-gray-900"
          >
            Add Image
          </label>
          <input
            type="file"
            id="article_image"
            accept="image/*"
            onChange={handleImageSelection}
            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 hidden w-full p-2.5"
          />

          <div className=" flex justify-center items-center mx-auto">



          {defaultImage && !imageFile && (
            <div className="relative mt-4 inline-block">
              <img
                src={defaultImage}
                alt="Old Article"
                className="image-standard mb-2 cursor-pointer"
                onContextMenu={(e) => e.preventDefault()}
                onClick={() => document.getElementById("article_image").click()} // Clicking on the image opens file picker
              />
              <button
                type="button"
                className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
                onClick={() => document.getElementById("article_image").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
            </div>
          )}
          {imageFile && (
            <div className="relative mt-4 inline-block">
            
            <img
              src={URL.createObjectURL(imageFile)}
              alt="Selected"
              className="image-standard mt-4"
                onContextMenu={(e) => e.preventDefault()}
            />
             <button
                type="button"
                className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                onClick={() => document.getElementById("article_image").click()}
              >
                <i className="fa fa-pencil text-black" aria-hidden="true"></i>
              </button>
              <button
              type="button"
              onClick={clearImage}
              className="mt-4 text-red-500 hover:underline"
            >
              Remove image <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
            </button>
            
            </div>
          )}

          </div>
          
         
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_body"
            className="block mb-2  font-medium text-gray-900"
          >
            Description
          </label>
          <textarea
            id="article_body"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-52" // Adjust height here
            required
            value={postData.article_body}
            onChange={(e) =>
              setPostData({ ...postData, article_body: e.target.value })
            }
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_conclusion"
            className="block mb-2  font-medium text-gray-900 "
          >
            Conclusion
          </label>
          <textarea
            id="article_conclusion"
            className="bg-gray-50 h-40 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-30"
            // required
            value={postData.article_conclusion}
            onChange={(e) =>
              setPostData({ ...postData, article_conclusion: e.target.value })
            }
          />
        </div>
        <button className="custom-btn">

        {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Submitting
              </div>
            ) : (
              <>Submit</>
            )}
          </button>
          <button className="custom-btn ml-2" onClick={()=>{
            handleCancel()
          }}>
            Cancel
          </button>
      </form>
    </div>
  );
};

export default ArticlePost;
