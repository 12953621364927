
import React, { createContext, useState } from 'react';

export const TrialContext = createContext();

export const TrialProvider = ({ children }) => {
  const [trialVisible, setTrialVisible] = useState(false);

  return (
    <TrialContext.Provider value={{ trialVisible, setTrialVisible }}>
      {children}
    </TrialContext.Provider>
  );
};
