import React, { useContext, useEffect, useRef, useState } from "react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import logo from "../../assets/images/logo-crop.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import uparrow from "../../assets/images/up-arrow.png"
import { IoIosArrowDown, IoIosLogOut } from "react-icons/io";
import Loader from "./Loader";


const NavbarHome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const { user, setUser, loading } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  // scroll to top btn starts ✅
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    setActiveDropdown("");
    setIsOpen(false)
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown("");
        setIsOpen(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);

    };
  }, []);


  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? "" : dropdown);
  };

  const handleItemClick = () => {
    setIsOpen(false); // Close the dropdown when an item is clicked
    setActiveDropdown(""); // Optionally reset active dropdown
  };


  const isActive = (path) => location.pathname.startsWith(path);



  const handleLogout = () => {
    // Confirmation before logout
    const confirmLogout = window.confirm('Are you sure you want to logout?');

    if (confirmLogout) {
      // Clear user data from localStorage and context
      localStorage.removeItem('user');
      setUser(null);
      navigate('/'); // Redirect to homepage or login page
    }
  };

  if (loading) {
    return <div><Loader /></div>; // Show loading while checking for user
  }


  return (
    <>
      <div className="bg-white shadow-md fixed mt-0  w-[100%] z-50">

        {/* sticky Scroll to top */}
        <button
          className={`fixed z-50 bottom-24 right-10 rounded-full p-3 transition-opacity duration-300 ease-in-out transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
          onClick={scrollToTop}
          aria-label="Scroll to top"
          title="Scroll to Top!"
        >
          <img src={uparrow} alt="scroll to top icon "
            onContextMenu={(e) => e.preventDefault()}
            className="w-12 h-12" />
        </button>

        {/* scroll to top ends ❎ */}

        {/* First Row: Logo item Logout Button ✅ */}

        <div className="flex items-center justify-between px-4 py-2 text-[#075CAB] md:px-8 md:py-4 border-b mx-3 md:mx-10">
          <Link to="/">
            <img src={logo} alt="Logo"

              onContextMenu={(e) => e.preventDefault()}
              className="h-[70px] md:h-[90px]" />
          </Link>

          <div className=" justify-center items-center gap-2 hidden lg:flex">
            <div
              ref={dropdownRef}
              className={`lg:flex `}

            >

              <div className="relative" >
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4   hover:bg-blue-100 rounded ${isActive("/about") ? "bg-blue-200" : ""}`}
                  onClick={() => {
                    navigate('/about')
                    window.scrollTo(0, 0)
                  }}
                >
                  About
                </button>
              </div>



              {/* Guidelines Dropdown */}
              <div className="relative" onMouseEnter={() => setActiveDropdown("guidelines")} onMouseLeave={() => setActiveDropdown("")}>
                <button
                  className={`flex items-center px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-blue-100 rounded ${isActive("/guidelines") ? "bg-blue-200" : ""}`}
                >
                  <Link to="/guidelines" className="flex no-underline font-bold text-[#075cab]">
                    Guidelines <IoIosArrowDown
                      style={{ fill: "#075CAB" }}
                      className="h-5 w-5 ml-2"
                    />
                  </Link>
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/privacypolicy"
                    onClick={handleItemClick}
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/cancellation"
                    onClick={handleItemClick}
                  >
                    Cancellation policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/legalpolicy"
                    onClick={handleItemClick}
                  >
                    Legal policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/termsandconditions"
                    onClick={handleItemClick}
                  >
                    Terms and conditions
                  </Link>
                </div>
              </div>

              <button type="button" onClick={() => {
                navigate('/login')
              }} class="text-white bg-gradient-to-r bg-bme_blue hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg  px-4 py-2.5 text-center me-2 mb-2 ">Sign In</button>

              <button type="button" onClick={() => {
                navigate('/profile-type')
              }} class="text-white bg-gradient-to-r bg-bme_blue hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg px-4 py-2.5 text-center me-2 mb-2 ">Sign Up</button>


            </div>

          </div>

          <div className="lg:hidden">
            <button onClick={() => setIsOpen(!isOpen)} aria-label="Menu">
              {isOpen ? (
                <XIcon className="h-10 w-10 text-blue-700" />
              ) : (
                <MenuIcon className="h-9 w-9 text-blue-700" />
              )}
            </button>
          </div>


        </div>

        {/* small screen starts here */}

        {isOpen && (
          <>
            <div
              ref={dropdownRef}
              className={`flex justify-center items-center flex-col gap-6 my-2 ml-5`}

            >

              <div className="relative" >
                <button
                  className={`flex items-center px-4 py-2 font-bold  text-[#075CAB] md:px-8 md:py-4   hover:bg-blue-100 rounded ${isActive("/about") ? "bg-blue-200" : ""}`}
                  onClick={() => {
                    navigate('/about')
                    window.scrollTo(0, 0)

                  }}
                >
                  About
                </button>
              </div>

              {/* Guidelines Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setActiveDropdown("guidelines")}

                  className={`flex items-center px-4 py-2 text-[#075CAB] md:px-8 md:py-4  hover:bg-blue-100 rounded ${isActive("/guidelines") ? "bg-blue-200" : ""}`}
                >
                  <Link to="/guidelines" className="flex no-underline font-bold text-[#075CAB]">
                    Guidelines <IoIosArrowDown
                      style={{ fill: "#075CAB" }}
                      className="h-5 w-5 ml-2"
                    />
                  </Link>
                </button>
                <div
                  className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
                >
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/privacypolicy"
                    onClick={handleItemClick}
                  >
                    Privacy policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/cancellation"
                    onClick={handleItemClick}
                  >
                    Cancellation policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/legal"
                    onClick={handleItemClick}
                  >
                    Legal policy
                  </Link>
                  <Link
                    className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                    to="/guidelines/termsandconditions"
                    onClick={handleItemClick}
                  >
                    Terms and conditions
                  </Link>
                </div>
              </div>

              <button type="button" onClick={() => {
                navigate('/login')
              }} class="text-white bg-gradient-to-r bg-bme_blue hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg  px-4 py-2.5 text-center me-2  ">Sign   In</button>

              <button type="button" onClick={() => {
                navigate('/profile-type')
              }} class="text-white bg-gradient-to-r bg-bme_blue hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg px-4 py-2.5 text-center me-2 mb-2 ">Sign Up</button>
            </div>

          </>

        )}

        {/* ends here */}
      </div><div className="pt-[84px] md:pt-[115px] "></div></>
  );
};

export default NavbarHome;
