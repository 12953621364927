import React, { useState, useEffect, useCallback } from 'react';
import Loader from '../../Common/Loader';
import apiClient from '../../ApiClient';

const Banner01 = () => {
  const [bannerHomeImages, setBannerHomeImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const fetchHomeBannerImages = useCallback(async () => {
    try {
      const response = await apiClient.post('/getBannerImages', {
        command: 'getBannerImages',
        banners_id: '54321',
      });

      if (response.data.status === 'success') {
        const bannerHomeData = response.data.response;

        const urlsObject = {};
        await Promise.all(
          bannerHomeData.map(async (banner) => {
            if (banner.files && banner.files.length > 0) {
              const fileKeys = banner.files;

              await Promise.all(
                fileKeys.map(async (fileKey) => {
                  try {
                    const res = await apiClient.post('/getObjectSignedUrl', {
                      command: 'getObjectSignedUrl',
                      bucket_name: "bme-app-admin-data",
                      key: fileKey,
                    });

                    const img_url = await res.data;
                    if (img_url) {
                      urlsObject[fileKey] = img_url;
                    }
                  } catch (error) {
                    // Handle error if necessary
                  }
                })
              );
            }
          })
        );

        setBannerHomeImages(Object.values(urlsObject));
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchHomeBannerImages();
  }, [fetchHomeBannerImages]);

  useEffect(() => {
    if (bannerHomeImages.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerHomeImages.length);
      }, 3000); 

      return () => clearInterval(interval);
    }
  }, [bannerHomeImages]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? bannerHomeImages.length - 1 : prevIndex - 1));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerHomeImages.length);
  };

  if (error) {
    return <div className="text-center text-red-500">Error: {error.message}</div>;
  }

  if (loading) {
    return <div className="text-center"><Loader /></div>;
  }

  return (
    <div id="default-carousel" className="relative mt-2 p-0 md:mt-0 overflow-hidden">
      <div
        className="flex transition-transform duration-700 h-80 md:h-[500px]"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {bannerHomeImages.map((image, index) => (
          <div className="min-w-full" key={index}>
            <img
              src={image}
              className="object-contain w-full"
              alt={`Slide ${index + 1}`}
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        ))}
      </div>

      {/* Slider indicators */}
      <div className="absolute z-30 hidden md:flex -translate-x-1/2 bottom-5 left-1/2 space-x-3">
        {bannerHomeImages.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`}
            aria-current={index === currentIndex}
            aria-label={`Slide ${index + 1}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>

      {/* Slider controls */}
      <button
        type="button"
        className="absolute top-0 left-0 z-10 items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={prevSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <svg
            className="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-0 right-0 z-10 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        onClick={nextSlide}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white">
          <svg
            className="w-4 h-4 text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default Banner01;
