import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import defaultImageUri from "../../../../assets/images/defaultImage.jpg"
import apiClient from '../../../ApiClient';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

const CompanyGetJobseekerDetails = () => {
  const location = useLocation();
  const {user_id} = useParams()
  const [ imageUrl, setImageUrl ] = useState('')
  const [posts, setPosts] = useState('');
  const [fileKey, setfileKey] = useState()
  const navigate = useNavigate()
  // console.log(posts)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const fetchJobProfile = async () => {
      try {
        const response = await apiClient.post(
          '/getJobProfiles',
          { command: 'getJobProfiles', user_id: user_id }
        );
        setPosts(response.data.response[0]);
        setfileKey(response.data.response[0].fileKey)

        // console.log(response)
      } catch (error) {
        console.error('Error fetching the job profile:', error);
      }
    };
    fetchJobProfile();
  }, []);

  useEffect (()=> {
    
    const handleProfileImage = async (profileKey) => {
      try {
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        if (response.status === 200) {
          setImageUrl(response.data)
       
        } else {
          setImageUrl(defaultImageUri)
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching Resume URL:", error);
      }
    };
  

    handleProfileImage(fileKey)
  },[fileKey])

  if (!posts) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 gap-3">
        <ToastContainer />
        <p className='m-0 text-[16px]'>Job seeker details not available.</p>
        <button onClick={() => navigate('/company-dashboard/profile/my-post-jobs-seekers')} className='custom-btn'>All job seekers</button>
      </div>
    );
  }





  return (
    <div className=" mx-auto px-4 mt-10">
      <ToastContainer />
      <Helmet>
        <title>Job seeker details | {posts.first_name} {posts.last_name}</title>
        <meta property="og:title" content={`Job seeker details | ${posts.first_name} ${posts.last_name}`}></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/company-dashboard/profile/my-post-jobs-seekers" />
      </Helmet>

      <h1 className="custom-h1  text-center mb-8 mt-5 ">Job Seeker Details</h1>

      {/* Container for all details */}
      <div className="bg-white border max-w-3xl w-full mx-auto border-gray-200 rounded-lg shadow-lg p-6">
        <div className="flex flex-col items-center mb-6">
          <img src={imageUrl}
            onContextMenu={(e) => e.preventDefault()} alt="Candidate" className="w-32 h-32 rounded-full object-contain shadow-md mb-4" />
          <div className="flex flex-col justify-start mb-2">
                      <span className="font-bold text-lg text-[18px]">{posts.first_name}{posts.last_name}</span>
                      <span className="text-sm text-gray-500 -mt-1">{posts?.user_category}</span>
                    </div>
          <button className="custom-btn mt-2" onClick={() => {
            // handleResume(profile.resume_key)
            window.open(
              "https://wa.me/+918310491223",
              "_blank",
              "noopener noreferrer"
            )
          }}>
            View resume
          </button>
        </div>

        {/* Centered grid of details */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 ">
          <div className="flex justify-start ">
            <span className="font-bold w-36">Gender</span>
            <span>: {posts.gender || 'N/A'}</span>
          </div>
          {/* <div className="flex justify-start ">
  <span className="font-bold w-36">College</span>
  <span>: {posts.college || 'N/A'}</span>
</div> */}
          <div className="flex justify-start ">
            <span className="font-bold w-36">City</span>
            <span>: {posts.city || 'N/A'}</span>
          </div>
          <div className="flex justify-start ">
            <span className="font-bold w-36">State</span>
            <span>: {posts.state || 'N/A'}</span>
          </div>
          <div className="flex justify-start ">
            <span className="font-bold w-36">Experience</span>
            <span>: {posts.work_experience || 'N/A'}</span>
          </div>
          <div className="flex justify-start ">
            <span className="font-bold w-36">Expert In</span>
            <span>: {posts.expert_in || 'N/A'}</span>
          </div>
          <div className="flex justify-start ">
            <span className="font-bold w-36">Domain Strength</span>
            <span>: {posts.domain_strength || 'N/A'}</span>
          </div>
          {/* <div className="flex justify-start ">
  <span className="font-bold w-36">Employment Type</span>
  <span>: {posts.employment_type || 'N/A'}</span>
</div> */}
          <div className="flex justify-start ">
            <span className="font-bold w-36">Languages</span>
            <span>: {posts.languages || 'N/A'}</span>
          </div>
          {/* <div className="flex justify-start ">
  <span className="font-bold w-36">Notice Period</span>
  <span>: {posts.notice_period || 'N/A'}</span>
</div> */}
          <div className="flex justify-start ">
            <span className="font-bold w-36">Expected Salary</span>
            <span>: {posts.preferred_salary || 'N/A'}</span>
          </div>
          {/* <div className="flex justify-start ">
  <span className="font-bold w-36">Preferred Shift</span>
  <span>: {posts.preferred_shift || 'N/A'}</span>
</div> */}
          <div className="flex justify-start ">
            <span className="font-bold w-36">Preferred Cities</span>
            <span>: {posts.preferred_cities || 'N/A'}</span>
          </div>

        </div>


        <div className="w-full flex items-center justify-center mx-auto mt-3 space-x-4">
          <button
            className=" custom-btn "
            onClick={() =>
              window.open(
                "https://wa.me/+918310491223",
                "_blank",
                "noopener noreferrer"
              )
            }
          >
            Contact
          </button>
          <button className="custom-btn" onClick={() => {
            navigate(-1)
          }}>
            Back
          </button>

        </div>
      </div>
    </div>
  );
};

export default CompanyGetJobseekerDetails;
