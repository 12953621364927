// JobProfile.js
import React, { useContext, useEffect, useState } from 'react';
import apiClient from '../../../ApiClient';
import { AuthContext } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import defaultImage from "../../../../assets/images/default_user.jpg";
import { Helmet } from 'react-helmet';

const JobProfile = () => {
  const [profile, setProfile] = useState(null);
  const {user} = useContext(AuthContext)
  const navigate = useNavigate()
  
  const [imgurl, setImgUrl] = useState()
  const [fileKey, setfileKey] = useState()

 
  // console.log(imgurl)

useEffect (()=> {
  if(typeof imgurl == "string"){
    // console.log("imgurl")
    setImgUrl(imgurl)
  }else{
    setImgUrl(defaultImage)
  }
},[imgurl])

  useEffect(() => {

    

    const fetchJobProfile = async () => {
      try {
        const response = await apiClient.post(
          '/getJobProfiles',
          { command: 'getJobProfiles', user_id: user.user_id }
        );
        console.log(response.data.response[0])
        setProfile(response.data.response[0]);
        setfileKey(response.data.response[0].fileKey)
        // console.log("response.data.response[0].fileKey",response.data.response[0].fileKey)
        // console.log(response)
      } catch (error) {
        console.error('Error fetching the job profile:', error);
      }
    };
   

    fetchJobProfile();
  }, []);

  
 

  useEffect(() => {
    const handleprofile = async (profileKey) => {
      try {
        // console.log(profileKey)
        if(profileKey) {
          const response = await apiClient.post("/getObjectSignedUrl", {
            command: "getObjectSignedUrl",
            key: profileKey,
          });
          if (response.status == 200 ) { 
            setImgUrl(response.data )
  
            // console.log(response.data)
          } else {
            console.error("Invalid response data:", response.data);
          }
        }
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        // console.log(response.data)
        if (response.status == 200 ) {
          setImgUrl(response.data)

          // console.log(response.data)
        } else {
          console.error("Invalid response data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching profile URL:", error);
      }
    };
    handleprofile(fileKey);

  }, [fileKey]);


  const handleResume = async (ResumeKey) => {
    try {
      const response = await apiClient.post("/getObjectSignedUrl", {
        command: "getObjectSignedUrl",
        key: ResumeKey,
      });
      // console.log(response.data)
      if (response.data ) {
        window.open(response.data, '_blank', 'noopener,noreferrer');
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching Resume URL:", error);
    }
  };

  const handleEditJObProfile= () => {
   navigate(`/user-dashboard/profile/my-job-profile-edit`)
  }


  if (!profile) {
    return (
      <div className="flex flex-col items-center justify-center mt-10">
       
        <ToastContainer/>
        <p className='m-0 text-[16px]'>You do not have a Job Profile.</p>
        <button
          className="mt-4 custom-btn"
          onClick={() => navigate(`/user-dashboard/profile/create-job-profile`)} // Adjust the route to your create job profile path
        >
          Create Job Profile
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 p-4 bg-white shadow-md rounded-lg">
    <Helmet>
        <title>BME Bharath | Job profile</title>
        <meta property="og:title" content="BME Bharath | Job profile"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/my-job-profile" />
      </Helmet>
      <div className="flex items-center mx-auto justify-center space-x-4">
        {/* Profile Image */}
        <img
            src={imgurl || defaultImage}
            
            onContextMenu={(e) => e.preventDefault()}
            alt="Profile"
            className="w-32 h-32 object-contain rounded-full"
          />
        <div>
          {/* Name and Personal Info */}
          <div className="flex flex-col justify-start m-1 mb-2">
                      <span className="font-bold text-lg text-[18px]">{profile.first_name}{profile.last_name}</span>
                      <span className="text-sm text-gray-500 -mt-1">{profile?.user_category}</span>
                    </div>
          <p className="text-gray-600 m-1">{profile.gender}</p>
          {/* <p className="text-gray-600 m-1">{profile.date_of_birth}</p> */}
          <p className="text-gray-600 m-1">Work Experience: {profile.work_experience}</p>
        </div>
      </div>

      {/* More Details */}
      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-6  w-[70%] mx-auto ">
        <div>
          <h3 className="text-lg font-semibold">Contact Details</h3>
          <p className="text-gray-600">Phone: {profile.user_phone_number}</p>
          <p className="text-gray-600">Email: {profile.user_email_id}</p>
          <p className="text-gray-600">Location: {profile.city}, {profile.state}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Job Preferences</h3>
          <p className="text-gray-600">Preferred Cities: {profile.preferred_cities}</p>
          <p className="text-gray-600">Expected Salary: {profile.preferred_salary}</p>
          <p className="text-gray-600">Domain Strength: {profile.domain_strength}</p>
        </div>
        <div>
          <h3 className="text-lg font-semibold">Industry Type</h3>
          <p className="text-gray-600">{profile.industry_type}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Expert in</h3>
          <p className="text-gray-600">{profile.expert_in}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Languages known</h3>
          <p className="text-gray-600">{profile.languages}</p>
        </div>
      </div>

  
      <div className="mt-6 flex items-center justify-center mx-auto no-underline gap-2">
      {profile && profile.resume_key && ( 
        <button
          onClick={()=>{
            handleResume(profile.resume_key)
            // console.log(profile.resume_key)
          }}
          className="custom-btn no-underline" 
        
        >
         Resume
        </button>
      )}
        <button className='custom-btn' onClick={()=>{
          handleEditJObProfile(profile)
        }}>
      Edit job profile
    </button>
      </div>
    

    </div>
  );
};

export default JobProfile;
