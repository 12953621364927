import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../../AuthContext";
import apiClient from "../../../ApiClient";
import defaultImage from "../../../../assets/images/defaultImage.jpg"
import LoaderButton from "../../../Common/LoaderButton";
import { Helmet } from "react-helmet";

const MyArticleEdit = () => {
  const location = useLocation();

  const [userid, setUserid] = useState("");
  const [loading, setLoading] = useState(false)
  const [imageFile, setImageFile] = useState();
  const [fileKey, setFileKey] = useState("");
  const [imageUrl, setImageUrl] = useState()
  const [postData, setPostData] = useState({
    article_title: "",
    article_body: "",
    article_conclusion: "",
  });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { articles, imageUrls } = location.state || {};

  // console.log(articles);
  // console.log(imageUrls);

  useEffect(() => {
    if (typeof imageUrls === "string") {
      setImageUrl(imageUrls);
    } else {
      setImageUrl(defaultImage);
    }
  }, [imageUrls]);

  useEffect(() => {
    if (!user) {
      toast.error("Please log in to update an article.");
      return;
    }

    setUserid(user.user_id);

    if (articles) {
      const { article_title, article_body, article_conclusion, fileKey } = articles;
      setPostData({
        article_title,
        article_body,
        article_conclusion,
      });

      setFileKey(fileKey || "");
      // setImageUrl(imageUrls || defaultImage); 

    }
  }, [user, articles]);


  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 200 * 1024) {
        toast.error("Image size exceeds 200 KB. Please choose a smaller image.");
        return;
      }
      setImageFile(file);
    }
  };

  const deleteFileFromS3 = async (key) => {
    try {
      const response = await apiClient.post("/deleteFileFromS3", {
        command: "deleteFileFromS3",
        key: key,
      });
      console.log("deleteFileFromS3", response.data.message);
    } catch (error) {
      console.error("Error deleting file:", error);
      return false;
    }
  };


  const clearImage = async () => {
    setImageFile("");
    if (fileKey) {
      await deleteFileFromS3(fileKey)
    }
    setFileKey("");
    setImageUrl(defaultImage)
    document.getElementById("article_image").value = ""; // Reset file input
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUploadImage = async (base64, type) => {
    setLoading(true)
    try {
      const uploadResponse = await apiClient.post("/uploadImage", {
        command: "uploadImage",
        headers: {
          "Content-Type": type,
        },
        fileBuffer: base64,
      });

      const uploadResult = await uploadResponse.data;

      if (uploadResult.statusCode === 200) {
        const body = JSON.parse(uploadResult.body);
        if (body.fileKey) {
          return body.fileKey;
        } else {
          throw new Error("fileKey not found in response");
        }
      } else {
        throw new Error(`Upload failed with status ${uploadResponse.status}`);
      }
    } catch (error) {
      console.error("Error in handleUploadImage:", error.message);
      toast.error("Image upload failed.");
      throw error;
    } finally {
      setLoading(false)
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    // console.log(articles.fileKey)

    if (fileKey && imageFile) {
      try {
        const res = await apiClient.post("/deleteFileFromS3", {
          command: "deleteFileFromS3",
          key: articles.fileKey,
        });

        // console.log("deleteFileFromS3",res)
      } catch (error) {
        toast.error("Failed to delete old image");
        return;
      }
    }

    let newFileKey = fileKey;

    // Check if a new image is selected and upload it
    if (imageFile) {
      const base64 = await convertToBase64(imageFile);
      try {
        newFileKey = await handleUploadImage(base64, imageFile.type);
      } catch (error) {
        toast.error("Image upload failed.");
        return;
      }
    }
    const articleData = {
      command: "updateArticle",
      user_id: user.user_id,
      article_id: articles.article_id,
      fileKey: newFileKey,
      article_title: postData.article_title,
      article_body: postData.article_body,
      article_conclusion: postData.article_conclusion,
    };
    try {
      const res = await apiClient.post("/updateArticle", articleData);
      // console.log(res)
      if (res.data.status == "success") {
        toast.success(res.data.status_message);
        setTimeout(() => {
          navigate("/admin-dashboard/profile/my-article-list");
        }, 1000);
      } else {
        toast.success(res.data.errorMessage);
      }
    } catch (error) {
      toast.error("Error updating article");
    } finally {
    setLoading(false)

    }
  };


  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="max-w-[1370px] w-full mt-10">
      <ToastContainer />
      <Helmet>
        <title>BME Bharath | Article</title>
        <meta property="og:title" content="BME Bharath | Article"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/profile/my-article-list" />
      </Helmet>
      <h1 className="custom-h1 my-8 text-center z-[1000]">Edit Article</h1>

      <form
        className="max-w-3xl w-[90%] md:w-full mx-auto"
        onSubmit={handleSubmit}
      >
        <div className="mb-4 ">
          <label
            htmlFor="article_title"
            className="block mb-2 font-medium text-gray-900"
          >
            Title
          </label>
          <input
            type="text"
            id="article_title"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required
            value={postData.article_title}
            onChange={(e) =>
              setPostData({ ...postData, article_title: e.target.value })
            }
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_image"
            className="block mb-2 font-medium text-gray-900"
          >
            Add Image
          </label>
          <div className=" flex justify-center items-center mx-auto">

            {/* starts */}
            {imageUrl && !imageFile && (
              <div className="relative mt-4 inline-block">
                <img
                  src={imageUrl}
                  alt="Old Article"
                  onContextMenu={(e) => e.preventDefault()}
                  className="image-standard mb-2 cursor-pointer"
                  onClick={() => document.getElementById("article_image").click()} // Clicking on the image opens file picker
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-none p-1 rounded-full shadow"
                  onClick={() => document.getElementById("article_image").click()}
                >
                  <i className="fa fa-pencil text-black" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    clearImage()
                  }}
                  className="text-red-500 hover:underline mt-2 block"
                >
                  Remove image{" "}
                  <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
                </button>
              </div>
            )}
            {imageFile && (
              <div className="relative mt-4 inline-block">
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="Selected"
                  onContextMenu={(e) => e.preventDefault()}
                  className="image-standard cursor-pointer"
                  onClick={() => document.getElementById("article_image").click()} // Clicking on the image opens file picker
                />
                {/* Pencil Icon in the top-right corner */}
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-white p-1 rounded-full shadow"
                  onClick={() => document.getElementById("article_image").click()}
                >
                  <i className="fa fa-pencil text-black" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    clearImage()
                  }}
                  className="text-red-500 hover:underline mt-2 block"
                >
                  Remove image{" "}
                  <i className="fa fa-trash text-red-500" aria-hidden="true"></i>
                </button>
              </div>
            )}
            {/* ends */}
          </div>
          <input
            type="file"
            id="article_image"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_body"
            className="block mb-2 font-medium text-gray-900"
          >
            Description
          </label>
          <textarea
            id="article_body"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-40"
            required
            value={postData.article_body}
            onChange={(e) =>
              setPostData({ ...postData, article_body: e.target.value })
            }
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="article_conclusion"
            className="block mb-2 font-medium text-gray-900"
          >
            Conclusion
          </label>
          <textarea
            id="article_conclusion"
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-40"
            // required
            value={postData.article_conclusion}
            onChange={(e) =>
              setPostData({ ...postData, article_conclusion: e.target.value })
            }
          />
        </div>

        <div className="flex gap-2">
          <button type="submit" className="custom-btn" disabled = {loading}>
          {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Updating
              </div>
            ) : (
              <>Update</>
            )}
          </button>
          <button className="custom-btn ml-2" onClick={(e)=>{
            e.stopPropagation()
            handleCancel()
          }}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyArticleEdit;
