import React, { useState, useEffect } from 'react';

const CustomDropdown = ({ items, placeholder, onSelect, lastSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    if (lastSelected) {
      const selected = items.find(item => item.label === lastSelected);
      if (selected) {
        setSelectedItem(selected.value);
      }
    }
  }, [lastSelected, items]);

  const handleItemClick = (item) => {
    setSelectedItem(item.value);
    onSelect(item);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left w-full my-2">
      <div>
        <button
          type="button"
          className="inline-flex justify-between w-full px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedItem
    ? items.find(item => item.value === selectedItem)?.label || placeholder
    : placeholder}

          <svg
            className={`w-5 h-5 transition-transform transform ${isOpen ? 'rotate-180' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06 0l3.25 3.25 3.25-3.25a.75.75 0 111.06 1.06l-3.75 3.75a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <ul className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {items.map((item) => (
              <li
                key={item.value}
                className="cursor-pointer select-none relative py-2 pl-4 pr-4 hover:bg-gray-100"
                onClick={() => handleItemClick(item)}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
