import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_BACKEND_API_KEY,
    "Content-Type": "application/json",
  },
});

export default apiClient;
