import React from "react";
import { Helmet } from "react-helmet";

const Subscription = () => {
  return (
    <div className="bg-white rounded-lg p-3 shadow-md mt-5 mx-auto w-[90%] md:w-3/4 lg:w-[80%]">
 <Helmet>
        <title>BME Bharat Guidelines | Legal Compliance</title>
        <meta property="og:title" content=">BME Bharat Guidelines | Legal Compliance"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/guidelines/legalpolicy" />
      </Helmet>
      <h1 className="text-center custom-h1  mb-2">Legal Compliance - Bharat</h1>
      <hr className="my-2" />
      <p className="text-gray-600 text-[12px] leading-6 text-justify">
        Effective Date: 01 July 2024
        <br />
        B M E Bharat ("we," "us," or "our") is committed to ensuring that our biomedical engineering app B M E Bharat complies with applicable Indian laws and regulations. We have established this Legal Compliance section to provide transparency and assurance to our Indian users regarding our commitment to legal and regulatory standards specific to India. Please read the following information carefully:
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">Compliance with Data Protection Laws in Bharat:</strong>
        <br />
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">
            Personal Data Protection: We respect the privacy of our users and are committed to complying with Indian data protection laws, including but not limited to the Personal Data Protection Bill. We ensure that your personal data is collected, processed, and stored in accordance with these regulations. For more information about how we handle your data, please review our Privacy Policy.
          </li>
          <li className="text-[12px]">
            Data Localization: We comply with any data localization requirements outlined in Bharat law, which may include storing and processing certain types of data within the geographical boundaries of Bharat.
          </li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">Copyright and Intellectual Property in Bharat:</strong>
        <br />
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">
            Respect for Copyright: We respect the intellectual property rights of others and expect our users to do the same. Any content, including text, images, videos, and other materials, provided within the App must comply with Bharat copyright laws. Users are not allowed to upload or share copyrighted material without the necessary permissions.
          </li>
          <li className="text-[12px]">
            Notification of Copyright Infringement: If you believe your copyright has been infringed by a user of our App, please notify us as described in our Copyright Policy. We will take appropriate action, which may include the removal of the infringing content and, in some cases, termination of the user's account.
          </li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">Compliance with Health Regulations in Bharat:</strong>
        <br />
        Our App may provide information related to biomedical engineering and medical technologies. We do not provide medical advice or services, and the content within the App is for informational purposes only. Users should always consult with qualified healthcare professionals for medical advice and treatment. We do not endorse or promote the use of unapproved or non-compliant medical devices or treatments in India.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">User Content and Community Guidelines:</strong>
        <br />
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">
            Prohibited Content: Users are prohibited from posting or sharing any content that violates Indian laws, including but not limited to content that is defamatory, harmful, discriminatory, or infringing on the rights of others.
          </li>
          <li className="text-[12px]">
            User Conduct: Users are expected to conduct themselves in a respectful and lawful manner when using the App. Harassment, hate speech, or any form of harmful behaviour is strictly prohibited.
          </li>
          <li className="text-[12px]">
            Reporting Violations: If you encounter content or behaviour that violates our community guidelines or Indian laws, please report it to us for review and appropriate action.
          </li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">Updates to Legal Compliance:</strong>
        <br />
        We may update our Legal Compliance to reflect changes in Bharat laws, regulations, or operational requirements. Any significant updates will be communicated to our users in Bharat. By continuing to use the App, you agree to abide by the latest version of our Legal Compliance.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">Contact Us:</strong>
        <br />
        If you have questions or concerns about our legal compliance specific to Bharat or require assistance related to legal matters, please contact us at Email{" "}
        <span className="text-blue-600">admin@bmebharat.com</span> or{" "}
        <span className="text-blue-600">bmebharat@gmail.com</span> or Phone Number <span className="text-blue-600">+91 8310491223</span>.
        <br />
        We are dedicated to ensuring that our App operates in accordance with the highest legal and ethical standards in Bharat. Your trust and satisfaction are essential to us, and we will continue to prioritize legal compliance in our operations within the Indian legal framework.
      </p>
    </div>
  );
};

export default Subscription;
