import React, { useContext, useRef, useState } from 'react';
import apiClient from '../../../ApiClient';
import { AuthContext } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import LoaderButton from '../../../Common/LoaderButton';
import CustomToastContainer from "../../../CustomToastContainer"

const DeleteAccount = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(true);

  const handleDelete = async () => {
    try {
      const res = await apiClient.post("/deleteAccount", {
        command: "deleteAccount",
        user_phone_number: user.user_phone_number,
      });

      if (res.data.status === "success") {
        toast.success(res.data.status_message);
        localStorage.removeItem("user");
        setUser(null);
        setTimeout(() => {
          navigate("/homepage");
        }, 2000);
      } else {
        toast.error(res.data.errorMessage);
      }
    } catch (error) {
      console.log("Error in handleDelete:", error);
      toast.error("An error occurred while deleting your account.");
    }
  };

  const handleCancel = () => {
    navigate("/user-dashboard/profile")
  };

  const handleOTPChange = (index, value) => {
    if (/^[0-9a-zA-Z]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      // console.log(newOtp)
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to the previous input if backspace is pressed and current is empty
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVerifyOtp();
    }
  };

  const handleSendOtp = async () => {
    const fullPhoneNumber =  user.user_phone_number

    try {
      setLoading(true);
      const otpRes = await apiClient.post(
        "/singInOTP",
        {
          command: "sendOtpMsg91",
          user_phone_number: fullPhoneNumber,
        },
        {
          headers: {
            "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
          },
        }
      );
      console.log(otpRes)
      if (otpRes.data?.type === "success") {
        setOtpSent(true);
        toast.success("OTP sent successfully.");
      } else {
        toast.error(otpRes.data.errorMessage);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    const enteredOTP = otp.join('');
    const fullPhoneNumber = user.user_phone_number
    try {
      setLoading(true);
      const response = await apiClient.post('/verifyOtpMsg91', {
        command: 'verifyOtpMsg91',
        otp: enteredOTP,
        user_phone_number: fullPhoneNumber
      });

      if (response.data.type === 'success') {
        setIsOtpVerified(true);
        toast.success("Account deleted successfully!!");
        handleDelete()
      } else {
        toast.error(response.data.message || "Invalid OTP.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-10 flex items-center justify-center p-6">
      <CustomToastContainer/>
      <Helmet>
        <title>BME Bharath | Delete account</title>
        <meta property="og:title" content="BME Bharath | Delete account"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/user-dashboard/profile/deleteaccount" />
      </Helmet>
        <div className="bg-white rounded-lg shadow-lg p-6 w-96 ">
          <h2 className="text-lg font-semibold mb-4">{user.first_name} {user.last_name}, are you sure you want to delete your account?<br /></h2>
          <p className="mb-4">  
            
            By confirming, you will permanently lose all data associated with this account, including your posts in the article and forum sections, comments, uploaded files (images, videos, documents), and transaction details. This action is irreversible.
          </p>
          <p className="mb-6">Do you wish to proceed?</p>
          <div className="flex justify-end space-x-4">
            {!otpSent && (
              <><button onClick={handleCancel} className="custom-btn">
              Cancel
            </button><button onClick={() => {
              handleSendOtp();
            } }
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
              disabled={loading}
            >
                {loading ? "Sending OTP..." : "Yes, Delete"}
              </button></>
            )}
            
  
          {otpSent && (
            <div className="flex flex-col space-y-2">
              <label htmlFor="otp">Enter OTP<span className="text-red-500"> *</span></label>
              <div className="flex justify-center gap-3">

                {otp.map((digit, index) => (
                  <input
                    key={index}
                    className="w-11 h-11 border rounded text-center text-xl focus:outline-none focus:border-blue-500"
                    placeholder="•"
                    type="tel"
                    maxLength={1}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    value={digit}
                    ref={(ref) => (otpInputs.current[index] = ref)}
                  />
                ))}
              </div>
              <button onClick={handleCancel} className="custom-btn w-fit mx-auto">
              Cancel
            </button>
              <button type="submit" className="w-fit mx-auto custom-btn bg-red-500 text-white hover:bg-red-600" onClick={() => {
                handleVerifyOtp()
              }} disabled={loading}>
                {loading ? (
                  <div className='flex justify-center items-center text-white'>
                    <LoaderButton />
                    Verifying OTP
                  </div>
                ) : (
                  <>Verify OTP and Delete Account</>
                )}
              </button>
            </div>
          )}

          </div>
        </div >  
     
    </div>
  );
};

export default DeleteAccount;
