import React from 'react';
import { Helmet } from 'react-helmet';

const Cancellation = () => {
  return (
    <div className="bg-white rounded-lg p-3 shadow-md mt-5 mx-auto w-[90%] md:w-3/4 lg:w-[80%]">
         <Helmet>
        <title>BME Bharat Guidelines | Cancellation Policy</title>
        <meta property="og:title" content=">BME Bharat Guidelines | Cancellation Policy"></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/guidelines/cancellation" />
      </Helmet>
      <h1 className="text-center custom-h1  mb-2">Cancellation Policy</h1>
      <hr className="my-2" />
      <p className="text-gray-600 text-[12px] leading-6 text-justify">
        Effective Date: 01 July 2024
        <br />
        Thank you for choosing to subscribe to our biomedical engineering app B M E Bharat. We value your satisfaction and aim to provide a clear and fair policy regarding subscription cancellations and refunds. Please carefully review the following terms:
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Subscription Cancellations:
        </strong>
        <br />
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">
            <strong className="text-[12px]">Cancellation by the Subscriber:</strong> Subscribers may cancel their subscription at any time. To cancel your subscription, please follow the cancellation process provided within the App or contact our customer support at Email <span className="text-blue-600">admin@bmebharat.com</span> or <span className="text-blue-600">bmebharat@gmail.com</span> or Phone Number <span className="text-blue-600">+91 8310491223</span>.
          </li>
          <li className="text-[12px]">
            <strong className="text-[12px]">Cancellation by the App Provider:</strong> We reserve the right to cancel or suspend a subscription in case of violations of our Terms of Service or if there are reasonable grounds to believe that the subscription is being misused. In such cases, no refund will be provided.
          </li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">
          Refunds:
        </strong>
        <br />
        <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
          <li className="text-[12px]">
            <strong className="text-[12px]">Refund Eligibility:</strong> Subscribers may be eligible for a refund if the following conditions are met:
            <ul className="list-disc ml-5 text-gray-600 text-[12px] leading-6">
              <li className="text-[12px]">You request a refund within 5 days of the subscription purchase.</li>
              <li className="text-[12px]">You have not violated our Terms of Service.</li>
              <li className="text-[12px]">You have not used the App for an extended period, and there is a valid reason for the refund.</li>
            </ul>
          </li>
          <li className="text-[12px]">
            <strong className="text-[12px]">Refund Process:</strong> To request a refund, please contact our customer support at Email <span className="text-blue-600">admin@bmebharat.com</span> or <span className="text-blue-600">bmebharat@gmail.com</span> or Phone Number <span className="text-blue-600">+91 8310491223</span>. Provide the necessary details, including your subscription information and the reason for the refund request. Our support team will review your request and respond within a reasonable time.
          </li>
          <li className="text-[12px]">
            <strong className="text-[12px]">Refund Methods:</strong> Refunds will be issued using the same payment method that you used for the subscription purchase. The time it takes for the refund to appear in your account may vary depending on your financial institution.
          </li>
        </ul>
        <strong className="text-gray-800 text-[12px] font-bold">
          Subscription Changes and Upgrades:
        </strong>
        <br />
        If you wish to change your subscription plan or upgrade to a different subscription level, you can do so at any time through the App. Any change in subscription fees will be prorated based on the remaining duration of your current subscription.
        <br />
        <br />
        <strong className="text-gray-800 text-[12px] font-bold">
          Subscription Renewals:
        </strong>
        <br />
        Your subscription will automatically renew at the end of the subscription period, unless you cancel it. You will be charged the subscription fee for the next billing cycle, which will be of the same duration as your initial subscription. To avoid automatic renewal, please cancel your subscription as described above.
        <br />
        <br />

        <strong className="text-gray-800 text-[12px] font-bold">
          Contact Us:
        </strong>
        <br />
        If you have any questions or concerns about our Cancellation/Refunds Policy or need assistance with your subscription, please contact us at Email <span className="text-blue-600">admin@bmebharat.com</span> or <span className="text-blue-600">bmebharat@gmail.com</span> or Phone Number <span className="text-blue-600">+91 8310491223</span>.
        <br />
        By subscribing to our App, you agree to abide by the terms and conditions outlined in this policy. We are committed to providing a transparent and equitable subscription experience for our users.
      </p>
    </div>
  );
};

export default Cancellation;
