import React, { useContext, useEffect, useRef, useState } from "react";
import { MenuIcon, XIcon, ChevronDownIcon } from "@heroicons/react/outline";
import logo from "../../assets/images/logo-crop.png"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import uparrow from "../../assets/images/up-arrow.png"
import { IoIosArrowDown, IoIosLogOut } from "react-icons/io";
import Loader from "./Loader";

const NavbarCompany = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const { user, setUser, loading } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  // scroll to top btn starts ✅
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    setActiveDropdown("");
    setIsOpen(false)
  };


  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown("");
        setIsOpen(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.addEventListener("scroll", handleScroll);

    };
  }, []);


  const handleDropdownClick = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? "" : dropdown);
  };

  const handleItemClick = () => {
    setIsOpen(false); // Close the dropdown when an item is clicked
    setActiveDropdown(""); // Optionally reset active dropdown
  };

  // Determine if the current path matches any of the dropdown items
  const isActive = (path) => location.pathname.startsWith(path);

  // Redirect to login if user is not authenticated
  useEffect(() => {
    // Check for user data in localStorage if user context is empty
    const storedUser = localStorage.getItem("user");
    if (!user && storedUser) {
      setUser(JSON.parse(storedUser)); // Set user from localStorage
    }

    if (!loading && !user && !storedUser) {
      navigate("/"); // Redirect to login page if no user is found in state or localStorage
    }
  }, [user, loading, navigate, setUser]);

  const handleLogout = () => {
    // Confirmation before logout
    const confirmLogout = window.confirm('Are you sure you want to logout?');

    if (confirmLogout) {
      // Clear user data from localStorage and context
      localStorage.removeItem('user');
      setUser(null);
      navigate('/'); // Redirect to homepage or login page
    }
  };

  if (loading) {
    return <div><Loader /></div>;
  }


  return (
    <><div className="bg-white shadow-md fixed mt-0  w-[100%] z-50">

      {/* sticky Scroll to top */}
      <button
        className={`fixed z-50 bottom-24 right-10 rounded-full p-3 transition-opacity duration-300 ease-in-out transform ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-75'}`}
        onClick={scrollToTop}
        aria-label="Scroll to top"
        title="Scroll to Top!"
      >
        <img src={uparrow} alt="scroll to top icon "
          onContextMenu={(e) => e.preventDefault()}
          className="w-12 h-12" />
      </button>

      {/* scroll to top ends ❎ */}

      {/* First Row: Logo item Logout Button ✅ */}

      <div className="flex items-center justify-between px-4 py-2 md:px-8 md:py-4 border-b mx-3 md:mx-10">
        <Link to="/">
          <img src={logo} alt="Logo"

            onContextMenu={(e) => e.preventDefault()}
            className="h-[70px] md:h-[90px]" />
        </Link>

        <div className=" justify-center items-center gap-2 hidden lg:flex">
          <div
            ref={dropdownRef}
            className={`lg:flex `}

          >
            {/* Jobs Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("jobs")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/jobs") ? "bg-blue-200" : ""}`}
              >
                Jobs <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-20 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200"
              `}
                  to="/company-dashboard/jobs"
                  onClick={handleItemClick}
                >
                  Latest jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs"
                  onClick={handleItemClick}
                >
                  My jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200"
              `}
                  to="/company-dashboard/jobs/user-job-post"
                  onClick={handleItemClick}
                >
                  Post a job
                </Link>
              </div>
            </div>

            {/* Articles Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("articles")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold  items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/articles") ? "bg-blue-200" : ""}`}
              >
                Articles <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "articles" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/articles"
                  onClick={handleItemClick}
                >
                  Latest articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-article-list"
                  onClick={handleItemClick}
                >
                  My articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/articles/post"
                  onClick={handleItemClick}
                >
                  Post an article
                </Link>

              </div>
            </div>

            {/* Companies Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("companies")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/company") ? "bg-blue-200" : ""}`}
              >
                Companies <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/company"
                  onClick={handleItemClick}
                >
                  Companies
                </Link>
              </div>
            </div>

        

            {/* Forum Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("forums")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/forums") ? "bg-blue-200" : ""}`}
              >
                Forums <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/forums"
                  onClick={handleItemClick}
                >
                  Latest posts
                </Link>

                {/* <Link
      className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
      to="/company-dashboard/forums/details"
      onClick={handleItemClick}
    >
      Forum Details
    </Link> */}
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/forums/post"
                  onClick={handleItemClick}
                >
                  Post a query
                </Link>
              </div>
            </div>


            {/* Guidelines Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("guidelines")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/guidelines") ? "bg-blue-200" : ""}`}
              >
                <Link
                  to="/company-dashboard/guidelines"
                  className="flex no-underline text-[#075CAB]"
                >
                  Guidelines <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </Link>
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/privacypolicy"
                  onClick={handleItemClick}
                >
                  Privacy policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/cancellation"
                  onClick={handleItemClick}
                >
                  Cancellation policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/legalpolicy"
                  onClick={handleItemClick}
                >
                  Legal policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/termsandconditions"
                  onClick={handleItemClick}
                >
                  Terms and conditions
                </Link>
              </div>
            </div>

            {/* Profile Dropdown */}
            <div className="relative" onMouseEnter={() => setActiveDropdown("profile")} onMouseLeave={() => setActiveDropdown("")}>
              <button
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/profile") ? "bg-blue-200" : ""}`}
              >
                Profile <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 md:w-96 bg-white border border-blue-200 rounded shadow-lg grid  gap-2 ${activeDropdown === "profile" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile"
                  onClick={handleItemClick}
                >
                  Company profile
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs"
                  onClick={handleItemClick}
                >
                  My jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs-seekers"
                  onClick={handleItemClick}
                >
                  Job seekers
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-article-list"
                  onClick={handleItemClick}
                >
                  My articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/subscription"
                  onClick={handleItemClick}
                >
                  Subscription
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/subscription-list"
                  onClick={handleItemClick}
                >
                  My subscriptions
                </Link>

                <Link
                  className={`flex items-center font-bold px-4 py-2 text-red-500 hover:bg-blue-100 no-underline border-b border-blue-200`}

                  onClick={handleLogout}

                >
                  <IoIosLogOut className='mr-2' /> Logout
                </Link>


              </div>
            </div>




          </div>

        </div>

        <div className="lg:hidden">
          <button onClick={() => setIsOpen(!isOpen)} aria-label="Menu">
            {isOpen ? (
              <XIcon className="h-10 w-10 text-[#075CAB]" />
            ) : (
              <MenuIcon className="h-9 w-9 text-[#075CAB]" />
            )}
          </button>
        </div>


      </div>


      {/* small screen starts here */}

      {isOpen && (
        <>
          <div
            ref={dropdownRef}
            className={`flex justify-center items-center flex-col gap-6 my-2 ml-5`}

          >
            {/* Jobs Dropdown */}
            <div className="relative" >
              <button
                onClick={() => setActiveDropdown("jobs")}
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/jobs") ? "bg-blue-200" : ""}`}
              >
                Jobs <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-20 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "jobs" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200"
              `}
                  to="/company-dashboard/jobs"
                  onClick={handleItemClick}
                >
                  All jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs"
                  onClick={handleItemClick}
                >
                  My jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200"
              `}
                  to="/company-dashboard/jobs/user-job-post"
                  onClick={handleItemClick}
                >
                  Post a job
                </Link>
              </div>
            </div>

            {/* Articles Dropdown */}
            <div className="relative">
              <button
                onClick={() => setActiveDropdown("articles")}

                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/articles") ? "bg-blue-200" : ""}`}
              >
                Articles <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "articles" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/articles"
                  onClick={handleItemClick}
                >
                  Articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-article-list"
                  onClick={handleItemClick}
                >
                  My articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/articles/post"
                  onClick={handleItemClick}
                >
                  Post an article
                </Link>

              </div>
            </div>

            {/* Companies Dropdown */}
            <div className="relative" >
              <button
                onClick={() => setActiveDropdown("companies")}

                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/company") ? "bg-blue-200" : ""}`}
              >
                Companies <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "companies" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/company"
                  onClick={handleItemClick}
                >
                  Companies
                </Link>
              </div>
            </div>


            
            {/* Forum Dropdown */}
            <div className="relative">
              <button
                onClick={() => setActiveDropdown("forums")}

                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/forums") ? "bg-blue-200" : ""}`}
              >
                Forums <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "forums" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/forums"
                  onClick={handleItemClick}
                >
                  Posts
                </Link>

                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/forums/post"
                  onClick={handleItemClick}
                >
                  Post a query
                </Link>
              </div>
            </div>

            {/* Profile Dropdown */}
            <div className="relative">
              <button
                onClick={() => setActiveDropdown("profile")}
                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/profile") ? "bg-blue-200" : ""}`}
              >
                Profile <IoIosArrowDown
                  style={{ fill: "#075CAB" }}
                  className="h-5 w-5 ml-2"
                />
              </button>
              <div
                className={`absolute z-10 left-0  w-48 md:w-96 bg-white border border-blue-200 rounded shadow-lg grid md:grid-cols-2 gap-2 ${activeDropdown === "profile" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile"
                  onClick={handleItemClick}
                >
                  My profile
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs"
                  onClick={handleItemClick}
                >
                  My jobs
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-post-jobs-seekers"
                  onClick={handleItemClick}
                >
                  Job seekers
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-article-list"
                  onClick={handleItemClick}
                >
                  My articles
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/my-forum-list"
                  onClick={handleItemClick}
                >
                  My posts
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/subscription"
                  onClick={handleItemClick}
                >
                  Subscription
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/profile/subscription-list"
                  onClick={handleItemClick}
                >
                  My subscriptions
                </Link>
              </div>
            </div>

            {/* Guidelines Dropdown */}
            <div className="relative" >
              <button
                onClick={() => setActiveDropdown("guidelines")}

                className={`flex font-bold items-center px-4 py-2 md:px-8 md:py-4 text-[#075CAB] hover:bg-blue-100 rounded ${isActive("/company-dashboard/guidelines") ? "bg-blue-200" : ""}`}
              >
                <Link
                  to="/company-dashboard/guidelines"
                  className="flex no-underline text-[#075CAB]"
                >
                  Guidelines <IoIosArrowDown
                    style={{ fill: "#075CAB" }}
                    className="h-5 w-5 ml-2"
                  />
                </Link>
              </button>
              <div
                className={`absolute z-10 left-0  w-48 bg-white border border-blue-200 rounded shadow-lg ${activeDropdown === "guidelines" ? "block" : "hidden"}`}
              >
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/privacypolicy"
                  onClick={handleItemClick}
                >
                  Privacy policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/cancellation"
                  onClick={handleItemClick}
                >
                  Cancellation policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/legalpolicy"
                  onClick={handleItemClick}
                >
                  Legal policy
                </Link>
                <Link
                  className={`block px-4 py-2 text-[#075CAB] hover:bg-blue-100 no-underline border-b border-blue-200`}
                  to="/company-dashboard/guidelines/termsandconditions"
                  onClick={handleItemClick}
                >
                  Terms and conditions
                </Link>
              </div>
            </div>

            {/* Nav items (for small screens) ✅*/}
            <div className=" justify-center flex-col items-center gap-8 flex mr-5">

              <button onClick={handleLogout} className=" flex font-bold items-center btn-custom mb-4">
                <IoIosLogOut className='mr-2' /> Logout
              </button>
            </div>
          </div>

        </>

      )}

      {/* ends here */}
    </div><div className="pt-[84px] md:pt-[115px] "></div></>
  );
};

export default NavbarCompany;
