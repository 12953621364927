import React, { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import apiClient from "../../../ApiClient";
import dummy_image from "../../../../assets/images/default_user.jpg";
import { stateCityData, CountryCodes } from '../../../Data';
import LoaderButton from "../../../Common/LoaderButton";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../AuthContext";


const AdminProfileUpdate = () => {
  const { login, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, imageUrl } = location.state || {};
  const [imageUri, setImageUri] = useState(imageUrl);
  const [phoneNumber, setPhoneNumber] = useState(profile?.user_phone_number.slice(-10));
  const [countryCode, setCountryCode] = useState(profile?.user_phone_number.slice(0, profile?.user_phone_number.length - 10));
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const otpInputs = useRef([]);
  const [otpSent, setOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(true);

  const [postData, setPostData] = useState({
    user_phone_number: profile?.user_phone_number || "",
    user_email_id: profile?.user_email_id || "",
    first_name: profile?.first_name || "",
    last_name: profile?.last_name || "",
    fileKey: imageUrl || "",
  });
  const [fileKey, setFileKey] = useState(profile.fileKey || "");

  const handleInputChange = (key, value) => {
    setPostData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleImageSelection = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 200) {
        toast.error("File size exceeds 200 KB limit");
        return;
      }
      if (fileKey) {
        await deleteFileFromS3(fileKey);
      }
      setImageUri(URL.createObjectURL(file));
      setFileKey(file);
    }
  };

  const handleUploadImage = async (file) => {
    setLoading(true)
    if (file) {
      try {
        const base64 = await getBase64FromBlob(file);
        const uploadResponse = await apiClient.post("/uploadImage", {
          command: 'uploadImage',
          headers: {
            'Content-Type': file.type,
          },
          fileBuffer: base64,

        });

        const uploadResult = await uploadResponse.data;
        if (uploadResult.statusCode === 200) {
          const body = JSON.parse(uploadResult.body);
          if (body.fileKey) {
            setFileKey(body.fileKey);
            return body.fileKey;
          } else {

            throw new Error('FileKey not found in response');
          }
        } else {
          toast.error(uploadResponse.data.body)
        }
      } catch (error) {
        console.error('Error in handleUploadImage:', error.message);

      } finally {
        setLoading(false)
      }
    }

  };

  const getBase64FromBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  // otp verify for phone ✅

  const handleOTPChange = (index, value) => {
    if (/^[0-9a-zA-Z]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      // console.log(newOtp)
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to the previous input if backspace is pressed and current is empty
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      handleVerifyOtp();
    }
  };

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setPhoneNumber(newPhone);
    setOtpSent(false);
    if (profile?.user_phone_number == countryCode + newPhone) {
      setIsOtpVerified(true);
    } else {
      setIsOtpVerified(false);
    }
  };

  const handleSendOtp = async () => {
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    if (!/^\d{10}$/.test(phoneNumber)) {
      return toast.error("Phone number must be exactly 10 digits.");
    }

    try {
      setLoading(true);
      const otpRes = await apiClient.post(
        "/sendOtpVerificationMsg91",
        {
          command: "sendOtpVerificationMsg91",
          user_phone_number: fullPhoneNumber,
        },
        {
          headers: {
            "x-api-key": "YTY0KJTGmQ7VTdqCyiLmi5Ew4tpKK7KsagnPa5Qg",
          },
        }
      );
      if (otpRes.data?.type === "success") {
        setOtpSent(true);
        toast.success("OTP sent successfully.");
      } else {
        toast.error(otpRes.data.errorMessage);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    const enteredOTP = otp.join('');
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;

    try {
      setLoading(true);
      const response = await apiClient.post('/verifyOtpMsg91', {
        command: 'verifyOtpMsg91',
        otp: enteredOTP,
        user_phone_number: fullPhoneNumber
      });
      if (response.data.type === 'success') {
        setIsOtpVerified(true);
        toast.success("Phone number verified successfully.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Error verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  const states = Object.keys(stateCityData);
  const cities = postData.state ? stateCityData[postData.state] : [];

  const handlePostSubmission = async () => {
    setLoading(true)
    try {
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      if (!postData.first_name) {
        return toast.error("First name is required")
      }

      if (!/^\d{10}$/.test(phoneNumber)) {
        return toast.error("Phone number must be exactly 10 digits.");
      }
      if (!isOtpVerified) {
        return toast.error("Phone number must be verified via OTP.");
      }
      if (!postData.user_phone_number) {
        return toast.error("Phone number is required")
      }
      if (!postData.user_email_id) {
        return toast.error("Email ID is required")
      }

      const imageFileKey = fileKey ? await handleUploadImage(fileKey) : "";
      const postPayload = {
        command: "updateUserDetails",
        user_id: profile.user_id,
        user_phone_number: fullPhoneNumber,
        user_email_id: postData.user_email_id,
        first_name: postData.first_name,
        last_name: postData.last_name,
        fileKey: imageFileKey,
      };

      // console.log("postPayload", postPayload)

      const res = await apiClient.post(
        '/updateUserDetails',
        postPayload
      );

      if (res.data.status === 'success') {
        toast.success('Profile updated successfully.');
        const res = await apiClient.post('/getUserDetails', {
          command: "getUserDetails",
          user_id: user.user_id,
        })
        localStorage.setItem('user', JSON.stringify(res.data.status_message));
        setTimeout(() => {
          navigate('/admin-dashboard/profile', { state: { postPayload } });
          window.scrollTo(0, 0)
        }, 2000);
      } else {
        toast.error(res.data.errorMessage || 'Failed to update profile.');

      }
    } catch (error) {
      toast.error('An error occurred while updating the profile.');
    } finally {
      setLoading(false)
    }
  };

  const handleRemoveImage = async () => {
    setImageUri(dummy_image);
    if (fileKey) {
      await deleteFileFromS3(fileKey)
    }
    setFileKey("");
    setPostData((prevData) => ({
      ...prevData,
      fileKey: "",
    }));
  };

  // delete file from s3

  const deleteFileFromS3 = async (key) => {
    setLoading(true)
    try {
      const response = await apiClient.post('/deleteFileFromS3', {
        command: 'deleteFileFromS3',
        key: key,
      });
      // console.log("deleteFileFromS3", response)
    } catch (error) {
      console.error('Error deleting file:', error);
      return false;
    } finally {
      setLoading(false)
    }
  };

  const handleCancel = () => {
    let confirm = window.confirm ("All unsaved changes will be discarded. Do you want to continue? ")
    if (confirm) {
      navigate(-1)
    }
  }

  return (
    <div className="min-h-screen flex flex-col items-center bg-white">
      <Helmet>
        <title>BME Bharat | Update profile</title>
        <meta property="og:title" content={`Profile`}></meta>
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering. Empowering the next generation of engineers to drive advancements in healthcare technology." />
        <meta property="og:url" content="https://bmebharat.com/admin-dashboard/profile/update" />
      </Helmet>
      <ToastContainer />
      <div className="max-w-xl w-full p-8 space-y-">
        <h1 className="my-10 custom-h1 font-bold text-center">
          {" "}
          Update Profile
        </h1>
        <div className="flex  justify-center">
          <input
            type="file"
            id="file-input"
            accept="image/*"
            onChange={handleImageSelection}
            className="hidden"
          />
          <label htmlFor="file-input" className="cursor-pointer">

            <img
              src={imageUri || dummy_image}
              alt="Profile"
              onContextMenu={(e) => e.preventDefault()}
              className="w-32 h-32 rounded-full"
            />

            {imageUri && (
              <button
                type="button"
                onClick={() => {
                  handleRemoveImage()
                }}
                className="mt-4 text-red-500 hover:underline"
              >
                Remove Image <i className="fas fa-times mr-2"></i>
              </button>
            )}


          </label>


        </div>
        <p className="text-gray-500 text-center">Click to upload image (Max 200KB)</p>
        <div className="flex flex-col space-y-2">
          <label htmlFor="first_name">First Name<span className="text-red-500"> *</span></label>
          <input
            id="first_name"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            value={postData.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            placeholder="First Name"
          />

          <label htmlFor="last_name">Last Name</label>
          <input
            id="last_name"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="text"
            value={postData.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            placeholder="Last Name"
          />

          <label htmlFor="user_phone_number">Phone Number<span className="text-red-500"> *</span></label>

          <div className="flex items-center justify-center space-x-2">
            <select
              id="countryCode"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="block text-center w-[40%] px-1 py-2 mr-1 border border-gray-300 bg-transparent rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              {CountryCodes.map((code, index) => (
                <option key={index} value={code.value}>
                  {code.label} ({code.value})
                </option>
              ))}
            </select>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="Enter 10-digit phone number"
              required
              className="block w-full rounded-lg px-3 py-2 border bg-none border-gray-300 bg-transparent shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {profile?.user_phone_number != countryCode + phoneNumber && !otpSent && (
              <button
                onClick={handleSendOtp}
                className="w-[50%] px-3 py-2 custom-btn"
                disabled={loading}
              >
                {loading ? "Sending OTP..." : "Verify OTP"}
              </button>
            )}
          </div>
          {otpSent && !isOtpVerified && (
            <div className="flex flex-col space-y-2">
              <label htmlFor="otp">Enter OTP<span className="text-red-500"> *</span></label>
              <div className="flex justify-center gap-3">

                {otp.map((digit, index) => (
                  <input
                    key={index}
                    className="w-12 h-12 border rounded text-center text-xl focus:outline-none focus:border-blue-500"
                    placeholder="•"
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    value={digit}
                    ref={(ref) => (otpInputs.current[index] = ref)}
                  />
                ))}
              </div>
              <button type="submit" className="w-fit mx-auto custom-btn" onClick={() => {
                handleVerifyOtp()
              }} disabled={loading}>
                {loading ? (
                  <div className='flex justify-center items-center'>
                    <LoaderButton />
                    Verifying OTP
                  </div>
                ) : (
                  <>Verify OTP</>
                )}
              </button>
            </div>
          )}

          <label htmlFor="user_email_id">Email ID <span className="text-red-500"> *</span></label>
          <input
            id="user_email_id"
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            type="email"
            value={postData.user_email_id}
            onChange={(e) => handleInputChange("user_email_id", e.target.value)}
            placeholder="Email ID"
          />

        </div>

        <div className="flex gap-2 mt-3">
          <button type="submit" onClick={handlePostSubmission} className="custom-btn" disabled={loading}>
            {loading ? (
              <div className='flex justify-center items-center'>
                <LoaderButton />
                Updating
              </div>
            ) : (
              <>Update</>
            )}
          </button>
          <button className="custom-btn ml-2" onClick={()=>{
            handleCancel()
          }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminProfileUpdate;
