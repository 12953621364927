// UserDetails.js
import React, { useContext, useEffect, useState } from 'react';
import apiClient from '../../../ApiClient';
import { AuthContext } from '../../../AuthContext';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import defaultImage from "../../../../assets/images/default_user.jpg";
import { Helmet } from 'react-helmet';
import Loader from '../../../Common/Loader';
import { GrPowerReset } from 'react-icons/gr';

const UserDetails = () => {
  const [profile, setProfile] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [imgurl, setImgUrl] = useState(defaultImage);
  const [fileKey, setFileKey] = useState();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [startCoords, setStartCoords] = useState({ x: 0, y: 0 });
  const [imagePosition, setImagePosition] = useState({ top: 0, left: 0 });
  
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await apiClient.post(
          '/getUserDetails',
          { command: 'getUserDetails', user_id: user_id }
        );
        setProfile(response.data.status_message);
        setFileKey(response.data.status_message.fileKey);
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user.user_id]);

  useEffect(() => {
    const handleProfileImage = async (profileKey) => {
      if (!profileKey) return;
      try {
        const response = await apiClient.post("/getObjectSignedUrl", {
          command: "getObjectSignedUrl",
          key: profileKey,
        });
        if (response.status === 200) {
          setImgUrl(response.data);
        }
      } catch (error) {
        console.error("Error fetching profile URL:", error);
      }
    };
    handleProfileImage(fileKey);
  }, [fileKey]);

  const handleResume = async (resumeKey) => {
    try {
      const response = await apiClient.post("/getObjectSignedUrl", {
        command: "getObjectSignedUrl",
        key: resumeKey,
      });
      if (response.data) {
        window.open(response.data, '_blank', 'noopener,noreferrer');
      }
    } catch (error) {
      console.error("Error fetching Resume URL:", error);
    }
  };

  
  // preview zoom in out starts here ✅

  const openModal = () => {
    setModalImage(imgurl);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage('');
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false); 
  };

  const resetbtn = () => {
    setZoomLevel(1);
    setImagePosition({ top: 0, left: 0 });
    setIsDragging(false)
  }

  const handleZoom = (increment) => {
    setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + increment, 1), 3));
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartCoords({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startCoords.x;
      const deltaY = e.clientY - startCoords.y;
      setImagePosition(prevPosition => ({
        top: prevPosition.top + deltaY,
        left: prevPosition.left + deltaX,
      }));
      setStartCoords({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };



  if (loading) {
    return <Loader />;
  }

  if (!profile) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 gap-3">
        <ToastContainer />
        <p className='m-0 text-[16px]'>No user profile available.</p>
        <button onClick={() => navigate('/company-dashboard/users/list')} className='custom-btn'>All users</button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 p-4 bg-white shadow-md rounded-lg">
      <Helmet>
        <title>BME Bharath | {profile.first_name} {profile.last_name}</title>
        <meta property="og:title" content={`BME Bharath | ${profile.first_name} ${profile.last_name}`} />
        <meta property="og:description" content="An innovative platform fostering knowledge sharing and collaboration in biomedical engineering." />
        <meta property="og:url" content={`https://bmebharat.com/company-dashboard/users/user_details/${profile.user_id}`} />
      </Helmet>

      <div className="flex items-center mx-auto justify-center space-x-4">
        <img
          src={imgurl}
          onContextMenu={(e) => e.preventDefault()}
          alt="Profile"
          className="w-36 h-36 object-contain rounded-full"
          onClick={()=>{
            openModal()
          }}
        />
        <div>
          <div className="flex flex-col justify-start m-1 mb-2">
            <span className="font-bold text-lg">{profile.first_name} {profile.last_name}</span>
            <span className="text-sm text-gray-500 -mt-1">{profile.select_your_profile}</span>
          </div>
          <p className="text-gray-500 m-1 my-0">{profile.gender}</p>

          <p className="text-gray-500 m-1 mt-0 hover:underline cursor-pointer" onClick={()=>{
            window.open("https://wa.me/+918310491223", "_blank", "noopener noreferrer")
          }}>
 {profile.user_email_id.replace(/(.{2}).+(@.+)/, "$1****$2")}
</p>

          {/* <p className="text-gray-600 m-1">Phone: {profile.user_phone_number}</p> */}
        </div>
      </div>

      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-6 w-[70%] mx-auto">
        <div>
          <h3 className="text-lg font-semibold">Personal Information</h3>
          <p className="text-gray-600">Gender: {profile.gender}</p>
          <p className="text-gray-600">Date of Birth: {profile.date_of_birth || "Not provided"}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Academic Background</h3>
          <p className="text-gray-600">College: {profile.college || "Not specified"}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold">Location</h3>
          <p className="text-gray-600">City: {profile.city || "Not specified"}</p>
          <p className="text-gray-600">State: {profile.state || "Not specified"}</p>
        </div>

      
      </div>

      <div className="mt-6 flex items-center justify-center gap-2">
        <button
          onClick={() => window.open("https://wa.me/+918310491223", "_blank", "noopener noreferrer")}
          className="custom-btn"
        >
          Contact
        </button>

        <button
          onClick={() => navigate(-1)}
          className="custom-btn"
        >
          Back
        </button>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <div className="relative">
            <img
              src={modalImage}
              alt="Enlarged view"
                onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s',
                width: '90vw',
                height: '90vh',
                objectFit: 'contain',
                position: 'relative',
                top: imagePosition.top,
                left: imagePosition.left,
              }}
              className="cursor-pointer"
              onMouseDown={handleMouseDown}
            />
            <button
              className="absolute top-4 right-4 text-[35px] text-white  text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="md:absolute md:bottom-4 hidden md:left-1/2 md:transform md:-translate-x-1/2 md:flex md:space-x-4">
              <button
                onClick={() => handleZoom(0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom In +
              </button>
              <button
                onClick={() => handleZoom(-0.2)}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                Zoom Out -
              </button>
              <button
                onClick={() => resetbtn()}
                className="bg-white text-black px-2 py-1 rounded shadow"
              >
                <GrPowerReset />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
