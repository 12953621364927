import React from 'react';
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="max-w-sm mx-auto p-6">
      <h4 className="text-2xl font-bold mb-6 text-center">Contact Us</h4>

      <ul className="space-y-4">
        <li className="flex items-center">
          <FaEnvelope className="mr-2 text-xl" />
          <a
            href="mailto:admin@bmebharat.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500"
          >
            admin@bmebharat.com
          </a>
        </li>
        <li className="flex items-center">
          <FaEnvelope className="mr-2 text-xl" />
          <a
            href="mailto:bmebharat@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500"
          >
            bmebharat@gmail.com
          </a>
        </li>
        <li className="flex items-center">
          <FaPhone className="mr-2 text-xl" />
          <a
            href="tel:+918310491223"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-blue-500"
          >
            +91 83104 91223
          </a>
        </li>
        <li className="flex items-start">
          <FaMapMarkerAlt className="text-xl mr-2" />
          <div className="flex flex-col">
            <p className="m-0">Operational Address:</p>
            <p className="m-0 mt-1">
              #260, 1st floor, 18th main
              <br />
              11th block Anjanpura,
              <br />
              Lal Bahadur Shastri Nagar,
              <br />
              Bengaluru-560083, Karnataka.
            </p>
          </div>
        </li>
      </ul>

      {/* <form className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h5 className="text-lg font-semibold mb-4">Send Us a Message</h5>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Name</label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Message</label>
            <textarea
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-500"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-semibold py-2 rounded hover:bg-blue-600 transition"
          >
            Send Message
          </button>
        </div>
      </form> */}
    </div>
  );
};

export default ContactUs;
